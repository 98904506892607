/* tslint:disable */
/* eslint-disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type XAny = any;

export interface Role {
  id?: number;
  name: string;
  description?: string;
  created?: string;
  modified?: string;
}

/**
 * Map principals to roles
 */
export interface RoleMapping {
  id?: number;

  /** The principal type, such as USER, APPLICATION, ROLE, or user model name in case of multiple user models */
  principalType?: string;
  principalId?: string;
  roleId?: number;
}

export interface AccountToken {
  id: string;

  /** time to live in seconds (2 weeks by default) */
  ttl?: number;

  /** Array of scopes granted to this access token. */
  scopes?: string[];
  created?: string;
  userId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Societe {
  logo?: string;
  name?: string;
  language?: string;
  id?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Contact {
  nom?: string;
  prenom?: string;
  email?: string;
  poste?: string;
  type?: string;
  hangouts?: string;
  id?: number;
  accountId?: number;
  societeId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Assurance {
  marOt?: string;
  marIm2?: string;
  marIm3?: string;
  assurance?: string;
  marSituation?: string;
  marCmrNum?: string[];
  marCmrIm?: string[];
  marDommages?: string;
  marLieu?: string;
  marCause?: string;
  marIm1?: string;
  matImMoteur?: string;
  matImTracte?: string;
  matDateSin?: string;
  matLocalite?: string;
  matTypeLieu?: string;
  matConstat?: boolean;
  matCause?: string;
  matDomCorp?: boolean;
  matDomMat?: string;
  matLoc1?: string;
  matLoc1Im1?: string;
  matLoc1Im2?: string;
  matLoc1Im3?: string;
  matLoc2?: string;
  matLoc2Im1?: string;
  matLoc2Im2?: string;
  matLoc2Im3?: string;
  matLoc3?: string;
  matLoc3Im1?: string;
  matLoc3Im2?: string;
  matLoc3Im3?: string;
  matLoc4?: string;
  matLoc4Im1?: string;
  matLoc4Im2?: string;
  matLoc4Im3?: string;
  matLoc5?: string;
  matLoc5Im1?: string;
  matLoc5Im2?: string;
  matLoc5Im3?: string;
  matLoc6?: string;
  matLoc6Im1?: string;
  matLoc6Im2?: string;
  matLoc6Im3?: string;
  matLoc7?: string;
  matLoc7Im1?: string;
  matLoc7Im2?: string;
  matLoc7Im3?: string;
  matLoc8?: string;
  matLoc8Im1?: string;
  matLoc8Im2?: string;
  matLoc8Im3?: string;
  matLoc9?: string;
  matLoc9Im1?: string;
  matLoc9Im2?: string;
  matLoc9Im3?: string;
  nomPrenom?: string;
  adresseUser?: string;
  marDate?: string;
  marEtat?: string;
  matEtat?: string;
  marValidationExp?: string;
  matValidationExp?: string;
  marDateTransExp?: string;
  marHeureTransExp?: string;
  matDateTransExp?: string;
  matHeureTransExp?: string;
  matDateTransAss?: string;
  marDateTransAss?: string;
  matHeureTransAss?: string;
  visuelMot?: string;
  visuelTract?: string;
  matDateSaisie?: string;
  matConstatIm?: string;
  lieuSaisie?: string;
  controleSt?: boolean;
  imagelibre?: string;
  marCmrType?: string[];
  id?: number;
  accountId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Photo {
  image?: string;
  note?: string;
  id?: number;
  accountId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Media {
  name?: string;
  source?: string;
  type?: string;
  author?: string;
  id?: number;
  accountId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Reserve {
  tripId?: number;
  image?: string[];
  tripInformation?: string;
  note?: string;
  latitude?: string;
  longitude?: string;
  timeStamp?: string;
  hdop?: string;
  altitude?: string;
  speed?: string;
  id?: number;
  accountId?: number;
  createdAt?: string;
  updatedAt?: string;
  missionId?: number;
  etapeId?: number;
}

export interface EtatDeLieux {
  lieu?: string;
  problem?: boolean;
  note?: string;
  image?: string;
  tripInformation?: string;
  tripId?: number;
  id?: number;
  accountId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Mission {
  etapes?: Etape[];
  reserves?: Reserve[];
  cMRs?: CMR[];
  account?: Account[];
  tempServices?: TempService[];
  ot?: string;
  id?: number;
  accountId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface InAppNotification {
  messageType?: string;
  message?: string;
  isRead?: boolean;
  metaData?: object;
  nomNotification?: string;
  resumeNotification?: string;
  dateNotification?: string;
  dateLecture?: string;
  id?: number;
  accountId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface ObservationNotification {
  texte?: string;
  type?: string;
  action?: string;
  id?: number;
  inAppNotificationId?: number;
  accountId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface TempService {
  tempsEnCours?: string;
  activiteEnCours?: string;
  pbo?: string;
  tempsDeService?: string;
  priseDePosteJour?: string;
  amplitude?: string;
  tempsServiceAuto?: string;
  condContinueUser?: string;
  condContinueAuto?: string;
  condJourPast1?: string;
  condJourPast2?: string;
  condJourUser?: string;
  condJourAuto?: string;
  condSemUser?: string;
  condSemAuto?: string;
  condQuinzUser?: string;
  condQuinzAuto?: string;
  coupureUser?: string;
  coupureObli?: string;
  reposJourPast1?: string;
  reposJourPast2?: string;
  reposJourPast3?: string;
  reposJourUser?: string;
  reposJourObli?: string;
  reposHebdoUser?: string;
  reposHebdoObli?: string;
  popupCoupure45?: string;
  popupCoupure30?: string;
  popupConduiteJour9?: string;
  popupConduiteJour10?: string;
  popupService?: string;
  popupPriseDePoste?: string;
  popupConduiteHebdo?: string;
  popupConduiteQuinz?: string;
  popupCoupure?: string;
  popupReposJour?: string;
  popupReposHebdo?: string;
  popupCoup4h?: string;
  popupCoup4h30?: string;
  popupCoup4hf?: string;
  popupCoup4h30f?: string;
  actualisation?: string;
  travailmois?: string;
  travailhebdo?: string;
  travailjour?: string;
  servicemois?: string;
  servicehebdo?: string;
  condmois?: string;
  id?: number;
  accountId?: number;
  missionId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Verifications {
  parcMoteur?: string;
  parcTracte?: string;
  anomalieCommentaire?: string;
  anomalieIm1?: string;
  anomalieIm2?: string;
  anomalieIm3?: string;
  OK?: boolean;
  etape?: string;
  id?: number;
  accountId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Ecoconduite {
  moyScoreUser?: string;
  moyScoreAct?: string;
  moyRalentiUser?: string;
  moyRalentiAct?: string;
  moyInertieUser?: string;
  moyInertieAct?: string;
  moyAccelUser?: string;
  moyAccelAct?: string;
  moyFreinageUser?: string;
  moyFreinageAct?: string;
  moyRegulateur?: string;
  moyConso?: string;
  dateComCoach?: string;
  commCoach?: string;
  heureComCoach?: string;
  perfRalentiUser?: string;
  datePerfRalenti?: string;
  perfInertieUser?: string;
  datePerfInertie?: string;
  perfAccelUser?: string;
  datePerfAccel?: string;
  perfFreinageUser?: string;
  datePerfFreinage?: string;
  perScoreUser?: string;
  perScoreAct?: string;
  perRalentiUser?: string;
  perRalentiAct?: string;
  perInertieUser?: string;
  perInertieAct?: string;
  perAccelUser?: string;
  perAccelAct?: string;
  perFreinageUser?: string;
  perFreinageAct?: string;
  perRegulateur?: string;
  perConso?: string;
  perDate1?: string;
  perDate2?: string;
  challRegul?: string;
  challInertie?: string;
  coachingApp1?: string;
  coachingApp2?: string;
  coachingApp3?: string;
  coachingApp4?: string;
  coachingApp5?: string;
  consoMinute?: string[];
  consoMoy?: string;
  coaching?: object[];
  id?: number;
  accountId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Moteur {
  etapes?: Etape[];
  techniques?: Technique[];
  parcMoteur?: string;
  matImMoteur?: string;
  kilometrage?: number;
  consommation?: string;
  vitesseMoy?: string;
  tempsMoteur?: string;
  tempsRoute?: string;
  tempsRalenti?: string;
  tempsSpecif?: string;
  nivCarburant?: string;
  donneeSpecif?: string;
  typeMoteur?: string;
  autonomie?: string;
  image?: string;
  id?: number;
  accountId?: number;
  createdAt?: string;
  updatedAt?: string;
  vehicleImageId?: number;
}

export interface RH {
  date?: string;
  prisePoste?: string;
  finPoste?: string;
  type?: string;
  codePostal?: string;
  ville?: string;
  pays?: string;
  montant?: string;
  majoration?: boolean;
  activation?: boolean;
  majorationReelle?: boolean;
  codePostalReelle?: string;
  villeReelle?: string;
  paysReelle?: string;
  montantReelle?: string;
  typeReelle?: string;
  activationReelle?: boolean;
  text?: string;
  updatefrais?: string;
  updateuser?: string;
  id?: number;
  accountId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Accueil {
  kmJournee?: number;
  consommationUser?: string;
  consommationActivite?: string;
  couleurAutonomie?: string;
  id?: number;
  accountId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Tracte {
  etapes?: Etape[];
  parcTracte?: string;
  matImTracte?: string;
  donneeSpecif?: string;
  typeTracte?: string;
  etatChargeVide?: string;
  image?: string;
  id?: number;
  accountId?: number;
  createdAt?: string;
  updatedAt?: string;
  vehicleImageId?: number;
}

export interface Parametres {
  formatDheures?: boolean;
  tracte?: string;
  isDarkMode?: boolean;
  guide?: string;
  id?: number;
  accountId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Bu {
  logo?: string;
  name?: string;
  language?: string;
  id?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface CodeAnalytique {
  logo?: string;
  name?: string;
  language?: string;
  id?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Etablissement {
  logo?: string;
  name?: string;
  language?: string;
  id?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Statistiques {
  ouvertureapp?: number;
  ouvertureguide?: number;
  ouverturemission?: number;
  ouverturetemps?: number;
  ouvertureeco?: number;
  ouverturerh?: number;
  ouverturepoi?: number;
  ouvertureassurance?: number;
  ouverturechallenge?: number;
  ouverturedetailpoi?: number;
  presence?: boolean;
  id?: number;
  accountId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Account {
  parametres?: Parametres[];
  missions?: Mission[];
  rHs?: RH[];
  ecoconduites?: Ecoconduite[];
  accueils?: Accueil[];
  verifications?: Verifications[];
  etatDeLieuxes?: EtatDeLieux[];
  tempServices?: TempService;
  moteurs?: Moteur;
  tractes?: Tracte;
  societe?: Societe;
  nom?: string;
  prenom?: string;
  email?: string;
  accessTokenGmail?: string;
  refreshTokenGmail?: string;
  cookieForMeet?: string;
  telephone?: string;
  address?: string;
  type?: string;
  isLoaded?: boolean;
  appVersion?: string;
  appStatus?: string;
  dateNaissance?: string;
  nConducteur?: string;
  paysRattachement?: string;
  codeSecurity?: string;
  isEnableNotification?: boolean;
  notiffrais?: boolean;
  raisonSociale?: string;
  avatar?: string;
  lastTimeOpenRh?: string;
  verifmoteur?: boolean;
  veriftracte?: boolean;
  verif?: boolean;
  codetms?: string;
  sorti?: boolean;
  realm?: string;
  username?: string;
  emailVerified?: boolean;
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  societeId?: number;
  buId?: number;
  codeAnalytiqueId?: number;
  etablissementId?: number;
}

export interface StatGlobal {
  valeurnum?: number;
  valeurbool?: boolean;
  stats_date?: string;
  id?: number;
  accountId?: number;
  buId?: number;
  societeId?: number;
  etablissementId?: number;
  codeAnalytiqueId?: number;
  createdAt?: string;
  updatedAt?: string;
  statIndicateurId?: number;
}

export interface CMR {
  image?: string;
  cMRno?: string;
  latitude?: string;
  longitude?: string;
  timeStamp?: string;
  hdop?: string;
  altitude?: string;
  speed?: string;
  type?: string;
  id?: number;
  accountId?: number;
  missionId?: number;
  etapeId?: number;
  assuranceId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface AssuranceMatLoc {
  matLoc?: string;
  matLocIm1?: string;
  matLocIm2?: string;
  matLocIm3?: string;
  matLocType?: string;
  id?: number;
  assuranceId?: number;
}

export interface Etape {
  cMRs?: CMR[];
  tracte?: Tracte;
  moteur?: Moteur;
  reserves?: Reserve[];
  eta?: string;
  refClient?: string;
  date?: string;
  rdvDebut?: string;
  rdvFin?: string;
  nomTypeDetape?: string;
  quantity?: string;
  unit?: string;
  nature?: string;
  adresse?: string;
  codePostal?: string;
  pays?: string;
  telephone?: string;
  heureArrive?: string;
  heureArriveReelle?: string;
  heureDebut?: string;
  heureDebutReelle?: string;
  heureFin?: string;
  heureFinReelle?: string;
  client?: string;
  metreLineaire?: number;
  poidsKg?: number;
  ref2?: string;
  ref3?: string;
  contrainte?: string;
  particularite?: string;
  relation?: string;
  statut?: boolean;
  raisonSociale?: string;
  dateHeureCmr?: string;
  dateFinReelle?: string;
  document?: boolean;
  gestionpalettes?: boolean;
  echangepal?: string;
  echangepalreel?: string;
  ville?: string;
  tracteParcTracteReel?: string;
  quantityReelle?: string;
  popupModification?: string;
  saisiearrivesite?: string;
  saisiedebutetape?: string;
  saisiefinetape?: string;
  id?: number;
  missionId?: number;
  accountId?: number;
  createdAt?: string;
  updatedAt?: string;
  moteurId?: number;
  tracteId?: number;
}

export interface Application {
  id: string;
  realm?: string;
  name: string;
  description?: string;

  /** The icon image url */
  icon?: string;

  /** The user id of the developer who registers the application */
  owner?: string;

  /** A list of users ids who have permissions to work on this app */
  collaborators?: string[];
  email?: string;
  emailVerified?: boolean;

  /** The application URL for OAuth 2.0 */
  url?: string;

  /** OAuth 2.0 code/token callback URLs */
  callbackUrls?: string[];

  /** A list of permissions required by the application */
  permissions?: string[];
  clientKey?: string;
  javaScriptKey?: string;
  restApiKey?: string;
  windowsKey?: string;
  masterKey?: string;
  pushSettings?: XAny;
  authenticationEnabled?: boolean;
  anonymousAllowed?: boolean;
  authenticationSchemes?: XAny[];

  /** Status of the application, production/sandbox/disabled */
  status?: string;
  created?: string;
  modified?: string;
}

export interface Installation {
  appId: string;
  appVersion?: string;
  badge?: number;
  created?: string;
  deviceToken: string;
  deviceType: string;
  modified?: string;
  status?: string;
  subscriptions?: string[];
  timeZone?: string;
  userId?: string;
  id?: number;
}

export interface Push {
  id?: number;
}

export interface Notification {
  alert?: XAny;
  badge?: number;
  category?: string;
  collapseKey?: string;
  contentAvailable?: boolean;
  created?: string;
  delayWhileIdle?: boolean;
  deviceToken: string;
  deviceType: string;
  expirationInterval?: number;
  expirationTime?: string;
  modified?: string;
  scheduledTime?: string;
  sound?: string;
  status?: string;
  urlArgs?: string[];
  id?: number;
}

export interface PleinCarburant {
  station?: string;
  kilometrage?: string;
  litrage?: string;
  complet?: boolean;
  date?: string;
  heure?: string;
  autreStation?: string;
  kilometrageauto?: string;
  litrageauto?: string;
  dateheureauto?: string;
  type?: string;
  id?: number;
  accountId?: number;
  moteurId?: number;
  cMRId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface POI {
  nom?: string;
  type?: string;
  adresse?: string;
  cp?: string;
  ville?: string;
  pays?: string;
  horaireSemaine?: string;
  horaireWeekEnd?: string;
  location?: GeoPoint;
  contact?: string;
  tel?: string;
  composition?: string;
  equipement?: string;
  id?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Technique {
  serviceDistance?: string;
  tempMoteur?: string;
  atelierProxNom?: string;
  atelierProxLatitude?: number;
  atelierProxLongitude?: number;
  id?: number;
  moteurId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface VehicleImage {
  imageUrl: string;
  imageName: string;
  id?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface AppVersion {
  version: string;
  buildNumber: number;
  id?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface StatIndicateur {
  libelle?: string;
  type?: string;
  id?: number;
}

export interface GeoPoint {
  lat?: number;
  lng?: number;
}

export type RequestParams = Omit<RequestInit, "body" | "method"> & {
  secure?: boolean;
};

export type RequestQueryParamsType = Record<string | number, any>;

type ApiConfig<SecurityDataType> = {
  baseUrl?: string;
  baseApiParams?: RequestParams;
  securityWorker?: (securityData: SecurityDataType) => RequestParams;
};

enum BodyType {
  Json,
}

class HttpClient<SecurityDataType> {
  public baseUrl: string = "/api";
  private securityData: SecurityDataType = null as any;
  private securityWorker: ApiConfig<SecurityDataType>["securityWorker"] = (() => {}) as any;

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor({ baseUrl, baseApiParams, securityWorker }: ApiConfig<SecurityDataType> = {}) {
    this.baseUrl = baseUrl || this.baseUrl;
    this.baseApiParams = baseApiParams || this.baseApiParams;
    this.securityWorker = securityWorker || this.securityWorker;
  }

  public setSecurityData = (data: SecurityDataType) => {
    this.securityData = data;
  };

  private addQueryParam(query: RequestQueryParamsType, key: string) {
    return (
      encodeURIComponent(key) + "=" + encodeURIComponent(Array.isArray(query[key]) ? query[key].join(",") : query[key])
    );
  }

  protected addQueryParams(rawQuery?: RequestQueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys.length
      ? `?${keys
          .map((key) =>
            typeof query[key] === "object" && !Array.isArray(query[key])
              ? this.addQueryParams(query[key] as object).substring(1)
              : this.addQueryParam(query, key),
          )
          .join("&")}`
      : "";
  }

  private bodyFormatters: Record<BodyType, (input: any) => any> = {
    [BodyType.Json]: JSON.stringify,
  };

  private mergeRequestOptions(params: RequestParams, securityParams?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params,
      ...(securityParams || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params.headers || {}),
        ...((securityParams && securityParams.headers) || {}),
      },
    };
  }

  private safeParseResponse = <T = any, E = any>(response: Response): Promise<T> =>
    response
      .json()
      .then((data) => data)
      .catch((e) => response.text);

  public request = <T = any, E = any>(
    path: string,
    method: string,
    { secure, ...params }: RequestParams = {},
    body?: any,
    bodyType?: BodyType,
    secureByDefault?: boolean,
  ): Promise<T> =>
    fetch(`${this.baseUrl}${path}`, {
      // @ts-ignore
      ...this.mergeRequestOptions(params, (secureByDefault || secure) && this.securityWorker(this.securityData)),
      method,
      body: body ? this.bodyFormatters[bodyType || BodyType.Json](body) : null,
    }).then(async (response) => {
      const data = await this.safeParseResponse<T, E>(response);
      if (!response.ok) throw data;
      return data;
    });
}

/**
 * @title TRANSALLIANEHUB-API
 * @version 2.2.4
 * @baseUrl /api
 * TRANSALLIANEHUB-API
 */
export class Api<SecurityDataType = any> extends HttpClient<SecurityDataType> {
  roleMappings = {
    /**
     * @tags RoleMapping
     * @name RoleMapping.prototype.__get__role
     * @summary Fetches belongsTo relation role.
     * @request GET:/RoleMappings/{id}/role
     */
    roleMappingPrototypeGetRole: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Role, any>(`/RoleMappings/${id}/role${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags RoleMapping
     * @name RoleMapping.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/RoleMappings
     */
    roleMappingCreate: (data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/RoleMappings`, "POST", params, data),

    /**
     * @tags RoleMapping
     * @name RoleMapping.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/RoleMappings
     */
    roleMappingPatchOrCreate: (data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/RoleMappings`, "PATCH", params, data),

    /**
     * @tags RoleMapping
     * @name RoleMapping.replaceOrCreate__put_RoleMappings
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/RoleMappings
     */
    roleMappingReplaceOrCreatePutRoleMappings: (data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/RoleMappings`, "PUT", params, data),

    /**
     * @tags RoleMapping
     * @name RoleMapping.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/RoleMappings
     */
    roleMappingFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<RoleMapping[], any>(`/RoleMappings${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags RoleMapping
     * @name RoleMapping.replaceOrCreate__post_RoleMappings_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/RoleMappings/replaceOrCreate
     */
    roleMappingReplaceOrCreatePostRoleMappingsReplaceOrCreate: (data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/RoleMappings/replaceOrCreate`, "POST", params, data),

    /**
     * @tags RoleMapping
     * @name RoleMapping.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/RoleMappings/upsertWithWhere
     */
    roleMappingUpsertWithWhere: (data: RoleMapping, query?: { where?: string }, params?: RequestParams) =>
      this.request<RoleMapping, any>(
        `/RoleMappings/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags RoleMapping
     * @name RoleMapping.exists__get_RoleMappings_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/RoleMappings/{id}/exists
     */
    roleMappingExistsGetRoleMappingsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/RoleMappings/${id}/exists`, "GET", params),

    /**
     * @tags RoleMapping
     * @name RoleMapping.exists__head_RoleMappings_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/RoleMappings/{id}
     */
    roleMappingExistsHeadRoleMappingsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/RoleMappings/${id}`, "HEAD", params),

    /**
     * @tags RoleMapping
     * @name RoleMapping.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/RoleMappings/{id}
     */
    roleMappingFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/RoleMappings/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags RoleMapping
     * @name RoleMapping.replaceById__put_RoleMappings_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/RoleMappings/{id}
     */
    roleMappingReplaceByIdPutRoleMappingsId: (id: string, data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/RoleMappings/${id}`, "PUT", params, data),

    /**
     * @tags RoleMapping
     * @name RoleMapping.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/RoleMappings/{id}
     */
    roleMappingDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/RoleMappings/${id}`, "DELETE", params),

    /**
     * @tags RoleMapping
     * @name RoleMapping.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/RoleMappings/{id}
     */
    roleMappingPrototypePatchAttributes: (id: string, data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/RoleMappings/${id}`, "PATCH", params, data),

    /**
     * @tags RoleMapping
     * @name RoleMapping.replaceById__post_RoleMappings_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/RoleMappings/{id}/replace
     */
    roleMappingReplaceByIdPostRoleMappingsIdReplace: (id: string, data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/RoleMappings/${id}/replace`, "POST", params, data),

    /**
     * @tags RoleMapping
     * @name RoleMapping.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/RoleMappings/findOne
     */
    roleMappingFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/RoleMappings/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags RoleMapping
     * @name RoleMapping.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/RoleMappings/update
     */
    roleMappingUpdateAll: (data: RoleMapping, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/RoleMappings/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags RoleMapping
     * @name RoleMapping.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/RoleMappings/count
     */
    roleMappingCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/RoleMappings/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags RoleMapping
     * @name RoleMapping.createChangeStream__post_RoleMappings_change-stream
     * @summary Create a change stream.
     * @request POST:/RoleMappings/change-stream
     */
    roleMappingCreateChangeStreamPostRoleMappingsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/RoleMappings/change-stream`, "POST", params, data),

    /**
     * @tags RoleMapping
     * @name RoleMapping.createChangeStream__get_RoleMappings_change-stream
     * @summary Create a change stream.
     * @request GET:/RoleMappings/change-stream
     */
    roleMappingCreateChangeStreamGetRoleMappingsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/RoleMappings/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  roles = {
    /**
     * @tags Role
     * @name Role.prototype.__findById__principals
     * @summary Find a related item by id for principals.
     * @request GET:/Roles/{id}/principals/{fk}
     */
    rolePrototypeFindByIdPrincipals: (id: string, fk: string, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/Roles/${id}/principals/${fk}`, "GET", params),

    /**
     * @tags Role
     * @name Role.prototype.__destroyById__principals
     * @summary Delete a related item by id for principals.
     * @request DELETE:/Roles/{id}/principals/{fk}
     */
    rolePrototypeDestroyByIdPrincipals: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Roles/${id}/principals/${fk}`, "DELETE", params),

    /**
     * @tags Role
     * @name Role.prototype.__updateById__principals
     * @summary Update a related item by id for principals.
     * @request PUT:/Roles/{id}/principals/{fk}
     */
    rolePrototypeUpdateByIdPrincipals: (id: string, fk: string, data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/Roles/${id}/principals/${fk}`, "PUT", params, data),

    /**
     * @tags Role
     * @name Role.prototype.__get__principals
     * @summary Queries principals of Role.
     * @request GET:/Roles/{id}/principals
     */
    rolePrototypeGetPrincipals: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<RoleMapping[], any>(`/Roles/${id}/principals${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Role
     * @name Role.prototype.__create__principals
     * @summary Creates a new instance in principals of this model.
     * @request POST:/Roles/{id}/principals
     */
    rolePrototypeCreatePrincipals: (id: string, data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/Roles/${id}/principals`, "POST", params, data),

    /**
     * @tags Role
     * @name Role.prototype.__delete__principals
     * @summary Deletes all principals of this model.
     * @request DELETE:/Roles/{id}/principals
     */
    rolePrototypeDeletePrincipals: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Roles/${id}/principals`, "DELETE", params),

    /**
     * @tags Role
     * @name Role.prototype.__count__principals
     * @summary Counts principals of Role.
     * @request GET:/Roles/{id}/principals/count
     */
    rolePrototypeCountPrincipals: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Roles/${id}/principals/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Role
     * @name Role.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Roles
     */
    roleCreate: (data: Role, params?: RequestParams) => this.request<Role, any>(`/Roles`, "POST", params, data),

    /**
     * @tags Role
     * @name Role.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Roles
     */
    rolePatchOrCreate: (data: Role, params?: RequestParams) => this.request<Role, any>(`/Roles`, "PATCH", params, data),

    /**
     * @tags Role
     * @name Role.replaceOrCreate__put_Roles
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Roles
     */
    roleReplaceOrCreatePutRoles: (data: Role, params?: RequestParams) =>
      this.request<Role, any>(`/Roles`, "PUT", params, data),

    /**
     * @tags Role
     * @name Role.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Roles
     */
    roleFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Role[], any>(`/Roles${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Role
     * @name Role.replaceOrCreate__post_Roles_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Roles/replaceOrCreate
     */
    roleReplaceOrCreatePostRolesReplaceOrCreate: (data: Role, params?: RequestParams) =>
      this.request<Role, any>(`/Roles/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Role
     * @name Role.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Roles/upsertWithWhere
     */
    roleUpsertWithWhere: (data: Role, query?: { where?: string }, params?: RequestParams) =>
      this.request<Role, any>(`/Roles/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Role
     * @name Role.exists__get_Roles_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Roles/{id}/exists
     */
    roleExistsGetRolesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Roles/${id}/exists`, "GET", params),

    /**
     * @tags Role
     * @name Role.exists__head_Roles_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Roles/{id}
     */
    roleExistsHeadRolesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Roles/${id}`, "HEAD", params),

    /**
     * @tags Role
     * @name Role.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Roles/{id}
     */
    roleFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Role, any>(`/Roles/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Role
     * @name Role.replaceById__put_Roles_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Roles/{id}
     */
    roleReplaceByIdPutRolesId: (id: string, data: Role, params?: RequestParams) =>
      this.request<Role, any>(`/Roles/${id}`, "PUT", params, data),

    /**
     * @tags Role
     * @name Role.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Roles/{id}
     */
    roleDeleteById: (id: string, params?: RequestParams) => this.request<object, any>(`/Roles/${id}`, "DELETE", params),

    /**
     * @tags Role
     * @name Role.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Roles/{id}
     */
    rolePrototypePatchAttributes: (id: string, data: Role, params?: RequestParams) =>
      this.request<Role, any>(`/Roles/${id}`, "PATCH", params, data),

    /**
     * @tags Role
     * @name Role.replaceById__post_Roles_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Roles/{id}/replace
     */
    roleReplaceByIdPostRolesIdReplace: (id: string, data: Role, params?: RequestParams) =>
      this.request<Role, any>(`/Roles/${id}/replace`, "POST", params, data),

    /**
     * @tags Role
     * @name Role.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Roles/findOne
     */
    roleFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Role, any>(`/Roles/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Role
     * @name Role.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Roles/update
     */
    roleUpdateAll: (data: Role, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Roles/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Role
     * @name Role.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Roles/count
     */
    roleCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Roles/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Role
     * @name Role.createChangeStream__post_Roles_change-stream
     * @summary Create a change stream.
     * @request POST:/Roles/change-stream
     */
    roleCreateChangeStreamPostRolesChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Roles/change-stream`, "POST", params, data),

    /**
     * @tags Role
     * @name Role.createChangeStream__get_Roles_change-stream
     * @summary Create a change stream.
     * @request GET:/Roles/change-stream
     */
    roleCreateChangeStreamGetRolesChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Roles/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  containers = {
    /**
     * @tags Container
     * @name Container.getContainers
     * @request GET:/Containers
     */
    containerGetContainers: (params?: RequestParams) => this.request<XAny[], any>(`/Containers`, "GET", params),

    /**
     * @tags Container
     * @name Container.createContainer
     * @request POST:/Containers
     */
    containerCreateContainer: (options: object, params?: RequestParams) =>
      this.request<object, any>(`/Containers`, "POST", params, options),

    /**
     * @tags Container
     * @name Container.destroyContainer
     * @request DELETE:/Containers/{container}
     */
    containerDestroyContainer: (container: string, params?: RequestParams) =>
      this.request<{ undefined?: object }, any>(`/Containers/${container}`, "DELETE", params),

    /**
     * @tags Container
     * @name Container.getContainer
     * @request GET:/Containers/{container}
     */
    containerGetContainer: (container: string, params?: RequestParams) =>
      this.request<object, any>(`/Containers/${container}`, "GET", params),

    /**
     * @tags Container
     * @name Container.getFiles
     * @request GET:/Containers/{container}/files
     */
    containerGetFiles: (container: string, params?: RequestParams) =>
      this.request<XAny[], any>(`/Containers/${container}/files`, "GET", params),

    /**
     * @tags Container
     * @name Container.getFile
     * @request GET:/Containers/{container}/files/{file}
     */
    containerGetFile: (container: string, file: string, params?: RequestParams) =>
      this.request<object, any>(`/Containers/${container}/files/${file}`, "GET", params),

    /**
     * @tags Container
     * @name Container.removeFile
     * @request DELETE:/Containers/{container}/files/{file}
     */
    containerRemoveFile: (container: string, file: string, params?: RequestParams) =>
      this.request<{ undefined?: object }, any>(`/Containers/${container}/files/${file}`, "DELETE", params),

    /**
     * @tags Container
     * @name Container.upload
     * @request POST:/Containers/{container}/upload
     */
    containerUpload: (container: string, params?: RequestParams) =>
      this.request<{ result?: object }, any>(`/Containers/${container}/upload`, "POST", params),

    /**
     * @tags Container
     * @name Container.download
     * @request GET:/Containers/{container}/download/{file}
     */
    containerDownload: (container: string, file: string, params?: RequestParams) =>
      this.request<any, any>(`/Containers/${container}/download/${file}`, "GET", params),
  };
  accounts = {
    /**
     * @tags Account
     * @name Account.prototype.__findById__roles
     * @summary Find a related item by id for roles.
     * @request GET:/Accounts/{id}/roles/{fk}
     */
    accountPrototypeFindByIdRoles: (id: string, fk: string, params?: RequestParams) =>
      this.request<Role, any>(`/Accounts/${id}/roles/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__roles
     * @summary Delete a related item by id for roles.
     * @request DELETE:/Accounts/{id}/roles/{fk}
     */
    accountPrototypeDestroyByIdRoles: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/roles/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__roles
     * @summary Update a related item by id for roles.
     * @request PUT:/Accounts/{id}/roles/{fk}
     */
    accountPrototypeUpdateByIdRoles: (id: string, fk: string, data: Role, params?: RequestParams) =>
      this.request<Role, any>(`/Accounts/${id}/roles/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__link__roles
     * @summary Add a related item by id for roles.
     * @request PUT:/Accounts/{id}/roles/rel/{fk}
     */
    accountPrototypeLinkRoles: (id: string, fk: string, data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/Accounts/${id}/roles/rel/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__unlink__roles
     * @summary Remove the roles relation to an item by id.
     * @request DELETE:/Accounts/{id}/roles/rel/{fk}
     */
    accountPrototypeUnlinkRoles: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/roles/rel/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__exists__roles
     * @summary Check the existence of roles relation to an item by id.
     * @request HEAD:/Accounts/{id}/roles/rel/{fk}
     */
    accountPrototypeExistsRoles: (id: string, fk: string, params?: RequestParams) =>
      this.request<boolean, any>(`/Accounts/${id}/roles/rel/${fk}`, "HEAD", params),

    /**
     * @tags Account
     * @name Account.prototype.__findById__accessTokens
     * @summary Find a related item by id for accessTokens.
     * @request GET:/Accounts/{id}/accessTokens/{fk}
     */
    accountPrototypeFindByIdAccessTokens: (id: string, fk: string, params?: RequestParams) =>
      this.request<AccountToken, any>(`/Accounts/${id}/accessTokens/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__accessTokens
     * @summary Delete a related item by id for accessTokens.
     * @request DELETE:/Accounts/{id}/accessTokens/{fk}
     */
    accountPrototypeDestroyByIdAccessTokens: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/accessTokens/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__accessTokens
     * @summary Update a related item by id for accessTokens.
     * @request PUT:/Accounts/{id}/accessTokens/{fk}
     */
    accountPrototypeUpdateByIdAccessTokens: (id: string, fk: string, data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/Accounts/${id}/accessTokens/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__get__societe
     * @summary Fetches belongsTo relation societe.
     * @request GET:/Accounts/{id}/societe
     */
    accountPrototypeGetSociete: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Societe, any>(`/Accounts/${id}/societe${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__findById__contacts
     * @summary Find a related item by id for contacts.
     * @request GET:/Accounts/{id}/contacts/{fk}
     */
    accountPrototypeFindByIdContacts: (id: string, fk: string, params?: RequestParams) =>
      this.request<Contact, any>(`/Accounts/${id}/contacts/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__contacts
     * @summary Delete a related item by id for contacts.
     * @request DELETE:/Accounts/{id}/contacts/{fk}
     */
    accountPrototypeDestroyByIdContacts: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/contacts/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__contacts
     * @summary Update a related item by id for contacts.
     * @request PUT:/Accounts/{id}/contacts/{fk}
     */
    accountPrototypeUpdateByIdContacts: (id: string, fk: string, data: Contact, params?: RequestParams) =>
      this.request<Contact, any>(`/Accounts/${id}/contacts/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__assurances
     * @summary Find a related item by id for assurances.
     * @request GET:/Accounts/{id}/assurances/{fk}
     */
    accountPrototypeFindByIdAssurances: (id: string, fk: string, params?: RequestParams) =>
      this.request<Assurance, any>(`/Accounts/${id}/assurances/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__assurances
     * @summary Delete a related item by id for assurances.
     * @request DELETE:/Accounts/{id}/assurances/{fk}
     */
    accountPrototypeDestroyByIdAssurances: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/assurances/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__assurances
     * @summary Update a related item by id for assurances.
     * @request PUT:/Accounts/{id}/assurances/{fk}
     */
    accountPrototypeUpdateByIdAssurances: (id: string, fk: string, data: Assurance, params?: RequestParams) =>
      this.request<Assurance, any>(`/Accounts/${id}/assurances/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__photos
     * @summary Find a related item by id for photos.
     * @request GET:/Accounts/{id}/photos/{fk}
     */
    accountPrototypeFindByIdPhotos: (id: string, fk: string, params?: RequestParams) =>
      this.request<Photo, any>(`/Accounts/${id}/photos/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__photos
     * @summary Delete a related item by id for photos.
     * @request DELETE:/Accounts/{id}/photos/{fk}
     */
    accountPrototypeDestroyByIdPhotos: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/photos/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__photos
     * @summary Update a related item by id for photos.
     * @request PUT:/Accounts/{id}/photos/{fk}
     */
    accountPrototypeUpdateByIdPhotos: (id: string, fk: string, data: Photo, params?: RequestParams) =>
      this.request<Photo, any>(`/Accounts/${id}/photos/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__media
     * @summary Find a related item by id for media.
     * @request GET:/Accounts/{id}/media/{fk}
     */
    accountPrototypeFindByIdMedia: (id: string, fk: string, params?: RequestParams) =>
      this.request<Media, any>(`/Accounts/${id}/media/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__media
     * @summary Delete a related item by id for media.
     * @request DELETE:/Accounts/{id}/media/{fk}
     */
    accountPrototypeDestroyByIdMedia: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/media/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__media
     * @summary Update a related item by id for media.
     * @request PUT:/Accounts/{id}/media/{fk}
     */
    accountPrototypeUpdateByIdMedia: (id: string, fk: string, data: Media, params?: RequestParams) =>
      this.request<Media, any>(`/Accounts/${id}/media/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__reserves
     * @summary Find a related item by id for reserves.
     * @request GET:/Accounts/{id}/reserves/{fk}
     */
    accountPrototypeFindByIdReserves: (id: string, fk: string, params?: RequestParams) =>
      this.request<Reserve, any>(`/Accounts/${id}/reserves/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__reserves
     * @summary Delete a related item by id for reserves.
     * @request DELETE:/Accounts/{id}/reserves/{fk}
     */
    accountPrototypeDestroyByIdReserves: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/reserves/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__reserves
     * @summary Update a related item by id for reserves.
     * @request PUT:/Accounts/{id}/reserves/{fk}
     */
    accountPrototypeUpdateByIdReserves: (id: string, fk: string, data: Reserve, params?: RequestParams) =>
      this.request<Reserve, any>(`/Accounts/${id}/reserves/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__etatDeLieuxes
     * @summary Find a related item by id for etatDeLieuxes.
     * @request GET:/Accounts/{id}/etatDeLieuxes/{fk}
     */
    accountPrototypeFindByIdEtatDeLieuxes: (id: string, fk: string, params?: RequestParams) =>
      this.request<EtatDeLieux, any>(`/Accounts/${id}/etatDeLieuxes/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__etatDeLieuxes
     * @summary Delete a related item by id for etatDeLieuxes.
     * @request DELETE:/Accounts/{id}/etatDeLieuxes/{fk}
     */
    accountPrototypeDestroyByIdEtatDeLieuxes: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/etatDeLieuxes/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__etatDeLieuxes
     * @summary Update a related item by id for etatDeLieuxes.
     * @request PUT:/Accounts/{id}/etatDeLieuxes/{fk}
     */
    accountPrototypeUpdateByIdEtatDeLieuxes: (id: string, fk: string, data: EtatDeLieux, params?: RequestParams) =>
      this.request<EtatDeLieux, any>(`/Accounts/${id}/etatDeLieuxes/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__missions
     * @summary Find a related item by id for missions.
     * @request GET:/Accounts/{id}/missions/{fk}
     */
    accountPrototypeFindByIdMissions: (id: string, fk: string, params?: RequestParams) =>
      this.request<Mission, any>(`/Accounts/${id}/missions/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__missions
     * @summary Delete a related item by id for missions.
     * @request DELETE:/Accounts/{id}/missions/{fk}
     */
    accountPrototypeDestroyByIdMissions: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/missions/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__missions
     * @summary Update a related item by id for missions.
     * @request PUT:/Accounts/{id}/missions/{fk}
     */
    accountPrototypeUpdateByIdMissions: (id: string, fk: string, data: Mission, params?: RequestParams) =>
      this.request<Mission, any>(`/Accounts/${id}/missions/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__inAppNotifications
     * @summary Find a related item by id for inAppNotifications.
     * @request GET:/Accounts/{id}/inAppNotifications/{fk}
     */
    accountPrototypeFindByIdInAppNotifications: (id: string, fk: string, params?: RequestParams) =>
      this.request<InAppNotification, any>(`/Accounts/${id}/inAppNotifications/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__inAppNotifications
     * @summary Delete a related item by id for inAppNotifications.
     * @request DELETE:/Accounts/{id}/inAppNotifications/{fk}
     */
    accountPrototypeDestroyByIdInAppNotifications: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/inAppNotifications/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__inAppNotifications
     * @summary Update a related item by id for inAppNotifications.
     * @request PUT:/Accounts/{id}/inAppNotifications/{fk}
     */
    accountPrototypeUpdateByIdInAppNotifications: (
      id: string,
      fk: string,
      data: InAppNotification,
      params?: RequestParams,
    ) => this.request<InAppNotification, any>(`/Accounts/${id}/inAppNotifications/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__observationNotifications
     * @summary Find a related item by id for observationNotifications.
     * @request GET:/Accounts/{id}/observationNotifications/{fk}
     */
    accountPrototypeFindByIdObservationNotifications: (id: string, fk: string, params?: RequestParams) =>
      this.request<ObservationNotification, any>(`/Accounts/${id}/observationNotifications/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__observationNotifications
     * @summary Delete a related item by id for observationNotifications.
     * @request DELETE:/Accounts/{id}/observationNotifications/{fk}
     */
    accountPrototypeDestroyByIdObservationNotifications: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/observationNotifications/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__observationNotifications
     * @summary Update a related item by id for observationNotifications.
     * @request PUT:/Accounts/{id}/observationNotifications/{fk}
     */
    accountPrototypeUpdateByIdObservationNotifications: (
      id: string,
      fk: string,
      data: ObservationNotification,
      params?: RequestParams,
    ) =>
      this.request<ObservationNotification, any>(`/Accounts/${id}/observationNotifications/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__get__tempServices
     * @summary Fetches hasOne relation tempServices.
     * @request GET:/Accounts/{id}/tempServices
     */
    accountPrototypeGetTempServices: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<TempService, any>(`/Accounts/${id}/tempServices${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__tempServices
     * @summary Creates a new instance in tempServices of this model.
     * @request POST:/Accounts/{id}/tempServices
     */
    accountPrototypeCreateTempServices: (id: string, data: TempService, params?: RequestParams) =>
      this.request<TempService, any>(`/Accounts/${id}/tempServices`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__update__tempServices
     * @summary Update tempServices of this model.
     * @request PUT:/Accounts/{id}/tempServices
     */
    accountPrototypeUpdateTempServices: (id: string, data: TempService, params?: RequestParams) =>
      this.request<TempService, any>(`/Accounts/${id}/tempServices`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__destroy__tempServices
     * @summary Deletes tempServices of this model.
     * @request DELETE:/Accounts/{id}/tempServices
     */
    accountPrototypeDestroyTempServices: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/tempServices`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__findById__verifications
     * @summary Find a related item by id for verifications.
     * @request GET:/Accounts/{id}/verifications/{fk}
     */
    accountPrototypeFindByIdVerifications: (id: string, fk: string, params?: RequestParams) =>
      this.request<Verifications, any>(`/Accounts/${id}/verifications/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__verifications
     * @summary Delete a related item by id for verifications.
     * @request DELETE:/Accounts/{id}/verifications/{fk}
     */
    accountPrototypeDestroyByIdVerifications: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/verifications/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__verifications
     * @summary Update a related item by id for verifications.
     * @request PUT:/Accounts/{id}/verifications/{fk}
     */
    accountPrototypeUpdateByIdVerifications: (id: string, fk: string, data: Verifications, params?: RequestParams) =>
      this.request<Verifications, any>(`/Accounts/${id}/verifications/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__ecoconduites
     * @summary Find a related item by id for ecoconduites.
     * @request GET:/Accounts/{id}/ecoconduites/{fk}
     */
    accountPrototypeFindByIdEcoconduites: (id: string, fk: string, params?: RequestParams) =>
      this.request<Ecoconduite, any>(`/Accounts/${id}/ecoconduites/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__ecoconduites
     * @summary Delete a related item by id for ecoconduites.
     * @request DELETE:/Accounts/{id}/ecoconduites/{fk}
     */
    accountPrototypeDestroyByIdEcoconduites: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/ecoconduites/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__ecoconduites
     * @summary Update a related item by id for ecoconduites.
     * @request PUT:/Accounts/{id}/ecoconduites/{fk}
     */
    accountPrototypeUpdateByIdEcoconduites: (id: string, fk: string, data: Ecoconduite, params?: RequestParams) =>
      this.request<Ecoconduite, any>(`/Accounts/${id}/ecoconduites/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__get__moteurs
     * @summary Fetches hasOne relation moteurs.
     * @request GET:/Accounts/{id}/moteurs
     */
    accountPrototypeGetMoteurs: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Moteur, any>(`/Accounts/${id}/moteurs${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__moteurs
     * @summary Creates a new instance in moteurs of this model.
     * @request POST:/Accounts/{id}/moteurs
     */
    accountPrototypeCreateMoteurs: (id: string, data: Moteur, params?: RequestParams) =>
      this.request<Moteur, any>(`/Accounts/${id}/moteurs`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__update__moteurs
     * @summary Update moteurs of this model.
     * @request PUT:/Accounts/{id}/moteurs
     */
    accountPrototypeUpdateMoteurs: (id: string, data: Moteur, params?: RequestParams) =>
      this.request<Moteur, any>(`/Accounts/${id}/moteurs`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__destroy__moteurs
     * @summary Deletes moteurs of this model.
     * @request DELETE:/Accounts/{id}/moteurs
     */
    accountPrototypeDestroyMoteurs: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/moteurs`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__findById__rHs
     * @summary Find a related item by id for rHs.
     * @request GET:/Accounts/{id}/rHs/{fk}
     */
    accountPrototypeFindByIdRHs: (id: string, fk: string, params?: RequestParams) =>
      this.request<RH, any>(`/Accounts/${id}/rHs/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__rHs
     * @summary Delete a related item by id for rHs.
     * @request DELETE:/Accounts/{id}/rHs/{fk}
     */
    accountPrototypeDestroyByIdRHs: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/rHs/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__rHs
     * @summary Update a related item by id for rHs.
     * @request PUT:/Accounts/{id}/rHs/{fk}
     */
    accountPrototypeUpdateByIdRHs: (id: string, fk: string, data: RH, params?: RequestParams) =>
      this.request<RH, any>(`/Accounts/${id}/rHs/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__accueils
     * @summary Find a related item by id for accueils.
     * @request GET:/Accounts/{id}/accueils/{fk}
     */
    accountPrototypeFindByIdAccueils: (id: string, fk: string, params?: RequestParams) =>
      this.request<Accueil, any>(`/Accounts/${id}/accueils/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__accueils
     * @summary Delete a related item by id for accueils.
     * @request DELETE:/Accounts/{id}/accueils/{fk}
     */
    accountPrototypeDestroyByIdAccueils: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/accueils/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__accueils
     * @summary Update a related item by id for accueils.
     * @request PUT:/Accounts/{id}/accueils/{fk}
     */
    accountPrototypeUpdateByIdAccueils: (id: string, fk: string, data: Accueil, params?: RequestParams) =>
      this.request<Accueil, any>(`/Accounts/${id}/accueils/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__get__tractes
     * @summary Fetches hasOne relation tractes.
     * @request GET:/Accounts/{id}/tractes
     */
    accountPrototypeGetTractes: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Tracte, any>(`/Accounts/${id}/tractes${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__tractes
     * @summary Creates a new instance in tractes of this model.
     * @request POST:/Accounts/{id}/tractes
     */
    accountPrototypeCreateTractes: (id: string, data: Tracte, params?: RequestParams) =>
      this.request<Tracte, any>(`/Accounts/${id}/tractes`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__update__tractes
     * @summary Update tractes of this model.
     * @request PUT:/Accounts/{id}/tractes
     */
    accountPrototypeUpdateTractes: (id: string, data: Tracte, params?: RequestParams) =>
      this.request<Tracte, any>(`/Accounts/${id}/tractes`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__destroy__tractes
     * @summary Deletes tractes of this model.
     * @request DELETE:/Accounts/{id}/tractes
     */
    accountPrototypeDestroyTractes: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/tractes`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__findById__parametres
     * @summary Find a related item by id for parametres.
     * @request GET:/Accounts/{id}/parametres/{fk}
     */
    accountPrototypeFindByIdParametres: (id: string, fk: string, params?: RequestParams) =>
      this.request<Parametres, any>(`/Accounts/${id}/parametres/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__parametres
     * @summary Delete a related item by id for parametres.
     * @request DELETE:/Accounts/{id}/parametres/{fk}
     */
    accountPrototypeDestroyByIdParametres: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/parametres/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__parametres
     * @summary Update a related item by id for parametres.
     * @request PUT:/Accounts/{id}/parametres/{fk}
     */
    accountPrototypeUpdateByIdParametres: (id: string, fk: string, data: Parametres, params?: RequestParams) =>
      this.request<Parametres, any>(`/Accounts/${id}/parametres/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__get__bu
     * @summary Fetches belongsTo relation bu.
     * @request GET:/Accounts/{id}/bu
     */
    accountPrototypeGetBu: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Bu, any>(`/Accounts/${id}/bu${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__get__codeAnalytique
     * @summary Fetches belongsTo relation codeAnalytique.
     * @request GET:/Accounts/{id}/codeAnalytique
     */
    accountPrototypeGetCodeAnalytique: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<CodeAnalytique, any>(`/Accounts/${id}/codeAnalytique${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__get__etablissement
     * @summary Fetches belongsTo relation etablissement.
     * @request GET:/Accounts/{id}/etablissement
     */
    accountPrototypeGetEtablissement: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Etablissement, any>(`/Accounts/${id}/etablissement${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__get__statistiques
     * @summary Fetches hasOne relation statistiques.
     * @request GET:/Accounts/{id}/statistiques
     */
    accountPrototypeGetStatistiques: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Statistiques, any>(`/Accounts/${id}/statistiques${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__statistiques
     * @summary Creates a new instance in statistiques of this model.
     * @request POST:/Accounts/{id}/statistiques
     */
    accountPrototypeCreateStatistiques: (id: string, data: Statistiques, params?: RequestParams) =>
      this.request<Statistiques, any>(`/Accounts/${id}/statistiques`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__update__statistiques
     * @summary Update statistiques of this model.
     * @request PUT:/Accounts/{id}/statistiques
     */
    accountPrototypeUpdateStatistiques: (id: string, data: Statistiques, params?: RequestParams) =>
      this.request<Statistiques, any>(`/Accounts/${id}/statistiques`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__destroy__statistiques
     * @summary Deletes statistiques of this model.
     * @request DELETE:/Accounts/{id}/statistiques
     */
    accountPrototypeDestroyStatistiques: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/statistiques`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__get__roles
     * @summary Queries roles of Account.
     * @request GET:/Accounts/{id}/roles
     */
    accountPrototypeGetRoles: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Role[], any>(`/Accounts/${id}/roles${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__roles
     * @summary Creates a new instance in roles of this model.
     * @request POST:/Accounts/{id}/roles
     */
    accountPrototypeCreateRoles: (id: string, data: Role, params?: RequestParams) =>
      this.request<Role, any>(`/Accounts/${id}/roles`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__roles
     * @summary Deletes all roles of this model.
     * @request DELETE:/Accounts/{id}/roles
     */
    accountPrototypeDeleteRoles: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/roles`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__roles
     * @summary Counts roles of Account.
     * @request GET:/Accounts/{id}/roles/count
     */
    accountPrototypeCountRoles: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Accounts/${id}/roles/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__get__accessTokens
     * @summary Queries accessTokens of Account.
     * @request GET:/Accounts/{id}/accessTokens
     */
    accountPrototypeGetAccessTokens: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountToken[], any>(`/Accounts/${id}/accessTokens${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__accessTokens
     * @summary Creates a new instance in accessTokens of this model.
     * @request POST:/Accounts/{id}/accessTokens
     */
    accountPrototypeCreateAccessTokens: (id: string, data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/Accounts/${id}/accessTokens`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__accessTokens
     * @summary Deletes all accessTokens of this model.
     * @request DELETE:/Accounts/{id}/accessTokens
     */
    accountPrototypeDeleteAccessTokens: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/accessTokens`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__accessTokens
     * @summary Counts accessTokens of Account.
     * @request GET:/Accounts/{id}/accessTokens/count
     */
    accountPrototypeCountAccessTokens: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/accessTokens/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__contacts
     * @summary Queries contacts of Account.
     * @request GET:/Accounts/{id}/contacts
     */
    accountPrototypeGetContacts: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Contact[], any>(`/Accounts/${id}/contacts${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__contacts
     * @summary Creates a new instance in contacts of this model.
     * @request POST:/Accounts/{id}/contacts
     */
    accountPrototypeCreateContacts: (id: string, data: Contact, params?: RequestParams) =>
      this.request<Contact, any>(`/Accounts/${id}/contacts`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__contacts
     * @summary Deletes all contacts of this model.
     * @request DELETE:/Accounts/{id}/contacts
     */
    accountPrototypeDeleteContacts: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/contacts`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__contacts
     * @summary Counts contacts of Account.
     * @request GET:/Accounts/{id}/contacts/count
     */
    accountPrototypeCountContacts: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/contacts/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__assurances
     * @summary Queries assurances of Account.
     * @request GET:/Accounts/{id}/assurances
     */
    accountPrototypeGetAssurances: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Assurance[], any>(`/Accounts/${id}/assurances${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__assurances
     * @summary Creates a new instance in assurances of this model.
     * @request POST:/Accounts/{id}/assurances
     */
    accountPrototypeCreateAssurances: (id: string, data: Assurance, params?: RequestParams) =>
      this.request<Assurance, any>(`/Accounts/${id}/assurances`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__assurances
     * @summary Deletes all assurances of this model.
     * @request DELETE:/Accounts/{id}/assurances
     */
    accountPrototypeDeleteAssurances: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/assurances`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__assurances
     * @summary Counts assurances of Account.
     * @request GET:/Accounts/{id}/assurances/count
     */
    accountPrototypeCountAssurances: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/assurances/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__photos
     * @summary Queries photos of Account.
     * @request GET:/Accounts/{id}/photos
     */
    accountPrototypeGetPhotos: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Photo[], any>(`/Accounts/${id}/photos${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__photos
     * @summary Creates a new instance in photos of this model.
     * @request POST:/Accounts/{id}/photos
     */
    accountPrototypeCreatePhotos: (id: string, data: Photo, params?: RequestParams) =>
      this.request<Photo, any>(`/Accounts/${id}/photos`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__photos
     * @summary Deletes all photos of this model.
     * @request DELETE:/Accounts/{id}/photos
     */
    accountPrototypeDeletePhotos: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/photos`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__photos
     * @summary Counts photos of Account.
     * @request GET:/Accounts/{id}/photos/count
     */
    accountPrototypeCountPhotos: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Accounts/${id}/photos/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__get__media
     * @summary Queries media of Account.
     * @request GET:/Accounts/{id}/media
     */
    accountPrototypeGetMedia: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Media[], any>(`/Accounts/${id}/media${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__media
     * @summary Creates a new instance in media of this model.
     * @request POST:/Accounts/{id}/media
     */
    accountPrototypeCreateMedia: (id: string, data: Media, params?: RequestParams) =>
      this.request<Media, any>(`/Accounts/${id}/media`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__media
     * @summary Deletes all media of this model.
     * @request DELETE:/Accounts/{id}/media
     */
    accountPrototypeDeleteMedia: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/media`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__media
     * @summary Counts media of Account.
     * @request GET:/Accounts/{id}/media/count
     */
    accountPrototypeCountMedia: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Accounts/${id}/media/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__get__reserves
     * @summary Queries reserves of Account.
     * @request GET:/Accounts/{id}/reserves
     */
    accountPrototypeGetReserves: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Reserve[], any>(`/Accounts/${id}/reserves${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__reserves
     * @summary Creates a new instance in reserves of this model.
     * @request POST:/Accounts/{id}/reserves
     */
    accountPrototypeCreateReserves: (id: string, data: Reserve, params?: RequestParams) =>
      this.request<Reserve, any>(`/Accounts/${id}/reserves`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__reserves
     * @summary Deletes all reserves of this model.
     * @request DELETE:/Accounts/{id}/reserves
     */
    accountPrototypeDeleteReserves: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/reserves`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__reserves
     * @summary Counts reserves of Account.
     * @request GET:/Accounts/{id}/reserves/count
     */
    accountPrototypeCountReserves: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/reserves/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__etatDeLieuxes
     * @summary Queries etatDeLieuxes of Account.
     * @request GET:/Accounts/{id}/etatDeLieuxes
     */
    accountPrototypeGetEtatDeLieuxes: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<EtatDeLieux[], any>(`/Accounts/${id}/etatDeLieuxes${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__etatDeLieuxes
     * @summary Creates a new instance in etatDeLieuxes of this model.
     * @request POST:/Accounts/{id}/etatDeLieuxes
     */
    accountPrototypeCreateEtatDeLieuxes: (id: string, data: EtatDeLieux, params?: RequestParams) =>
      this.request<EtatDeLieux, any>(`/Accounts/${id}/etatDeLieuxes`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__etatDeLieuxes
     * @summary Deletes all etatDeLieuxes of this model.
     * @request DELETE:/Accounts/{id}/etatDeLieuxes
     */
    accountPrototypeDeleteEtatDeLieuxes: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/etatDeLieuxes`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__etatDeLieuxes
     * @summary Counts etatDeLieuxes of Account.
     * @request GET:/Accounts/{id}/etatDeLieuxes/count
     */
    accountPrototypeCountEtatDeLieuxes: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/etatDeLieuxes/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__missions
     * @summary Queries missions of Account.
     * @request GET:/Accounts/{id}/missions
     */
    accountPrototypeGetMissions: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Mission[], any>(`/Accounts/${id}/missions${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__missions
     * @summary Creates a new instance in missions of this model.
     * @request POST:/Accounts/{id}/missions
     */
    accountPrototypeCreateMissions: (id: string, data: Mission, params?: RequestParams) =>
      this.request<Mission, any>(`/Accounts/${id}/missions`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__missions
     * @summary Deletes all missions of this model.
     * @request DELETE:/Accounts/{id}/missions
     */
    accountPrototypeDeleteMissions: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/missions`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__missions
     * @summary Counts missions of Account.
     * @request GET:/Accounts/{id}/missions/count
     */
    accountPrototypeCountMissions: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/missions/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__inAppNotifications
     * @summary Queries inAppNotifications of Account.
     * @request GET:/Accounts/{id}/inAppNotifications
     */
    accountPrototypeGetInAppNotifications: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<InAppNotification[], any>(
        `/Accounts/${id}/inAppNotifications${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__create__inAppNotifications
     * @summary Creates a new instance in inAppNotifications of this model.
     * @request POST:/Accounts/{id}/inAppNotifications
     */
    accountPrototypeCreateInAppNotifications: (id: string, data: InAppNotification, params?: RequestParams) =>
      this.request<InAppNotification, any>(`/Accounts/${id}/inAppNotifications`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__inAppNotifications
     * @summary Deletes all inAppNotifications of this model.
     * @request DELETE:/Accounts/{id}/inAppNotifications
     */
    accountPrototypeDeleteInAppNotifications: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/inAppNotifications`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__inAppNotifications
     * @summary Counts inAppNotifications of Account.
     * @request GET:/Accounts/{id}/inAppNotifications/count
     */
    accountPrototypeCountInAppNotifications: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/inAppNotifications/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__observationNotifications
     * @summary Queries observationNotifications of Account.
     * @request GET:/Accounts/{id}/observationNotifications
     */
    accountPrototypeGetObservationNotifications: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<ObservationNotification[], any>(
        `/Accounts/${id}/observationNotifications${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__create__observationNotifications
     * @summary Creates a new instance in observationNotifications of this model.
     * @request POST:/Accounts/{id}/observationNotifications
     */
    accountPrototypeCreateObservationNotifications: (
      id: string,
      data: ObservationNotification,
      params?: RequestParams,
    ) => this.request<ObservationNotification, any>(`/Accounts/${id}/observationNotifications`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__observationNotifications
     * @summary Deletes all observationNotifications of this model.
     * @request DELETE:/Accounts/{id}/observationNotifications
     */
    accountPrototypeDeleteObservationNotifications: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/observationNotifications`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__observationNotifications
     * @summary Counts observationNotifications of Account.
     * @request GET:/Accounts/{id}/observationNotifications/count
     */
    accountPrototypeCountObservationNotifications: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/observationNotifications/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__verifications
     * @summary Queries verifications of Account.
     * @request GET:/Accounts/{id}/verifications
     */
    accountPrototypeGetVerifications: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Verifications[], any>(`/Accounts/${id}/verifications${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__verifications
     * @summary Creates a new instance in verifications of this model.
     * @request POST:/Accounts/{id}/verifications
     */
    accountPrototypeCreateVerifications: (id: string, data: Verifications, params?: RequestParams) =>
      this.request<Verifications, any>(`/Accounts/${id}/verifications`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__verifications
     * @summary Deletes all verifications of this model.
     * @request DELETE:/Accounts/{id}/verifications
     */
    accountPrototypeDeleteVerifications: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/verifications`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__verifications
     * @summary Counts verifications of Account.
     * @request GET:/Accounts/{id}/verifications/count
     */
    accountPrototypeCountVerifications: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/verifications/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__ecoconduites
     * @summary Queries ecoconduites of Account.
     * @request GET:/Accounts/{id}/ecoconduites
     */
    accountPrototypeGetEcoconduites: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Ecoconduite[], any>(`/Accounts/${id}/ecoconduites${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__ecoconduites
     * @summary Creates a new instance in ecoconduites of this model.
     * @request POST:/Accounts/{id}/ecoconduites
     */
    accountPrototypeCreateEcoconduites: (id: string, data: Ecoconduite, params?: RequestParams) =>
      this.request<Ecoconduite, any>(`/Accounts/${id}/ecoconduites`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__ecoconduites
     * @summary Deletes all ecoconduites of this model.
     * @request DELETE:/Accounts/{id}/ecoconduites
     */
    accountPrototypeDeleteEcoconduites: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/ecoconduites`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__ecoconduites
     * @summary Counts ecoconduites of Account.
     * @request GET:/Accounts/{id}/ecoconduites/count
     */
    accountPrototypeCountEcoconduites: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/ecoconduites/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__rHs
     * @summary Queries rHs of Account.
     * @request GET:/Accounts/{id}/rHs
     */
    accountPrototypeGetRHs: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<RH[], any>(`/Accounts/${id}/rHs${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__rHs
     * @summary Creates a new instance in rHs of this model.
     * @request POST:/Accounts/{id}/rHs
     */
    accountPrototypeCreateRHs: (id: string, data: RH, params?: RequestParams) =>
      this.request<RH, any>(`/Accounts/${id}/rHs`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__rHs
     * @summary Deletes all rHs of this model.
     * @request DELETE:/Accounts/{id}/rHs
     */
    accountPrototypeDeleteRHs: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/rHs`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__rHs
     * @summary Counts rHs of Account.
     * @request GET:/Accounts/{id}/rHs/count
     */
    accountPrototypeCountRHs: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Accounts/${id}/rHs/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__get__accueils
     * @summary Queries accueils of Account.
     * @request GET:/Accounts/{id}/accueils
     */
    accountPrototypeGetAccueils: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Accueil[], any>(`/Accounts/${id}/accueils${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__accueils
     * @summary Creates a new instance in accueils of this model.
     * @request POST:/Accounts/{id}/accueils
     */
    accountPrototypeCreateAccueils: (id: string, data: Accueil, params?: RequestParams) =>
      this.request<Accueil, any>(`/Accounts/${id}/accueils`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__accueils
     * @summary Deletes all accueils of this model.
     * @request DELETE:/Accounts/{id}/accueils
     */
    accountPrototypeDeleteAccueils: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/accueils`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__accueils
     * @summary Counts accueils of Account.
     * @request GET:/Accounts/{id}/accueils/count
     */
    accountPrototypeCountAccueils: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/accueils/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__parametres
     * @summary Queries parametres of Account.
     * @request GET:/Accounts/{id}/parametres
     */
    accountPrototypeGetParametres: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Parametres[], any>(`/Accounts/${id}/parametres${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__parametres
     * @summary Creates a new instance in parametres of this model.
     * @request POST:/Accounts/{id}/parametres
     */
    accountPrototypeCreateParametres: (id: string, data: Parametres, params?: RequestParams) =>
      this.request<Parametres, any>(`/Accounts/${id}/parametres`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__parametres
     * @summary Deletes all parametres of this model.
     * @request DELETE:/Accounts/{id}/parametres
     */
    accountPrototypeDeleteParametres: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/parametres`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__parametres
     * @summary Counts parametres of Account.
     * @request GET:/Accounts/{id}/parametres/count
     */
    accountPrototypeCountParametres: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/parametres/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Accounts
     */
    accountCreate: (data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Accounts
     */
    accountPatchOrCreate: (data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts`, "PATCH", params, data),

    /**
     * @tags Account
     * @name Account.replaceOrCreate__put_Accounts
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Accounts
     */
    accountReplaceOrCreatePutAccounts: (data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Accounts
     */
    accountFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Account[], any>(`/Accounts${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.replaceOrCreate__post_Accounts_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Accounts/replaceOrCreate
     */
    accountReplaceOrCreatePostAccountsReplaceOrCreate: (data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Accounts/upsertWithWhere
     */
    accountUpsertWithWhere: (data: Account, query?: { where?: string }, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.exists__get_Accounts_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Accounts/{id}/exists
     */
    accountExistsGetAccountsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Accounts/${id}/exists`, "GET", params),

    /**
     * @tags Account
     * @name Account.exists__head_Accounts_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Accounts/{id}
     */
    accountExistsHeadAccountsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Accounts/${id}`, "HEAD", params),

    /**
     * @tags Account
     * @name Account.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Accounts/{id}
     */
    accountFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.replaceById__put_Accounts_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Accounts/{id}
     */
    accountReplaceByIdPutAccountsId: (id: string, data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/${id}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Accounts/{id}
     */
    accountDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Accounts/${id}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Accounts/{id}
     */
    accountPrototypePatchAttributes: (id: string, data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/${id}`, "PATCH", params, data),

    /**
     * @tags Account
     * @name Account.replaceById__post_Accounts_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Accounts/{id}/replace
     */
    accountReplaceByIdPostAccountsIdReplace: (id: string, data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/${id}/replace`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Accounts/findOne
     */
    accountFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Accounts/update
     */
    accountUpdateAll: (data: Account, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Accounts/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Accounts/count
     */
    accountCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Accounts/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.createChangeStream__post_Accounts_change-stream
     * @summary Create a change stream.
     * @request POST:/Accounts/change-stream
     */
    accountCreateChangeStreamPostAccountsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Accounts/change-stream`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.createChangeStream__get_Accounts_change-stream
     * @summary Create a change stream.
     * @request GET:/Accounts/change-stream
     */
    accountCreateChangeStreamGetAccountsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Accounts/change-stream${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.login
     * @summary Login a user with username/email and password.
     * @request POST:/Accounts/login
     */
    accountLogin: (credentials: object, query?: { include?: string }, params?: RequestParams) =>
      this.request<object, any>(`/Accounts/login${this.addQueryParams(query)}`, "POST", params, credentials),

    /**
     * @tags Account
     * @name Account.logout
     * @summary Logout a user with access token.
     * @request POST:/Accounts/logout
     */
    accountLogout: (params?: RequestParams) => this.request<any, any>(`/Accounts/logout`, "POST", params),

    /**
     * @tags Account
     * @name Account.prototype.verify
     * @summary Trigger user's identity verification with configured verifyOptions
     * @request POST:/Accounts/{id}/verify
     */
    accountPrototypeVerify: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/verify`, "POST", params),

    /**
     * @tags Account
     * @name Account.confirm
     * @summary Confirm a user registration with identity verification token.
     * @request GET:/Accounts/confirm
     */
    accountConfirm: (query: { uid: string; token: string; redirect?: string }, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/confirm${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.resetPassword
     * @summary Reset password for a user with email.
     * @request POST:/Accounts/reset
     */
    accountResetPassword: (options: object, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/reset`, "POST", params, options),

    /**
     * @tags Account
     * @name Account.changePassword
     * @summary Change a user's password.
     * @request POST:/Accounts/change-password
     */
    accountChangePassword: (data: { oldPassword: string; newPassword: string }, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/change-password`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.setPassword
     * @summary Reset user's password via a password-reset token.
     * @request POST:/Accounts/reset-password
     */
    accountSetPassword: (data: { newPassword: string }, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/reset-password`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.gmailCheckToken
     * @summary Check gmail oauth token
     * @request GET:/Accounts/{id}/gmail/checkToken
     */
    accountPrototypeGmailCheckToken: (id: string, params?: RequestParams) =>
      this.request<string, any>(`/Accounts/${id}/gmail/checkToken`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.gmailAuthUrl
     * @summary Get google oauth url
     * @request GET:/Accounts/{id}/gmail/authUrl
     */
    accountPrototypeGmailAuthUrl: (id: string, params?: RequestParams) =>
      this.request<string, any>(`/Accounts/${id}/gmail/authUrl`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.gmailPushAuthCode
     * @summary Post auth code
     * @request POST:/Accounts/{id}/gmail/authCode
     */
    accountPrototypeGmailPushAuthCode: (id: string, code: string, params?: RequestParams) =>
      this.request<object, any>(`/Accounts/${id}/gmail/authCode`, "POST", params, code),

    /**
     * @tags Account
     * @name Account.prototype.gmailInboxes
     * @summary Get inboxes of gmail
     * @request GET:/Accounts/{id}/gmail/inboxes
     */
    accountPrototypeGmailInboxes: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Accounts/${id}/gmail/inboxes`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.gmailMessage
     * @summary Get inbox content
     * @request GET:/Accounts/{id}/gmail/inboxes/{messageId}
     */
    accountPrototypeGmailMessage: (id: string, messageId: string, params?: RequestParams) =>
      this.request<object, any>(`/Accounts/${id}/gmail/inboxes/${messageId}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.gmailSend
     * @summary Get inbox content
     * @request POST:/Accounts/{id}/gmail/send
     */
    accountPrototypeGmailSend: (id: string, message: object, params?: RequestParams) =>
      this.request<object, any>(`/Accounts/${id}/gmail/send`, "POST", params, message),

    /**
     * @tags Account
     * @name Account.getAuthToken
     * @summary Get token
     * @request POST:/Accounts/getAuthToken
     */
    accountGetAuthToken: (query: { serialNumber: string; token: string }, params?: RequestParams) =>
      this.request<AccountToken, any>(`/Accounts/getAuthToken${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags Account
     * @name Account.sendPdfEmail
     * @summary Send email with pdf
     * @request POST:/Accounts/sendPdfMail
     */
    accountSendPdfEmail: (data: { societeId: number; fileAttachment?: string }, params?: RequestParams) =>
      this.request<string, any>(`/Accounts/sendPdfMail`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.verifySerialNumber
     * @summary Get token
     * @request POST:/Accounts/verifySerialNumber
     */
    accountVerifySerialNumber: (query: { encryptedSerialNumber: string }, params?: RequestParams) =>
      this.request<XAny, any>(`/Accounts/verifySerialNumber${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags Account
     * @name Account.verifySecurityCode
     * @summary Verify code Security and get token
     * @request POST:/Accounts/verifyCodeSecurity
     */
    accountVerifySecurityCode: (
      query: { encryptedCodeSecurity: string; encryptedSerialNumber: string },
      params?: RequestParams,
    ) => this.request<AccountToken, any>(`/Accounts/verifyCodeSecurity${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags Account
     * @name Account.configSecurityCode
     * @summary Verify code Security and get token
     * @request POST:/Accounts/configCodeSecurity
     */
    accountConfigSecurityCode: (
      query: { encryptedCodeSecurity: string; encryptedSerialNumber: string },
      params?: RequestParams,
    ) => this.request<AccountToken, any>(`/Accounts/configCodeSecurity${this.addQueryParams(query)}`, "POST", params),
  };
  accountTokens = {
    /**
     * @tags AccountToken
     * @name AccountToken.prototype.__get__user
     * @summary Fetches belongsTo relation user.
     * @request GET:/AccountTokens/{id}/user
     */
    accountTokenPrototypeGetUser: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/AccountTokens/${id}/user${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountToken
     * @name AccountToken.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/AccountTokens
     */
    accountTokenCreate: (data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens`, "POST", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/AccountTokens
     */
    accountTokenPatchOrCreate: (data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens`, "PATCH", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.replaceOrCreate__put_AccountTokens
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/AccountTokens
     */
    accountTokenReplaceOrCreatePutAccountTokens: (data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens`, "PUT", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/AccountTokens
     */
    accountTokenFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountToken[], any>(`/AccountTokens${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountToken
     * @name AccountToken.replaceOrCreate__post_AccountTokens_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/AccountTokens/replaceOrCreate
     */
    accountTokenReplaceOrCreatePostAccountTokensReplaceOrCreate: (data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens/replaceOrCreate`, "POST", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/AccountTokens/upsertWithWhere
     */
    accountTokenUpsertWithWhere: (data: AccountToken, query?: { where?: string }, params?: RequestParams) =>
      this.request<AccountToken, any>(
        `/AccountTokens/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags AccountToken
     * @name AccountToken.exists__get_AccountTokens_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/AccountTokens/{id}/exists
     */
    accountTokenExistsGetAccountTokensIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/AccountTokens/${id}/exists`, "GET", params),

    /**
     * @tags AccountToken
     * @name AccountToken.exists__head_AccountTokens_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/AccountTokens/{id}
     */
    accountTokenExistsHeadAccountTokensId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/AccountTokens/${id}`, "HEAD", params),

    /**
     * @tags AccountToken
     * @name AccountToken.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/AccountTokens/{id}
     */
    accountTokenFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountToken
     * @name AccountToken.replaceById__put_AccountTokens_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/AccountTokens/{id}
     */
    accountTokenReplaceByIdPutAccountTokensId: (id: string, data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens/${id}`, "PUT", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/AccountTokens/{id}
     */
    accountTokenDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/AccountTokens/${id}`, "DELETE", params),

    /**
     * @tags AccountToken
     * @name AccountToken.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/AccountTokens/{id}
     */
    accountTokenPrototypePatchAttributes: (id: string, data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens/${id}`, "PATCH", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.replaceById__post_AccountTokens_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/AccountTokens/{id}/replace
     */
    accountTokenReplaceByIdPostAccountTokensIdReplace: (id: string, data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens/${id}/replace`, "POST", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/AccountTokens/findOne
     */
    accountTokenFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountToken
     * @name AccountToken.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/AccountTokens/update
     */
    accountTokenUpdateAll: (data: AccountToken, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/AccountTokens/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/AccountTokens/count
     */
    accountTokenCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/AccountTokens/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountToken
     * @name AccountToken.createChangeStream__post_AccountTokens_change-stream
     * @summary Create a change stream.
     * @request POST:/AccountTokens/change-stream
     */
    accountTokenCreateChangeStreamPostAccountTokensChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/AccountTokens/change-stream`, "POST", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.createChangeStream__get_AccountTokens_change-stream
     * @summary Create a change stream.
     * @request GET:/AccountTokens/change-stream
     */
    accountTokenCreateChangeStreamGetAccountTokensChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/AccountTokens/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  societes = {
    /**
     * @tags Societe
     * @name Societe.prototype.__findById__accounts
     * @summary Find a related item by id for accounts.
     * @request GET:/Societes/{id}/accounts/{fk}
     */
    societePrototypeFindByIdAccounts: (id: string, fk: string, params?: RequestParams) =>
      this.request<Account, any>(`/Societes/${id}/accounts/${fk}`, "GET", params),

    /**
     * @tags Societe
     * @name Societe.prototype.__destroyById__accounts
     * @summary Delete a related item by id for accounts.
     * @request DELETE:/Societes/{id}/accounts/{fk}
     */
    societePrototypeDestroyByIdAccounts: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Societes/${id}/accounts/${fk}`, "DELETE", params),

    /**
     * @tags Societe
     * @name Societe.prototype.__updateById__accounts
     * @summary Update a related item by id for accounts.
     * @request PUT:/Societes/{id}/accounts/{fk}
     */
    societePrototypeUpdateByIdAccounts: (id: string, fk: string, data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Societes/${id}/accounts/${fk}`, "PUT", params, data),

    /**
     * @tags Societe
     * @name Societe.prototype.__findById__contacts
     * @summary Find a related item by id for contacts.
     * @request GET:/Societes/{id}/contacts/{fk}
     */
    societePrototypeFindByIdContacts: (id: string, fk: string, params?: RequestParams) =>
      this.request<Contact, any>(`/Societes/${id}/contacts/${fk}`, "GET", params),

    /**
     * @tags Societe
     * @name Societe.prototype.__destroyById__contacts
     * @summary Delete a related item by id for contacts.
     * @request DELETE:/Societes/{id}/contacts/{fk}
     */
    societePrototypeDestroyByIdContacts: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Societes/${id}/contacts/${fk}`, "DELETE", params),

    /**
     * @tags Societe
     * @name Societe.prototype.__updateById__contacts
     * @summary Update a related item by id for contacts.
     * @request PUT:/Societes/{id}/contacts/{fk}
     */
    societePrototypeUpdateByIdContacts: (id: string, fk: string, data: Contact, params?: RequestParams) =>
      this.request<Contact, any>(`/Societes/${id}/contacts/${fk}`, "PUT", params, data),

    /**
     * @tags Societe
     * @name Societe.prototype.__get__statGlobal
     * @summary Fetches hasOne relation statGlobal.
     * @request GET:/Societes/{id}/statGlobal
     */
    societePrototypeGetStatGlobal: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/Societes/${id}/statGlobal${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Societe
     * @name Societe.prototype.__create__statGlobal
     * @summary Creates a new instance in statGlobal of this model.
     * @request POST:/Societes/{id}/statGlobal
     */
    societePrototypeCreateStatGlobal: (id: string, data: StatGlobal, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/Societes/${id}/statGlobal`, "POST", params, data),

    /**
     * @tags Societe
     * @name Societe.prototype.__update__statGlobal
     * @summary Update statGlobal of this model.
     * @request PUT:/Societes/{id}/statGlobal
     */
    societePrototypeUpdateStatGlobal: (id: string, data: StatGlobal, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/Societes/${id}/statGlobal`, "PUT", params, data),

    /**
     * @tags Societe
     * @name Societe.prototype.__destroy__statGlobal
     * @summary Deletes statGlobal of this model.
     * @request DELETE:/Societes/{id}/statGlobal
     */
    societePrototypeDestroyStatGlobal: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Societes/${id}/statGlobal`, "DELETE", params),

    /**
     * @tags Societe
     * @name Societe.prototype.__get__accounts
     * @summary Queries accounts of Societe.
     * @request GET:/Societes/{id}/accounts
     */
    societePrototypeGetAccounts: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Account[], any>(`/Societes/${id}/accounts${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Societe
     * @name Societe.prototype.__create__accounts
     * @summary Creates a new instance in accounts of this model.
     * @request POST:/Societes/{id}/accounts
     */
    societePrototypeCreateAccounts: (id: string, data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Societes/${id}/accounts`, "POST", params, data),

    /**
     * @tags Societe
     * @name Societe.prototype.__delete__accounts
     * @summary Deletes all accounts of this model.
     * @request DELETE:/Societes/{id}/accounts
     */
    societePrototypeDeleteAccounts: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Societes/${id}/accounts`, "DELETE", params),

    /**
     * @tags Societe
     * @name Societe.prototype.__count__accounts
     * @summary Counts accounts of Societe.
     * @request GET:/Societes/{id}/accounts/count
     */
    societePrototypeCountAccounts: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Societes/${id}/accounts/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Societe
     * @name Societe.prototype.__get__contacts
     * @summary Queries contacts of Societe.
     * @request GET:/Societes/{id}/contacts
     */
    societePrototypeGetContacts: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Contact[], any>(`/Societes/${id}/contacts${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Societe
     * @name Societe.prototype.__create__contacts
     * @summary Creates a new instance in contacts of this model.
     * @request POST:/Societes/{id}/contacts
     */
    societePrototypeCreateContacts: (id: string, data: Contact, params?: RequestParams) =>
      this.request<Contact, any>(`/Societes/${id}/contacts`, "POST", params, data),

    /**
     * @tags Societe
     * @name Societe.prototype.__delete__contacts
     * @summary Deletes all contacts of this model.
     * @request DELETE:/Societes/{id}/contacts
     */
    societePrototypeDeleteContacts: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Societes/${id}/contacts`, "DELETE", params),

    /**
     * @tags Societe
     * @name Societe.prototype.__count__contacts
     * @summary Counts contacts of Societe.
     * @request GET:/Societes/{id}/contacts/count
     */
    societePrototypeCountContacts: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Societes/${id}/contacts/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Societe
     * @name Societe.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Societes
     */
    societeCreate: (data: Societe, params?: RequestParams) =>
      this.request<Societe, any>(`/Societes`, "POST", params, data),

    /**
     * @tags Societe
     * @name Societe.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Societes
     */
    societePatchOrCreate: (data: Societe, params?: RequestParams) =>
      this.request<Societe, any>(`/Societes`, "PATCH", params, data),

    /**
     * @tags Societe
     * @name Societe.replaceOrCreate__put_Societes
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Societes
     */
    societeReplaceOrCreatePutSocietes: (data: Societe, params?: RequestParams) =>
      this.request<Societe, any>(`/Societes`, "PUT", params, data),

    /**
     * @tags Societe
     * @name Societe.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Societes
     */
    societeFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Societe[], any>(`/Societes${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Societe
     * @name Societe.replaceOrCreate__post_Societes_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Societes/replaceOrCreate
     */
    societeReplaceOrCreatePostSocietesReplaceOrCreate: (data: Societe, params?: RequestParams) =>
      this.request<Societe, any>(`/Societes/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Societe
     * @name Societe.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Societes/upsertWithWhere
     */
    societeUpsertWithWhere: (data: Societe, query?: { where?: string }, params?: RequestParams) =>
      this.request<Societe, any>(`/Societes/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Societe
     * @name Societe.exists__get_Societes_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Societes/{id}/exists
     */
    societeExistsGetSocietesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Societes/${id}/exists`, "GET", params),

    /**
     * @tags Societe
     * @name Societe.exists__head_Societes_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Societes/{id}
     */
    societeExistsHeadSocietesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Societes/${id}`, "HEAD", params),

    /**
     * @tags Societe
     * @name Societe.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Societes/{id}
     */
    societeFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Societe, any>(`/Societes/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Societe
     * @name Societe.replaceById__put_Societes_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Societes/{id}
     */
    societeReplaceByIdPutSocietesId: (id: string, data: Societe, params?: RequestParams) =>
      this.request<Societe, any>(`/Societes/${id}`, "PUT", params, data),

    /**
     * @tags Societe
     * @name Societe.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Societes/{id}
     */
    societeDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Societes/${id}`, "DELETE", params),

    /**
     * @tags Societe
     * @name Societe.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Societes/{id}
     */
    societePrototypePatchAttributes: (id: string, data: Societe, params?: RequestParams) =>
      this.request<Societe, any>(`/Societes/${id}`, "PATCH", params, data),

    /**
     * @tags Societe
     * @name Societe.replaceById__post_Societes_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Societes/{id}/replace
     */
    societeReplaceByIdPostSocietesIdReplace: (id: string, data: Societe, params?: RequestParams) =>
      this.request<Societe, any>(`/Societes/${id}/replace`, "POST", params, data),

    /**
     * @tags Societe
     * @name Societe.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Societes/findOne
     */
    societeFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Societe, any>(`/Societes/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Societe
     * @name Societe.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Societes/update
     */
    societeUpdateAll: (data: Societe, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Societes/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Societe
     * @name Societe.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Societes/count
     */
    societeCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Societes/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Societe
     * @name Societe.createChangeStream__post_Societes_change-stream
     * @summary Create a change stream.
     * @request POST:/Societes/change-stream
     */
    societeCreateChangeStreamPostSocietesChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Societes/change-stream`, "POST", params, data),

    /**
     * @tags Societe
     * @name Societe.createChangeStream__get_Societes_change-stream
     * @summary Create a change stream.
     * @request GET:/Societes/change-stream
     */
    societeCreateChangeStreamGetSocietesChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Societes/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  contacts = {
    /**
     * @tags Contact
     * @name Contact.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/Contacts/{id}/account
     */
    contactPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Contacts/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Contact
     * @name Contact.prototype.__get__societe
     * @summary Fetches belongsTo relation societe.
     * @request GET:/Contacts/{id}/societe
     */
    contactPrototypeGetSociete: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Societe, any>(`/Contacts/${id}/societe${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Contact
     * @name Contact.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Contacts
     */
    contactCreate: (data: Contact, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts`, "POST", params, data),

    /**
     * @tags Contact
     * @name Contact.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Contacts
     */
    contactPatchOrCreate: (data: Contact, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts`, "PATCH", params, data),

    /**
     * @tags Contact
     * @name Contact.replaceOrCreate__put_Contacts
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Contacts
     */
    contactReplaceOrCreatePutContacts: (data: Contact, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts`, "PUT", params, data),

    /**
     * @tags Contact
     * @name Contact.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Contacts
     */
    contactFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Contact[], any>(`/Contacts${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Contact
     * @name Contact.replaceOrCreate__post_Contacts_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Contacts/replaceOrCreate
     */
    contactReplaceOrCreatePostContactsReplaceOrCreate: (data: Contact, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Contact
     * @name Contact.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Contacts/upsertWithWhere
     */
    contactUpsertWithWhere: (data: Contact, query?: { where?: string }, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Contact
     * @name Contact.exists__get_Contacts_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Contacts/{id}/exists
     */
    contactExistsGetContactsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Contacts/${id}/exists`, "GET", params),

    /**
     * @tags Contact
     * @name Contact.exists__head_Contacts_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Contacts/{id}
     */
    contactExistsHeadContactsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Contacts/${id}`, "HEAD", params),

    /**
     * @tags Contact
     * @name Contact.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Contacts/{id}
     */
    contactFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Contact
     * @name Contact.replaceById__put_Contacts_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Contacts/{id}
     */
    contactReplaceByIdPutContactsId: (id: string, data: Contact, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts/${id}`, "PUT", params, data),

    /**
     * @tags Contact
     * @name Contact.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Contacts/{id}
     */
    contactDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Contacts/${id}`, "DELETE", params),

    /**
     * @tags Contact
     * @name Contact.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Contacts/{id}
     */
    contactPrototypePatchAttributes: (id: string, data: Contact, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts/${id}`, "PATCH", params, data),

    /**
     * @tags Contact
     * @name Contact.replaceById__post_Contacts_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Contacts/{id}/replace
     */
    contactReplaceByIdPostContactsIdReplace: (id: string, data: Contact, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts/${id}/replace`, "POST", params, data),

    /**
     * @tags Contact
     * @name Contact.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Contacts/findOne
     */
    contactFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Contact, any>(`/Contacts/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Contact
     * @name Contact.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Contacts/update
     */
    contactUpdateAll: (data: Contact, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Contacts/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Contact
     * @name Contact.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Contacts/count
     */
    contactCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Contacts/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Contact
     * @name Contact.createChangeStream__post_Contacts_change-stream
     * @summary Create a change stream.
     * @request POST:/Contacts/change-stream
     */
    contactCreateChangeStreamPostContactsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Contacts/change-stream`, "POST", params, data),

    /**
     * @tags Contact
     * @name Contact.createChangeStream__get_Contacts_change-stream
     * @summary Create a change stream.
     * @request GET:/Contacts/change-stream
     */
    contactCreateChangeStreamGetContactsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Contacts/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  assurances = {
    /**
     * @tags Assurance
     * @name Assurance.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/Assurances/{id}/account
     */
    assurancePrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Assurances/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Assurance
     * @name Assurance.prototype.__findById__cMRs
     * @summary Find a related item by id for cMRs.
     * @request GET:/Assurances/{id}/cMRs/{fk}
     */
    assurancePrototypeFindByIdCMRs: (id: string, fk: string, params?: RequestParams) =>
      this.request<CMR, any>(`/Assurances/${id}/cMRs/${fk}`, "GET", params),

    /**
     * @tags Assurance
     * @name Assurance.prototype.__destroyById__cMRs
     * @summary Delete a related item by id for cMRs.
     * @request DELETE:/Assurances/{id}/cMRs/{fk}
     */
    assurancePrototypeDestroyByIdCMRs: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Assurances/${id}/cMRs/${fk}`, "DELETE", params),

    /**
     * @tags Assurance
     * @name Assurance.prototype.__updateById__cMRs
     * @summary Update a related item by id for cMRs.
     * @request PUT:/Assurances/{id}/cMRs/{fk}
     */
    assurancePrototypeUpdateByIdCMRs: (id: string, fk: string, data: CMR, params?: RequestParams) =>
      this.request<CMR, any>(`/Assurances/${id}/cMRs/${fk}`, "PUT", params, data),

    /**
     * @tags Assurance
     * @name Assurance.prototype.__findById__assuranceMatLocs
     * @summary Find a related item by id for assuranceMatLocs.
     * @request GET:/Assurances/{id}/assuranceMatLocs/{fk}
     */
    assurancePrototypeFindByIdAssuranceMatLocs: (id: string, fk: string, params?: RequestParams) =>
      this.request<AssuranceMatLoc, any>(`/Assurances/${id}/assuranceMatLocs/${fk}`, "GET", params),

    /**
     * @tags Assurance
     * @name Assurance.prototype.__destroyById__assuranceMatLocs
     * @summary Delete a related item by id for assuranceMatLocs.
     * @request DELETE:/Assurances/{id}/assuranceMatLocs/{fk}
     */
    assurancePrototypeDestroyByIdAssuranceMatLocs: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Assurances/${id}/assuranceMatLocs/${fk}`, "DELETE", params),

    /**
     * @tags Assurance
     * @name Assurance.prototype.__updateById__assuranceMatLocs
     * @summary Update a related item by id for assuranceMatLocs.
     * @request PUT:/Assurances/{id}/assuranceMatLocs/{fk}
     */
    assurancePrototypeUpdateByIdAssuranceMatLocs: (
      id: string,
      fk: string,
      data: AssuranceMatLoc,
      params?: RequestParams,
    ) => this.request<AssuranceMatLoc, any>(`/Assurances/${id}/assuranceMatLocs/${fk}`, "PUT", params, data),

    /**
     * @tags Assurance
     * @name Assurance.prototype.__get__cMRs
     * @summary Queries cMRs of Assurance.
     * @request GET:/Assurances/{id}/cMRs
     */
    assurancePrototypeGetCMRs: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<CMR[], any>(`/Assurances/${id}/cMRs${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Assurance
     * @name Assurance.prototype.__create__cMRs
     * @summary Creates a new instance in cMRs of this model.
     * @request POST:/Assurances/{id}/cMRs
     */
    assurancePrototypeCreateCMRs: (id: string, data: CMR, params?: RequestParams) =>
      this.request<CMR, any>(`/Assurances/${id}/cMRs`, "POST", params, data),

    /**
     * @tags Assurance
     * @name Assurance.prototype.__delete__cMRs
     * @summary Deletes all cMRs of this model.
     * @request DELETE:/Assurances/{id}/cMRs
     */
    assurancePrototypeDeleteCMRs: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Assurances/${id}/cMRs`, "DELETE", params),

    /**
     * @tags Assurance
     * @name Assurance.prototype.__count__cMRs
     * @summary Counts cMRs of Assurance.
     * @request GET:/Assurances/{id}/cMRs/count
     */
    assurancePrototypeCountCMRs: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Assurances/${id}/cMRs/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Assurance
     * @name Assurance.prototype.__get__assuranceMatLocs
     * @summary Queries assuranceMatLocs of Assurance.
     * @request GET:/Assurances/{id}/assuranceMatLocs
     */
    assurancePrototypeGetAssuranceMatLocs: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<AssuranceMatLoc[], any>(
        `/Assurances/${id}/assuranceMatLocs${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Assurance
     * @name Assurance.prototype.__create__assuranceMatLocs
     * @summary Creates a new instance in assuranceMatLocs of this model.
     * @request POST:/Assurances/{id}/assuranceMatLocs
     */
    assurancePrototypeCreateAssuranceMatLocs: (id: string, data: AssuranceMatLoc, params?: RequestParams) =>
      this.request<AssuranceMatLoc, any>(`/Assurances/${id}/assuranceMatLocs`, "POST", params, data),

    /**
     * @tags Assurance
     * @name Assurance.prototype.__delete__assuranceMatLocs
     * @summary Deletes all assuranceMatLocs of this model.
     * @request DELETE:/Assurances/{id}/assuranceMatLocs
     */
    assurancePrototypeDeleteAssuranceMatLocs: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Assurances/${id}/assuranceMatLocs`, "DELETE", params),

    /**
     * @tags Assurance
     * @name Assurance.prototype.__count__assuranceMatLocs
     * @summary Counts assuranceMatLocs of Assurance.
     * @request GET:/Assurances/{id}/assuranceMatLocs/count
     */
    assurancePrototypeCountAssuranceMatLocs: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Assurances/${id}/assuranceMatLocs/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Assurance
     * @name Assurance.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Assurances
     */
    assuranceCreate: (data: Assurance, params?: RequestParams) =>
      this.request<Assurance, any>(`/Assurances`, "POST", params, data),

    /**
     * @tags Assurance
     * @name Assurance.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Assurances
     */
    assurancePatchOrCreate: (data: Assurance, params?: RequestParams) =>
      this.request<Assurance, any>(`/Assurances`, "PATCH", params, data),

    /**
     * @tags Assurance
     * @name Assurance.replaceOrCreate__put_Assurances
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Assurances
     */
    assuranceReplaceOrCreatePutAssurances: (data: Assurance, params?: RequestParams) =>
      this.request<Assurance, any>(`/Assurances`, "PUT", params, data),

    /**
     * @tags Assurance
     * @name Assurance.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Assurances
     */
    assuranceFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Assurance[], any>(`/Assurances${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Assurance
     * @name Assurance.replaceOrCreate__post_Assurances_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Assurances/replaceOrCreate
     */
    assuranceReplaceOrCreatePostAssurancesReplaceOrCreate: (data: Assurance, params?: RequestParams) =>
      this.request<Assurance, any>(`/Assurances/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Assurance
     * @name Assurance.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Assurances/upsertWithWhere
     */
    assuranceUpsertWithWhere: (data: Assurance, query?: { where?: string }, params?: RequestParams) =>
      this.request<Assurance, any>(`/Assurances/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Assurance
     * @name Assurance.exists__get_Assurances_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Assurances/{id}/exists
     */
    assuranceExistsGetAssurancesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Assurances/${id}/exists`, "GET", params),

    /**
     * @tags Assurance
     * @name Assurance.exists__head_Assurances_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Assurances/{id}
     */
    assuranceExistsHeadAssurancesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Assurances/${id}`, "HEAD", params),

    /**
     * @tags Assurance
     * @name Assurance.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Assurances/{id}
     */
    assuranceFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Assurance, any>(`/Assurances/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Assurance
     * @name Assurance.replaceById__put_Assurances_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Assurances/{id}
     */
    assuranceReplaceByIdPutAssurancesId: (id: string, data: Assurance, params?: RequestParams) =>
      this.request<Assurance, any>(`/Assurances/${id}`, "PUT", params, data),

    /**
     * @tags Assurance
     * @name Assurance.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Assurances/{id}
     */
    assuranceDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Assurances/${id}`, "DELETE", params),

    /**
     * @tags Assurance
     * @name Assurance.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Assurances/{id}
     */
    assurancePrototypePatchAttributes: (id: string, data: Assurance, params?: RequestParams) =>
      this.request<Assurance, any>(`/Assurances/${id}`, "PATCH", params, data),

    /**
     * @tags Assurance
     * @name Assurance.replaceById__post_Assurances_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Assurances/{id}/replace
     */
    assuranceReplaceByIdPostAssurancesIdReplace: (id: string, data: Assurance, params?: RequestParams) =>
      this.request<Assurance, any>(`/Assurances/${id}/replace`, "POST", params, data),

    /**
     * @tags Assurance
     * @name Assurance.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Assurances/findOne
     */
    assuranceFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Assurance, any>(`/Assurances/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Assurance
     * @name Assurance.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Assurances/update
     */
    assuranceUpdateAll: (data: Assurance, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Assurances/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Assurance
     * @name Assurance.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Assurances/count
     */
    assuranceCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Assurances/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Assurance
     * @name Assurance.createChangeStream__post_Assurances_change-stream
     * @summary Create a change stream.
     * @request POST:/Assurances/change-stream
     */
    assuranceCreateChangeStreamPostAssurancesChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Assurances/change-stream`, "POST", params, data),

    /**
     * @tags Assurance
     * @name Assurance.createChangeStream__get_Assurances_change-stream
     * @summary Create a change stream.
     * @request GET:/Assurances/change-stream
     */
    assuranceCreateChangeStreamGetAssurancesChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Assurances/change-stream${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Assurance
     * @name Assurance.genPdf
     * @summary gen pdf from data
     * @request POST:/Assurances/genPdf
     */
    assuranceGenPdf: (query: { id: string; shouldResize?: boolean }, params?: RequestParams) =>
      this.request<string, any>(`/Assurances/genPdf${this.addQueryParams(query)}`, "POST", params),
  };
  photos = {
    /**
     * @tags Photo
     * @name Photo.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/Photos/{id}/account
     */
    photoPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Photos/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Photo
     * @name Photo.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Photos
     */
    photoCreate: (data: Photo, params?: RequestParams) => this.request<Photo, any>(`/Photos`, "POST", params, data),

    /**
     * @tags Photo
     * @name Photo.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Photos
     */
    photoPatchOrCreate: (data: Photo, params?: RequestParams) =>
      this.request<Photo, any>(`/Photos`, "PATCH", params, data),

    /**
     * @tags Photo
     * @name Photo.replaceOrCreate__put_Photos
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Photos
     */
    photoReplaceOrCreatePutPhotos: (data: Photo, params?: RequestParams) =>
      this.request<Photo, any>(`/Photos`, "PUT", params, data),

    /**
     * @tags Photo
     * @name Photo.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Photos
     */
    photoFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Photo[], any>(`/Photos${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Photo
     * @name Photo.replaceOrCreate__post_Photos_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Photos/replaceOrCreate
     */
    photoReplaceOrCreatePostPhotosReplaceOrCreate: (data: Photo, params?: RequestParams) =>
      this.request<Photo, any>(`/Photos/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Photo
     * @name Photo.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Photos/upsertWithWhere
     */
    photoUpsertWithWhere: (data: Photo, query?: { where?: string }, params?: RequestParams) =>
      this.request<Photo, any>(`/Photos/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Photo
     * @name Photo.exists__get_Photos_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Photos/{id}/exists
     */
    photoExistsGetPhotosIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Photos/${id}/exists`, "GET", params),

    /**
     * @tags Photo
     * @name Photo.exists__head_Photos_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Photos/{id}
     */
    photoExistsHeadPhotosId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Photos/${id}`, "HEAD", params),

    /**
     * @tags Photo
     * @name Photo.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Photos/{id}
     */
    photoFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Photo, any>(`/Photos/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Photo
     * @name Photo.replaceById__put_Photos_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Photos/{id}
     */
    photoReplaceByIdPutPhotosId: (id: string, data: Photo, params?: RequestParams) =>
      this.request<Photo, any>(`/Photos/${id}`, "PUT", params, data),

    /**
     * @tags Photo
     * @name Photo.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Photos/{id}
     */
    photoDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Photos/${id}`, "DELETE", params),

    /**
     * @tags Photo
     * @name Photo.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Photos/{id}
     */
    photoPrototypePatchAttributes: (id: string, data: Photo, params?: RequestParams) =>
      this.request<Photo, any>(`/Photos/${id}`, "PATCH", params, data),

    /**
     * @tags Photo
     * @name Photo.replaceById__post_Photos_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Photos/{id}/replace
     */
    photoReplaceByIdPostPhotosIdReplace: (id: string, data: Photo, params?: RequestParams) =>
      this.request<Photo, any>(`/Photos/${id}/replace`, "POST", params, data),

    /**
     * @tags Photo
     * @name Photo.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Photos/findOne
     */
    photoFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Photo, any>(`/Photos/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Photo
     * @name Photo.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Photos/update
     */
    photoUpdateAll: (data: Photo, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Photos/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Photo
     * @name Photo.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Photos/count
     */
    photoCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Photos/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Photo
     * @name Photo.createChangeStream__post_Photos_change-stream
     * @summary Create a change stream.
     * @request POST:/Photos/change-stream
     */
    photoCreateChangeStreamPostPhotosChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Photos/change-stream`, "POST", params, data),

    /**
     * @tags Photo
     * @name Photo.createChangeStream__get_Photos_change-stream
     * @summary Create a change stream.
     * @request GET:/Photos/change-stream
     */
    photoCreateChangeStreamGetPhotosChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Photos/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  media = {
    /**
     * @tags Media
     * @name Media.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Media
     */
    mediaCreate: (data: Media, params?: RequestParams) => this.request<Media, any>(`/Media`, "POST", params, data),

    /**
     * @tags Media
     * @name Media.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Media
     */
    mediaPatchOrCreate: (data: Media, params?: RequestParams) =>
      this.request<Media, any>(`/Media`, "PATCH", params, data),

    /**
     * @tags Media
     * @name Media.replaceOrCreate__put_Media
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Media
     */
    mediaReplaceOrCreatePutMedia: (data: Media, params?: RequestParams) =>
      this.request<Media, any>(`/Media`, "PUT", params, data),

    /**
     * @tags Media
     * @name Media.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Media
     */
    mediaFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Media[], any>(`/Media${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Media
     * @name Media.replaceOrCreate__post_Media_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Media/replaceOrCreate
     */
    mediaReplaceOrCreatePostMediaReplaceOrCreate: (data: Media, params?: RequestParams) =>
      this.request<Media, any>(`/Media/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Media
     * @name Media.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Media/upsertWithWhere
     */
    mediaUpsertWithWhere: (data: Media, query?: { where?: string }, params?: RequestParams) =>
      this.request<Media, any>(`/Media/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Media
     * @name Media.exists__get_Media_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Media/{id}/exists
     */
    mediaExistsGetMediaIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Media/${id}/exists`, "GET", params),

    /**
     * @tags Media
     * @name Media.exists__head_Media_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Media/{id}
     */
    mediaExistsHeadMediaId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Media/${id}`, "HEAD", params),

    /**
     * @tags Media
     * @name Media.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Media/{id}
     */
    mediaFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Media, any>(`/Media/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Media
     * @name Media.replaceById__put_Media_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Media/{id}
     */
    mediaReplaceByIdPutMediaId: (id: string, data: Media, params?: RequestParams) =>
      this.request<Media, any>(`/Media/${id}`, "PUT", params, data),

    /**
     * @tags Media
     * @name Media.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Media/{id}
     */
    mediaDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Media/${id}`, "DELETE", params),

    /**
     * @tags Media
     * @name Media.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Media/{id}
     */
    mediaPrototypePatchAttributes: (id: string, data: Media, params?: RequestParams) =>
      this.request<Media, any>(`/Media/${id}`, "PATCH", params, data),

    /**
     * @tags Media
     * @name Media.replaceById__post_Media_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Media/{id}/replace
     */
    mediaReplaceByIdPostMediaIdReplace: (id: string, data: Media, params?: RequestParams) =>
      this.request<Media, any>(`/Media/${id}/replace`, "POST", params, data),

    /**
     * @tags Media
     * @name Media.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Media/findOne
     */
    mediaFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Media, any>(`/Media/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Media
     * @name Media.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Media/update
     */
    mediaUpdateAll: (data: Media, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Media/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Media
     * @name Media.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Media/count
     */
    mediaCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Media/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Media
     * @name Media.createChangeStream__post_Media_change-stream
     * @summary Create a change stream.
     * @request POST:/Media/change-stream
     */
    mediaCreateChangeStreamPostMediaChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Media/change-stream`, "POST", params, data),

    /**
     * @tags Media
     * @name Media.createChangeStream__get_Media_change-stream
     * @summary Create a change stream.
     * @request GET:/Media/change-stream
     */
    mediaCreateChangeStreamGetMediaChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Media/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  reserves = {
    /**
     * @tags Reserve
     * @name Reserve.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/Reserves/{id}/account
     */
    reservePrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Reserves/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Reserve
     * @name Reserve.prototype.__get__etape
     * @summary Fetches belongsTo relation etape.
     * @request GET:/Reserves/{id}/etape
     */
    reservePrototypeGetEtape: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Etape, any>(`/Reserves/${id}/etape${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Reserve
     * @name Reserve.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Reserves
     */
    reserveCreate: (data: Reserve, params?: RequestParams) =>
      this.request<Reserve, any>(`/Reserves`, "POST", params, data),

    /**
     * @tags Reserve
     * @name Reserve.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Reserves
     */
    reservePatchOrCreate: (data: Reserve, params?: RequestParams) =>
      this.request<Reserve, any>(`/Reserves`, "PATCH", params, data),

    /**
     * @tags Reserve
     * @name Reserve.replaceOrCreate__put_Reserves
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Reserves
     */
    reserveReplaceOrCreatePutReserves: (data: Reserve, params?: RequestParams) =>
      this.request<Reserve, any>(`/Reserves`, "PUT", params, data),

    /**
     * @tags Reserve
     * @name Reserve.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Reserves
     */
    reserveFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Reserve[], any>(`/Reserves${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Reserve
     * @name Reserve.replaceOrCreate__post_Reserves_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Reserves/replaceOrCreate
     */
    reserveReplaceOrCreatePostReservesReplaceOrCreate: (data: Reserve, params?: RequestParams) =>
      this.request<Reserve, any>(`/Reserves/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Reserve
     * @name Reserve.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Reserves/upsertWithWhere
     */
    reserveUpsertWithWhere: (data: Reserve, query?: { where?: string }, params?: RequestParams) =>
      this.request<Reserve, any>(`/Reserves/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Reserve
     * @name Reserve.exists__get_Reserves_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Reserves/{id}/exists
     */
    reserveExistsGetReservesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Reserves/${id}/exists`, "GET", params),

    /**
     * @tags Reserve
     * @name Reserve.exists__head_Reserves_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Reserves/{id}
     */
    reserveExistsHeadReservesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Reserves/${id}`, "HEAD", params),

    /**
     * @tags Reserve
     * @name Reserve.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Reserves/{id}
     */
    reserveFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Reserve, any>(`/Reserves/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Reserve
     * @name Reserve.replaceById__put_Reserves_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Reserves/{id}
     */
    reserveReplaceByIdPutReservesId: (id: string, data: Reserve, params?: RequestParams) =>
      this.request<Reserve, any>(`/Reserves/${id}`, "PUT", params, data),

    /**
     * @tags Reserve
     * @name Reserve.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Reserves/{id}
     */
    reserveDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Reserves/${id}`, "DELETE", params),

    /**
     * @tags Reserve
     * @name Reserve.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Reserves/{id}
     */
    reservePrototypePatchAttributes: (id: string, data: Reserve, params?: RequestParams) =>
      this.request<Reserve, any>(`/Reserves/${id}`, "PATCH", params, data),

    /**
     * @tags Reserve
     * @name Reserve.replaceById__post_Reserves_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Reserves/{id}/replace
     */
    reserveReplaceByIdPostReservesIdReplace: (id: string, data: Reserve, params?: RequestParams) =>
      this.request<Reserve, any>(`/Reserves/${id}/replace`, "POST", params, data),

    /**
     * @tags Reserve
     * @name Reserve.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Reserves/findOne
     */
    reserveFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Reserve, any>(`/Reserves/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Reserve
     * @name Reserve.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Reserves/update
     */
    reserveUpdateAll: (data: Reserve, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Reserves/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Reserve
     * @name Reserve.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Reserves/count
     */
    reserveCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Reserves/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Reserve
     * @name Reserve.createChangeStream__post_Reserves_change-stream
     * @summary Create a change stream.
     * @request POST:/Reserves/change-stream
     */
    reserveCreateChangeStreamPostReservesChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Reserves/change-stream`, "POST", params, data),

    /**
     * @tags Reserve
     * @name Reserve.createChangeStream__get_Reserves_change-stream
     * @summary Create a change stream.
     * @request GET:/Reserves/change-stream
     */
    reserveCreateChangeStreamGetReservesChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Reserves/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  etatDeLieuxes = {
    /**
     * @tags EtatDeLieux
     * @name EtatDeLieux.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/EtatDeLieuxes/{id}/account
     */
    etatDeLieuxPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/EtatDeLieuxes/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags EtatDeLieux
     * @name EtatDeLieux.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/EtatDeLieuxes
     */
    etatDeLieuxCreate: (data: EtatDeLieux, params?: RequestParams) =>
      this.request<EtatDeLieux, any>(`/EtatDeLieuxes`, "POST", params, data),

    /**
     * @tags EtatDeLieux
     * @name EtatDeLieux.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/EtatDeLieuxes
     */
    etatDeLieuxPatchOrCreate: (data: EtatDeLieux, params?: RequestParams) =>
      this.request<EtatDeLieux, any>(`/EtatDeLieuxes`, "PATCH", params, data),

    /**
     * @tags EtatDeLieux
     * @name EtatDeLieux.replaceOrCreate__put_EtatDeLieuxes
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/EtatDeLieuxes
     */
    etatDeLieuxReplaceOrCreatePutEtatDeLieuxes: (data: EtatDeLieux, params?: RequestParams) =>
      this.request<EtatDeLieux, any>(`/EtatDeLieuxes`, "PUT", params, data),

    /**
     * @tags EtatDeLieux
     * @name EtatDeLieux.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/EtatDeLieuxes
     */
    etatDeLieuxFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<EtatDeLieux[], any>(`/EtatDeLieuxes${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags EtatDeLieux
     * @name EtatDeLieux.replaceOrCreate__post_EtatDeLieuxes_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/EtatDeLieuxes/replaceOrCreate
     */
    etatDeLieuxReplaceOrCreatePostEtatDeLieuxesReplaceOrCreate: (data: EtatDeLieux, params?: RequestParams) =>
      this.request<EtatDeLieux, any>(`/EtatDeLieuxes/replaceOrCreate`, "POST", params, data),

    /**
     * @tags EtatDeLieux
     * @name EtatDeLieux.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/EtatDeLieuxes/upsertWithWhere
     */
    etatDeLieuxUpsertWithWhere: (data: EtatDeLieux, query?: { where?: string }, params?: RequestParams) =>
      this.request<EtatDeLieux, any>(
        `/EtatDeLieuxes/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags EtatDeLieux
     * @name EtatDeLieux.exists__get_EtatDeLieuxes_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/EtatDeLieuxes/{id}/exists
     */
    etatDeLieuxExistsGetEtatDeLieuxesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/EtatDeLieuxes/${id}/exists`, "GET", params),

    /**
     * @tags EtatDeLieux
     * @name EtatDeLieux.exists__head_EtatDeLieuxes_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/EtatDeLieuxes/{id}
     */
    etatDeLieuxExistsHeadEtatDeLieuxesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/EtatDeLieuxes/${id}`, "HEAD", params),

    /**
     * @tags EtatDeLieux
     * @name EtatDeLieux.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/EtatDeLieuxes/{id}
     */
    etatDeLieuxFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<EtatDeLieux, any>(`/EtatDeLieuxes/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags EtatDeLieux
     * @name EtatDeLieux.replaceById__put_EtatDeLieuxes_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/EtatDeLieuxes/{id}
     */
    etatDeLieuxReplaceByIdPutEtatDeLieuxesId: (id: string, data: EtatDeLieux, params?: RequestParams) =>
      this.request<EtatDeLieux, any>(`/EtatDeLieuxes/${id}`, "PUT", params, data),

    /**
     * @tags EtatDeLieux
     * @name EtatDeLieux.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/EtatDeLieuxes/{id}
     */
    etatDeLieuxDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/EtatDeLieuxes/${id}`, "DELETE", params),

    /**
     * @tags EtatDeLieux
     * @name EtatDeLieux.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/EtatDeLieuxes/{id}
     */
    etatDeLieuxPrototypePatchAttributes: (id: string, data: EtatDeLieux, params?: RequestParams) =>
      this.request<EtatDeLieux, any>(`/EtatDeLieuxes/${id}`, "PATCH", params, data),

    /**
     * @tags EtatDeLieux
     * @name EtatDeLieux.replaceById__post_EtatDeLieuxes_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/EtatDeLieuxes/{id}/replace
     */
    etatDeLieuxReplaceByIdPostEtatDeLieuxesIdReplace: (id: string, data: EtatDeLieux, params?: RequestParams) =>
      this.request<EtatDeLieux, any>(`/EtatDeLieuxes/${id}/replace`, "POST", params, data),

    /**
     * @tags EtatDeLieux
     * @name EtatDeLieux.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/EtatDeLieuxes/findOne
     */
    etatDeLieuxFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<EtatDeLieux, any>(`/EtatDeLieuxes/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags EtatDeLieux
     * @name EtatDeLieux.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/EtatDeLieuxes/update
     */
    etatDeLieuxUpdateAll: (data: EtatDeLieux, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/EtatDeLieuxes/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags EtatDeLieux
     * @name EtatDeLieux.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/EtatDeLieuxes/count
     */
    etatDeLieuxCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/EtatDeLieuxes/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags EtatDeLieux
     * @name EtatDeLieux.createChangeStream__post_EtatDeLieuxes_change-stream
     * @summary Create a change stream.
     * @request POST:/EtatDeLieuxes/change-stream
     */
    etatDeLieuxCreateChangeStreamPostEtatDeLieuxesChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/EtatDeLieuxes/change-stream`, "POST", params, data),

    /**
     * @tags EtatDeLieux
     * @name EtatDeLieux.createChangeStream__get_EtatDeLieuxes_change-stream
     * @summary Create a change stream.
     * @request GET:/EtatDeLieuxes/change-stream
     */
    etatDeLieuxCreateChangeStreamGetEtatDeLieuxesChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/EtatDeLieuxes/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  missions = {
    /**
     * @tags Mission
     * @name Mission.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/Missions/{id}/account
     */
    missionPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Missions/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Mission
     * @name Mission.prototype.__findById__reserves
     * @summary Find a related item by id for reserves.
     * @request GET:/Missions/{id}/reserves/{fk}
     */
    missionPrototypeFindByIdReserves: (id: string, fk: string, params?: RequestParams) =>
      this.request<Reserve, any>(`/Missions/${id}/reserves/${fk}`, "GET", params),

    /**
     * @tags Mission
     * @name Mission.prototype.__destroyById__reserves
     * @summary Delete a related item by id for reserves.
     * @request DELETE:/Missions/{id}/reserves/{fk}
     */
    missionPrototypeDestroyByIdReserves: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Missions/${id}/reserves/${fk}`, "DELETE", params),

    /**
     * @tags Mission
     * @name Mission.prototype.__updateById__reserves
     * @summary Update a related item by id for reserves.
     * @request PUT:/Missions/{id}/reserves/{fk}
     */
    missionPrototypeUpdateByIdReserves: (id: string, fk: string, data: Reserve, params?: RequestParams) =>
      this.request<Reserve, any>(`/Missions/${id}/reserves/${fk}`, "PUT", params, data),

    /**
     * @tags Mission
     * @name Mission.prototype.__findById__etapes
     * @summary Find a related item by id for etapes.
     * @request GET:/Missions/{id}/etapes/{fk}
     */
    missionPrototypeFindByIdEtapes: (id: string, fk: string, params?: RequestParams) =>
      this.request<Etape, any>(`/Missions/${id}/etapes/${fk}`, "GET", params),

    /**
     * @tags Mission
     * @name Mission.prototype.__destroyById__etapes
     * @summary Delete a related item by id for etapes.
     * @request DELETE:/Missions/{id}/etapes/{fk}
     */
    missionPrototypeDestroyByIdEtapes: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Missions/${id}/etapes/${fk}`, "DELETE", params),

    /**
     * @tags Mission
     * @name Mission.prototype.__updateById__etapes
     * @summary Update a related item by id for etapes.
     * @request PUT:/Missions/{id}/etapes/{fk}
     */
    missionPrototypeUpdateByIdEtapes: (id: string, fk: string, data: Etape, params?: RequestParams) =>
      this.request<Etape, any>(`/Missions/${id}/etapes/${fk}`, "PUT", params, data),

    /**
     * @tags Mission
     * @name Mission.prototype.__findById__cMRs
     * @summary Find a related item by id for cMRs.
     * @request GET:/Missions/{id}/cMRs/{fk}
     */
    missionPrototypeFindByIdCMRs: (id: string, fk: string, params?: RequestParams) =>
      this.request<CMR, any>(`/Missions/${id}/cMRs/${fk}`, "GET", params),

    /**
     * @tags Mission
     * @name Mission.prototype.__destroyById__cMRs
     * @summary Delete a related item by id for cMRs.
     * @request DELETE:/Missions/{id}/cMRs/{fk}
     */
    missionPrototypeDestroyByIdCMRs: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Missions/${id}/cMRs/${fk}`, "DELETE", params),

    /**
     * @tags Mission
     * @name Mission.prototype.__updateById__cMRs
     * @summary Update a related item by id for cMRs.
     * @request PUT:/Missions/{id}/cMRs/{fk}
     */
    missionPrototypeUpdateByIdCMRs: (id: string, fk: string, data: CMR, params?: RequestParams) =>
      this.request<CMR, any>(`/Missions/${id}/cMRs/${fk}`, "PUT", params, data),

    /**
     * @tags Mission
     * @name Mission.prototype.__get__tempServices
     * @summary Fetches hasOne relation tempServices.
     * @request GET:/Missions/{id}/tempServices
     */
    missionPrototypeGetTempServices: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<TempService, any>(`/Missions/${id}/tempServices${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Mission
     * @name Mission.prototype.__create__tempServices
     * @summary Creates a new instance in tempServices of this model.
     * @request POST:/Missions/{id}/tempServices
     */
    missionPrototypeCreateTempServices: (id: string, data: TempService, params?: RequestParams) =>
      this.request<TempService, any>(`/Missions/${id}/tempServices`, "POST", params, data),

    /**
     * @tags Mission
     * @name Mission.prototype.__update__tempServices
     * @summary Update tempServices of this model.
     * @request PUT:/Missions/{id}/tempServices
     */
    missionPrototypeUpdateTempServices: (id: string, data: TempService, params?: RequestParams) =>
      this.request<TempService, any>(`/Missions/${id}/tempServices`, "PUT", params, data),

    /**
     * @tags Mission
     * @name Mission.prototype.__destroy__tempServices
     * @summary Deletes tempServices of this model.
     * @request DELETE:/Missions/{id}/tempServices
     */
    missionPrototypeDestroyTempServices: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Missions/${id}/tempServices`, "DELETE", params),

    /**
     * @tags Mission
     * @name Mission.prototype.__get__reserves
     * @summary Queries reserves of Mission.
     * @request GET:/Missions/{id}/reserves
     */
    missionPrototypeGetReserves: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Reserve[], any>(`/Missions/${id}/reserves${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Mission
     * @name Mission.prototype.__create__reserves
     * @summary Creates a new instance in reserves of this model.
     * @request POST:/Missions/{id}/reserves
     */
    missionPrototypeCreateReserves: (id: string, data: Reserve, params?: RequestParams) =>
      this.request<Reserve, any>(`/Missions/${id}/reserves`, "POST", params, data),

    /**
     * @tags Mission
     * @name Mission.prototype.__delete__reserves
     * @summary Deletes all reserves of this model.
     * @request DELETE:/Missions/{id}/reserves
     */
    missionPrototypeDeleteReserves: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Missions/${id}/reserves`, "DELETE", params),

    /**
     * @tags Mission
     * @name Mission.prototype.__count__reserves
     * @summary Counts reserves of Mission.
     * @request GET:/Missions/{id}/reserves/count
     */
    missionPrototypeCountReserves: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Missions/${id}/reserves/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Mission
     * @name Mission.prototype.__get__etapes
     * @summary Queries etapes of Mission.
     * @request GET:/Missions/{id}/etapes
     */
    missionPrototypeGetEtapes: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Etape[], any>(`/Missions/${id}/etapes${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Mission
     * @name Mission.prototype.__create__etapes
     * @summary Creates a new instance in etapes of this model.
     * @request POST:/Missions/{id}/etapes
     */
    missionPrototypeCreateEtapes: (id: string, data: Etape, params?: RequestParams) =>
      this.request<Etape, any>(`/Missions/${id}/etapes`, "POST", params, data),

    /**
     * @tags Mission
     * @name Mission.prototype.__delete__etapes
     * @summary Deletes all etapes of this model.
     * @request DELETE:/Missions/{id}/etapes
     */
    missionPrototypeDeleteEtapes: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Missions/${id}/etapes`, "DELETE", params),

    /**
     * @tags Mission
     * @name Mission.prototype.__count__etapes
     * @summary Counts etapes of Mission.
     * @request GET:/Missions/{id}/etapes/count
     */
    missionPrototypeCountEtapes: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Missions/${id}/etapes/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Mission
     * @name Mission.prototype.__get__cMRs
     * @summary Queries cMRs of Mission.
     * @request GET:/Missions/{id}/cMRs
     */
    missionPrototypeGetCMRs: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<CMR[], any>(`/Missions/${id}/cMRs${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Mission
     * @name Mission.prototype.__create__cMRs
     * @summary Creates a new instance in cMRs of this model.
     * @request POST:/Missions/{id}/cMRs
     */
    missionPrototypeCreateCMRs: (id: string, data: CMR, params?: RequestParams) =>
      this.request<CMR, any>(`/Missions/${id}/cMRs`, "POST", params, data),

    /**
     * @tags Mission
     * @name Mission.prototype.__delete__cMRs
     * @summary Deletes all cMRs of this model.
     * @request DELETE:/Missions/{id}/cMRs
     */
    missionPrototypeDeleteCMRs: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Missions/${id}/cMRs`, "DELETE", params),

    /**
     * @tags Mission
     * @name Mission.prototype.__count__cMRs
     * @summary Counts cMRs of Mission.
     * @request GET:/Missions/{id}/cMRs/count
     */
    missionPrototypeCountCMRs: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Missions/${id}/cMRs/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Mission
     * @name Mission.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Missions
     */
    missionCreate: (data: Mission, params?: RequestParams) =>
      this.request<Mission, any>(`/Missions`, "POST", params, data),

    /**
     * @tags Mission
     * @name Mission.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Missions
     */
    missionPatchOrCreate: (data: Mission, params?: RequestParams) =>
      this.request<Mission, any>(`/Missions`, "PATCH", params, data),

    /**
     * @tags Mission
     * @name Mission.replaceOrCreate__put_Missions
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Missions
     */
    missionReplaceOrCreatePutMissions: (data: Mission, params?: RequestParams) =>
      this.request<Mission, any>(`/Missions`, "PUT", params, data),

    /**
     * @tags Mission
     * @name Mission.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Missions
     */
    missionFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Mission[], any>(`/Missions${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Mission
     * @name Mission.replaceOrCreate__post_Missions_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Missions/replaceOrCreate
     */
    missionReplaceOrCreatePostMissionsReplaceOrCreate: (data: Mission, params?: RequestParams) =>
      this.request<Mission, any>(`/Missions/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Mission
     * @name Mission.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Missions/upsertWithWhere
     */
    missionUpsertWithWhere: (data: Mission, query?: { where?: string }, params?: RequestParams) =>
      this.request<Mission, any>(`/Missions/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Mission
     * @name Mission.exists__get_Missions_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Missions/{id}/exists
     */
    missionExistsGetMissionsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Missions/${id}/exists`, "GET", params),

    /**
     * @tags Mission
     * @name Mission.exists__head_Missions_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Missions/{id}
     */
    missionExistsHeadMissionsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Missions/${id}`, "HEAD", params),

    /**
     * @tags Mission
     * @name Mission.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Missions/{id}
     */
    missionFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Mission, any>(`/Missions/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Mission
     * @name Mission.replaceById__put_Missions_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Missions/{id}
     */
    missionReplaceByIdPutMissionsId: (id: string, data: Mission, params?: RequestParams) =>
      this.request<Mission, any>(`/Missions/${id}`, "PUT", params, data),

    /**
     * @tags Mission
     * @name Mission.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Missions/{id}
     */
    missionDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Missions/${id}`, "DELETE", params),

    /**
     * @tags Mission
     * @name Mission.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Missions/{id}
     */
    missionPrototypePatchAttributes: (id: string, data: Mission, params?: RequestParams) =>
      this.request<Mission, any>(`/Missions/${id}`, "PATCH", params, data),

    /**
     * @tags Mission
     * @name Mission.replaceById__post_Missions_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Missions/{id}/replace
     */
    missionReplaceByIdPostMissionsIdReplace: (id: string, data: Mission, params?: RequestParams) =>
      this.request<Mission, any>(`/Missions/${id}/replace`, "POST", params, data),

    /**
     * @tags Mission
     * @name Mission.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Missions/findOne
     */
    missionFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Mission, any>(`/Missions/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Mission
     * @name Mission.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Missions/update
     */
    missionUpdateAll: (data: Mission, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Missions/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Mission
     * @name Mission.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Missions/count
     */
    missionCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Missions/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Mission
     * @name Mission.createChangeStream__post_Missions_change-stream
     * @summary Create a change stream.
     * @request POST:/Missions/change-stream
     */
    missionCreateChangeStreamPostMissionsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Missions/change-stream`, "POST", params, data),

    /**
     * @tags Mission
     * @name Mission.createChangeStream__get_Missions_change-stream
     * @summary Create a change stream.
     * @request GET:/Missions/change-stream
     */
    missionCreateChangeStreamGetMissionsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Missions/change-stream${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Mission
     * @name Mission.activeMissions
     * @summary Get mission for user
     * @request GET:/Missions/activeMissions
     */
    missionActiveMissions: (params?: RequestParams) =>
      this.request<Mission[], any>(`/Missions/activeMissions`, "GET", params),

    /**
     * @tags Mission
     * @name Mission.currentEtape
     * @summary Get current Etape in Mission of user
     * @request GET:/Missions/currentEtape
     */
    missionCurrentEtape: (params?: RequestParams) => this.request<object, any>(`/Missions/currentEtape`, "GET", params),
  };
  etapes = {
    /**
     * @tags Etape
     * @name Etape.prototype.__get__mission
     * @summary Fetches belongsTo relation mission.
     * @request GET:/Etapes/{id}/mission
     */
    etapePrototypeGetMission: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Mission, any>(`/Etapes/${id}/mission${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Etape
     * @name Etape.prototype.__findById__reserves
     * @summary Find a related item by id for reserves.
     * @request GET:/Etapes/{id}/reserves/{fk}
     */
    etapePrototypeFindByIdReserves: (id: string, fk: string, params?: RequestParams) =>
      this.request<Reserve, any>(`/Etapes/${id}/reserves/${fk}`, "GET", params),

    /**
     * @tags Etape
     * @name Etape.prototype.__destroyById__reserves
     * @summary Delete a related item by id for reserves.
     * @request DELETE:/Etapes/{id}/reserves/{fk}
     */
    etapePrototypeDestroyByIdReserves: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Etapes/${id}/reserves/${fk}`, "DELETE", params),

    /**
     * @tags Etape
     * @name Etape.prototype.__updateById__reserves
     * @summary Update a related item by id for reserves.
     * @request PUT:/Etapes/{id}/reserves/{fk}
     */
    etapePrototypeUpdateByIdReserves: (id: string, fk: string, data: Reserve, params?: RequestParams) =>
      this.request<Reserve, any>(`/Etapes/${id}/reserves/${fk}`, "PUT", params, data),

    /**
     * @tags Etape
     * @name Etape.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/Etapes/{id}/account
     */
    etapePrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Etapes/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Etape
     * @name Etape.prototype.__findById__cMRs
     * @summary Find a related item by id for cMRs.
     * @request GET:/Etapes/{id}/cMRs/{fk}
     */
    etapePrototypeFindByIdCMRs: (id: string, fk: string, params?: RequestParams) =>
      this.request<CMR, any>(`/Etapes/${id}/cMRs/${fk}`, "GET", params),

    /**
     * @tags Etape
     * @name Etape.prototype.__destroyById__cMRs
     * @summary Delete a related item by id for cMRs.
     * @request DELETE:/Etapes/{id}/cMRs/{fk}
     */
    etapePrototypeDestroyByIdCMRs: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Etapes/${id}/cMRs/${fk}`, "DELETE", params),

    /**
     * @tags Etape
     * @name Etape.prototype.__updateById__cMRs
     * @summary Update a related item by id for cMRs.
     * @request PUT:/Etapes/{id}/cMRs/{fk}
     */
    etapePrototypeUpdateByIdCMRs: (id: string, fk: string, data: CMR, params?: RequestParams) =>
      this.request<CMR, any>(`/Etapes/${id}/cMRs/${fk}`, "PUT", params, data),

    /**
     * @tags Etape
     * @name Etape.prototype.__get__moteur
     * @summary Fetches belongsTo relation moteur.
     * @request GET:/Etapes/{id}/moteur
     */
    etapePrototypeGetMoteur: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Moteur, any>(`/Etapes/${id}/moteur${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Etape
     * @name Etape.prototype.__get__tracte
     * @summary Fetches belongsTo relation tracte.
     * @request GET:/Etapes/{id}/tracte
     */
    etapePrototypeGetTracte: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Tracte, any>(`/Etapes/${id}/tracte${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Etape
     * @name Etape.prototype.__get__reserves
     * @summary Queries reserves of Etape.
     * @request GET:/Etapes/{id}/reserves
     */
    etapePrototypeGetReserves: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Reserve[], any>(`/Etapes/${id}/reserves${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Etape
     * @name Etape.prototype.__create__reserves
     * @summary Creates a new instance in reserves of this model.
     * @request POST:/Etapes/{id}/reserves
     */
    etapePrototypeCreateReserves: (id: string, data: Reserve, params?: RequestParams) =>
      this.request<Reserve, any>(`/Etapes/${id}/reserves`, "POST", params, data),

    /**
     * @tags Etape
     * @name Etape.prototype.__delete__reserves
     * @summary Deletes all reserves of this model.
     * @request DELETE:/Etapes/{id}/reserves
     */
    etapePrototypeDeleteReserves: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Etapes/${id}/reserves`, "DELETE", params),

    /**
     * @tags Etape
     * @name Etape.prototype.__count__reserves
     * @summary Counts reserves of Etape.
     * @request GET:/Etapes/{id}/reserves/count
     */
    etapePrototypeCountReserves: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Etapes/${id}/reserves/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Etape
     * @name Etape.prototype.__get__cMRs
     * @summary Queries cMRs of Etape.
     * @request GET:/Etapes/{id}/cMRs
     */
    etapePrototypeGetCMRs: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<CMR[], any>(`/Etapes/${id}/cMRs${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Etape
     * @name Etape.prototype.__create__cMRs
     * @summary Creates a new instance in cMRs of this model.
     * @request POST:/Etapes/{id}/cMRs
     */
    etapePrototypeCreateCMRs: (id: string, data: CMR, params?: RequestParams) =>
      this.request<CMR, any>(`/Etapes/${id}/cMRs`, "POST", params, data),

    /**
     * @tags Etape
     * @name Etape.prototype.__delete__cMRs
     * @summary Deletes all cMRs of this model.
     * @request DELETE:/Etapes/{id}/cMRs
     */
    etapePrototypeDeleteCMRs: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Etapes/${id}/cMRs`, "DELETE", params),

    /**
     * @tags Etape
     * @name Etape.prototype.__count__cMRs
     * @summary Counts cMRs of Etape.
     * @request GET:/Etapes/{id}/cMRs/count
     */
    etapePrototypeCountCMRs: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Etapes/${id}/cMRs/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Etape
     * @name Etape.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Etapes
     */
    etapeCreate: (data: Etape, params?: RequestParams) => this.request<Etape, any>(`/Etapes`, "POST", params, data),

    /**
     * @tags Etape
     * @name Etape.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Etapes
     */
    etapePatchOrCreate: (data: Etape, params?: RequestParams) =>
      this.request<Etape, any>(`/Etapes`, "PATCH", params, data),

    /**
     * @tags Etape
     * @name Etape.replaceOrCreate__put_Etapes
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Etapes
     */
    etapeReplaceOrCreatePutEtapes: (data: Etape, params?: RequestParams) =>
      this.request<Etape, any>(`/Etapes`, "PUT", params, data),

    /**
     * @tags Etape
     * @name Etape.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Etapes
     */
    etapeFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Etape[], any>(`/Etapes${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Etape
     * @name Etape.replaceOrCreate__post_Etapes_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Etapes/replaceOrCreate
     */
    etapeReplaceOrCreatePostEtapesReplaceOrCreate: (data: Etape, params?: RequestParams) =>
      this.request<Etape, any>(`/Etapes/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Etape
     * @name Etape.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Etapes/upsertWithWhere
     */
    etapeUpsertWithWhere: (data: Etape, query?: { where?: string }, params?: RequestParams) =>
      this.request<Etape, any>(`/Etapes/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Etape
     * @name Etape.exists__get_Etapes_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Etapes/{id}/exists
     */
    etapeExistsGetEtapesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Etapes/${id}/exists`, "GET", params),

    /**
     * @tags Etape
     * @name Etape.exists__head_Etapes_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Etapes/{id}
     */
    etapeExistsHeadEtapesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Etapes/${id}`, "HEAD", params),

    /**
     * @tags Etape
     * @name Etape.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Etapes/{id}
     */
    etapeFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Etape, any>(`/Etapes/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Etape
     * @name Etape.replaceById__put_Etapes_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Etapes/{id}
     */
    etapeReplaceByIdPutEtapesId: (id: string, data: Etape, params?: RequestParams) =>
      this.request<Etape, any>(`/Etapes/${id}`, "PUT", params, data),

    /**
     * @tags Etape
     * @name Etape.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Etapes/{id}
     */
    etapeDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Etapes/${id}`, "DELETE", params),

    /**
     * @tags Etape
     * @name Etape.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Etapes/{id}
     */
    etapePrototypePatchAttributes: (id: string, data: Etape, params?: RequestParams) =>
      this.request<Etape, any>(`/Etapes/${id}`, "PATCH", params, data),

    /**
     * @tags Etape
     * @name Etape.replaceById__post_Etapes_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Etapes/{id}/replace
     */
    etapeReplaceByIdPostEtapesIdReplace: (id: string, data: Etape, params?: RequestParams) =>
      this.request<Etape, any>(`/Etapes/${id}/replace`, "POST", params, data),

    /**
     * @tags Etape
     * @name Etape.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Etapes/findOne
     */
    etapeFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Etape, any>(`/Etapes/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Etape
     * @name Etape.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Etapes/update
     */
    etapeUpdateAll: (data: Etape, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Etapes/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Etape
     * @name Etape.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Etapes/count
     */
    etapeCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Etapes/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Etape
     * @name Etape.createChangeStream__post_Etapes_change-stream
     * @summary Create a change stream.
     * @request POST:/Etapes/change-stream
     */
    etapeCreateChangeStreamPostEtapesChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Etapes/change-stream`, "POST", params, data),

    /**
     * @tags Etape
     * @name Etape.createChangeStream__get_Etapes_change-stream
     * @summary Create a change stream.
     * @request GET:/Etapes/change-stream
     */
    etapeCreateChangeStreamGetEtapesChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Etapes/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  inAppNotifications = {
    /**
     * @tags InAppNotification
     * @name InAppNotification.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/InAppNotifications/{id}/account
     */
    inAppNotificationPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/InAppNotifications/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags InAppNotification
     * @name InAppNotification.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/InAppNotifications
     */
    inAppNotificationCreate: (data: InAppNotification, params?: RequestParams) =>
      this.request<InAppNotification, any>(`/InAppNotifications`, "POST", params, data),

    /**
     * @tags InAppNotification
     * @name InAppNotification.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/InAppNotifications
     */
    inAppNotificationPatchOrCreate: (data: InAppNotification, params?: RequestParams) =>
      this.request<InAppNotification, any>(`/InAppNotifications`, "PATCH", params, data),

    /**
     * @tags InAppNotification
     * @name InAppNotification.replaceOrCreate__put_InAppNotifications
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/InAppNotifications
     */
    inAppNotificationReplaceOrCreatePutInAppNotifications: (data: InAppNotification, params?: RequestParams) =>
      this.request<InAppNotification, any>(`/InAppNotifications`, "PUT", params, data),

    /**
     * @tags InAppNotification
     * @name InAppNotification.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/InAppNotifications
     */
    inAppNotificationFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<InAppNotification[], any>(`/InAppNotifications${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags InAppNotification
     * @name InAppNotification.replaceOrCreate__post_InAppNotifications_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/InAppNotifications/replaceOrCreate
     */
    inAppNotificationReplaceOrCreatePostInAppNotificationsReplaceOrCreate: (
      data: InAppNotification,
      params?: RequestParams,
    ) => this.request<InAppNotification, any>(`/InAppNotifications/replaceOrCreate`, "POST", params, data),

    /**
     * @tags InAppNotification
     * @name InAppNotification.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/InAppNotifications/upsertWithWhere
     */
    inAppNotificationUpsertWithWhere: (data: InAppNotification, query?: { where?: string }, params?: RequestParams) =>
      this.request<InAppNotification, any>(
        `/InAppNotifications/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags InAppNotification
     * @name InAppNotification.exists__get_InAppNotifications_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/InAppNotifications/{id}/exists
     */
    inAppNotificationExistsGetInAppNotificationsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/InAppNotifications/${id}/exists`, "GET", params),

    /**
     * @tags InAppNotification
     * @name InAppNotification.exists__head_InAppNotifications_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/InAppNotifications/{id}
     */
    inAppNotificationExistsHeadInAppNotificationsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/InAppNotifications/${id}`, "HEAD", params),

    /**
     * @tags InAppNotification
     * @name InAppNotification.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/InAppNotifications/{id}
     */
    inAppNotificationFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<InAppNotification, any>(`/InAppNotifications/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags InAppNotification
     * @name InAppNotification.replaceById__put_InAppNotifications_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/InAppNotifications/{id}
     */
    inAppNotificationReplaceByIdPutInAppNotificationsId: (
      id: string,
      data: InAppNotification,
      params?: RequestParams,
    ) => this.request<InAppNotification, any>(`/InAppNotifications/${id}`, "PUT", params, data),

    /**
     * @tags InAppNotification
     * @name InAppNotification.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/InAppNotifications/{id}
     */
    inAppNotificationDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/InAppNotifications/${id}`, "DELETE", params),

    /**
     * @tags InAppNotification
     * @name InAppNotification.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/InAppNotifications/{id}
     */
    inAppNotificationPrototypePatchAttributes: (id: string, data: InAppNotification, params?: RequestParams) =>
      this.request<InAppNotification, any>(`/InAppNotifications/${id}`, "PATCH", params, data),

    /**
     * @tags InAppNotification
     * @name InAppNotification.replaceById__post_InAppNotifications_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/InAppNotifications/{id}/replace
     */
    inAppNotificationReplaceByIdPostInAppNotificationsIdReplace: (
      id: string,
      data: InAppNotification,
      params?: RequestParams,
    ) => this.request<InAppNotification, any>(`/InAppNotifications/${id}/replace`, "POST", params, data),

    /**
     * @tags InAppNotification
     * @name InAppNotification.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/InAppNotifications/findOne
     */
    inAppNotificationFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<InAppNotification, any>(`/InAppNotifications/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags InAppNotification
     * @name InAppNotification.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/InAppNotifications/update
     */
    inAppNotificationUpdateAll: (data: InAppNotification, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/InAppNotifications/update${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags InAppNotification
     * @name InAppNotification.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/InAppNotifications/count
     */
    inAppNotificationCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/InAppNotifications/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags InAppNotification
     * @name InAppNotification.createChangeStream__post_InAppNotifications_change-stream
     * @summary Create a change stream.
     * @request POST:/InAppNotifications/change-stream
     */
    inAppNotificationCreateChangeStreamPostInAppNotificationsChangeStream: (
      data: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/InAppNotifications/change-stream`, "POST", params, data),

    /**
     * @tags InAppNotification
     * @name InAppNotification.createChangeStream__get_InAppNotifications_change-stream
     * @summary Create a change stream.
     * @request GET:/InAppNotifications/change-stream
     */
    inAppNotificationCreateChangeStreamGetInAppNotificationsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/InAppNotifications/change-stream${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags InAppNotification
     * @name InAppNotification.readAll
     * @summary Set read status of all input notificationIds
     * @request POST:/InAppNotifications/readAll
     */
    inAppNotificationReadAll: (data: { listOfIds: string }, params?: RequestParams) =>
      this.request<XAny[], any>(`/InAppNotifications/readAll`, "POST", params, data),

    /**
     * @tags InAppNotification
     * @name InAppNotification.messageTypes
     * @summary Get all message types
     * @request GET:/InAppNotifications/messageTypes
     */
    inAppNotificationMessageTypes: (params?: RequestParams) =>
      this.request<XAny[], any>(`/InAppNotifications/messageTypes`, "GET", params),
  };
  applications = {
    /**
     * @tags Application
     * @name Application.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Applications
     */
    applicationCreate: (data: Application, params?: RequestParams) =>
      this.request<Application, any>(`/Applications`, "POST", params, data),

    /**
     * @tags Application
     * @name Application.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Applications
     */
    applicationPatchOrCreate: (data: Application, params?: RequestParams) =>
      this.request<Application, any>(`/Applications`, "PATCH", params, data),

    /**
     * @tags Application
     * @name Application.replaceOrCreate__put_Applications
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Applications
     */
    applicationReplaceOrCreatePutApplications: (data: Application, params?: RequestParams) =>
      this.request<Application, any>(`/Applications`, "PUT", params, data),

    /**
     * @tags Application
     * @name Application.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Applications
     */
    applicationFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Application[], any>(`/Applications${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Application
     * @name Application.replaceOrCreate__post_Applications_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Applications/replaceOrCreate
     */
    applicationReplaceOrCreatePostApplicationsReplaceOrCreate: (data: Application, params?: RequestParams) =>
      this.request<Application, any>(`/Applications/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Application
     * @name Application.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Applications/upsertWithWhere
     */
    applicationUpsertWithWhere: (data: Application, query?: { where?: string }, params?: RequestParams) =>
      this.request<Application, any>(
        `/Applications/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags Application
     * @name Application.exists__get_Applications_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Applications/{id}/exists
     */
    applicationExistsGetApplicationsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Applications/${id}/exists`, "GET", params),

    /**
     * @tags Application
     * @name Application.exists__head_Applications_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Applications/{id}
     */
    applicationExistsHeadApplicationsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Applications/${id}`, "HEAD", params),

    /**
     * @tags Application
     * @name Application.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Applications/{id}
     */
    applicationFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Application, any>(`/Applications/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Application
     * @name Application.replaceById__put_Applications_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Applications/{id}
     */
    applicationReplaceByIdPutApplicationsId: (id: string, data: Application, params?: RequestParams) =>
      this.request<Application, any>(`/Applications/${id}`, "PUT", params, data),

    /**
     * @tags Application
     * @name Application.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Applications/{id}
     */
    applicationDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Applications/${id}`, "DELETE", params),

    /**
     * @tags Application
     * @name Application.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Applications/{id}
     */
    applicationPrototypePatchAttributes: (id: string, data: Application, params?: RequestParams) =>
      this.request<Application, any>(`/Applications/${id}`, "PATCH", params, data),

    /**
     * @tags Application
     * @name Application.replaceById__post_Applications_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Applications/{id}/replace
     */
    applicationReplaceByIdPostApplicationsIdReplace: (id: string, data: Application, params?: RequestParams) =>
      this.request<Application, any>(`/Applications/${id}/replace`, "POST", params, data),

    /**
     * @tags Application
     * @name Application.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Applications/findOne
     */
    applicationFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Application, any>(`/Applications/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Application
     * @name Application.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Applications/update
     */
    applicationUpdateAll: (data: Application, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Applications/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Application
     * @name Application.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Applications/count
     */
    applicationCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Applications/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Application
     * @name Application.createChangeStream__post_Applications_change-stream
     * @summary Create a change stream.
     * @request POST:/Applications/change-stream
     */
    applicationCreateChangeStreamPostApplicationsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Applications/change-stream`, "POST", params, data),

    /**
     * @tags Application
     * @name Application.createChangeStream__get_Applications_change-stream
     * @summary Create a change stream.
     * @request GET:/Applications/change-stream
     */
    applicationCreateChangeStreamGetApplicationsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Applications/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  installations = {
    /**
     * @tags Installation
     * @name Installation.findByApp
     * @summary Find installations by application id
     * @request GET:/Installations/byApp
     */
    installationFindByApp: (
      query?: { deviceType?: string; appId?: string; appVersion?: string },
      params?: RequestParams,
    ) => this.request<object, any>(`/Installations/byApp${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Installation
     * @name Installation.findByUser
     * @summary Find installations by user id
     * @request GET:/Installations/byUser
     */
    installationFindByUser: (query?: { deviceType?: string; userId?: string }, params?: RequestParams) =>
      this.request<object, any>(`/Installations/byUser${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Installation
     * @name Installation.findBySubscriptions
     * @summary Find installations by subscriptions
     * @request GET:/Installations/bySubscriptions
     */
    installationFindBySubscriptions: (
      query?: { deviceType?: string; subscriptions?: string },
      params?: RequestParams,
    ) => this.request<object, any>(`/Installations/bySubscriptions${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Installation
     * @name Installation.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Installations
     */
    installationCreate: (data: Installation, params?: RequestParams) =>
      this.request<Installation, any>(`/Installations`, "POST", params, data),

    /**
     * @tags Installation
     * @name Installation.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Installations
     */
    installationPatchOrCreate: (data: Installation, params?: RequestParams) =>
      this.request<Installation, any>(`/Installations`, "PATCH", params, data),

    /**
     * @tags Installation
     * @name Installation.replaceOrCreate__put_Installations
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Installations
     */
    installationReplaceOrCreatePutInstallations: (data: Installation, params?: RequestParams) =>
      this.request<Installation, any>(`/Installations`, "PUT", params, data),

    /**
     * @tags Installation
     * @name Installation.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Installations
     */
    installationFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Installation[], any>(`/Installations${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Installation
     * @name Installation.replaceOrCreate__post_Installations_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Installations/replaceOrCreate
     */
    installationReplaceOrCreatePostInstallationsReplaceOrCreate: (data: Installation, params?: RequestParams) =>
      this.request<Installation, any>(`/Installations/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Installation
     * @name Installation.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Installations/upsertWithWhere
     */
    installationUpsertWithWhere: (data: Installation, query?: { where?: string }, params?: RequestParams) =>
      this.request<Installation, any>(
        `/Installations/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags Installation
     * @name Installation.exists__get_Installations_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Installations/{id}/exists
     */
    installationExistsGetInstallationsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Installations/${id}/exists`, "GET", params),

    /**
     * @tags Installation
     * @name Installation.exists__head_Installations_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Installations/{id}
     */
    installationExistsHeadInstallationsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Installations/${id}`, "HEAD", params),

    /**
     * @tags Installation
     * @name Installation.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Installations/{id}
     */
    installationFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Installation, any>(`/Installations/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Installation
     * @name Installation.replaceById__put_Installations_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Installations/{id}
     */
    installationReplaceByIdPutInstallationsId: (id: string, data: Installation, params?: RequestParams) =>
      this.request<Installation, any>(`/Installations/${id}`, "PUT", params, data),

    /**
     * @tags Installation
     * @name Installation.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Installations/{id}
     */
    installationDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Installations/${id}`, "DELETE", params),

    /**
     * @tags Installation
     * @name Installation.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Installations/{id}
     */
    installationPrototypePatchAttributes: (id: string, data: Installation, params?: RequestParams) =>
      this.request<Installation, any>(`/Installations/${id}`, "PATCH", params, data),

    /**
     * @tags Installation
     * @name Installation.replaceById__post_Installations_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Installations/{id}/replace
     */
    installationReplaceByIdPostInstallationsIdReplace: (id: string, data: Installation, params?: RequestParams) =>
      this.request<Installation, any>(`/Installations/${id}/replace`, "POST", params, data),

    /**
     * @tags Installation
     * @name Installation.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Installations/findOne
     */
    installationFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Installation, any>(`/Installations/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Installation
     * @name Installation.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Installations/update
     */
    installationUpdateAll: (data: Installation, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Installations/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Installation
     * @name Installation.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Installations/count
     */
    installationCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Installations/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Installation
     * @name Installation.createChangeStream__post_Installations_change-stream
     * @summary Create a change stream.
     * @request POST:/Installations/change-stream
     */
    installationCreateChangeStreamPostInstallationsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Installations/change-stream`, "POST", params, data),

    /**
     * @tags Installation
     * @name Installation.createChangeStream__get_Installations_change-stream
     * @summary Create a change stream.
     * @request GET:/Installations/change-stream
     */
    installationCreateChangeStreamGetInstallationsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/Installations/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  push = {
    /**
     * @tags Push
     * @name Push.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/push
     */
    pushCreate: (data: Push, params?: RequestParams) => this.request<Push, any>(`/push`, "POST", params, data),

    /**
     * @tags Push
     * @name Push.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/push
     */
    pushPatchOrCreate: (data: Push, params?: RequestParams) => this.request<Push, any>(`/push`, "PATCH", params, data),

    /**
     * @tags Push
     * @name Push.replaceOrCreate__put_push
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/push
     */
    pushReplaceOrCreatePutPush: (data: Push, params?: RequestParams) =>
      this.request<Push, any>(`/push`, "PUT", params, data),

    /**
     * @tags Push
     * @name Push.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/push
     */
    pushFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Push[], any>(`/push${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Push
     * @name Push.replaceOrCreate__post_push_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/push/replaceOrCreate
     */
    pushReplaceOrCreatePostPushReplaceOrCreate: (data: Push, params?: RequestParams) =>
      this.request<Push, any>(`/push/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Push
     * @name Push.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/push/upsertWithWhere
     */
    pushUpsertWithWhere: (data: Push, query?: { where?: string }, params?: RequestParams) =>
      this.request<Push, any>(`/push/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Push
     * @name Push.exists__get_push_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/push/{id}/exists
     */
    pushExistsGetPushIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/push/${id}/exists`, "GET", params),

    /**
     * @tags Push
     * @name Push.exists__head_push_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/push/{id}
     */
    pushExistsHeadPushId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/push/${id}`, "HEAD", params),

    /**
     * @tags Push
     * @name Push.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/push/{id}
     */
    pushFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Push, any>(`/push/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Push
     * @name Push.replaceById__put_push_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/push/{id}
     */
    pushReplaceByIdPutPushId: (id: string, data: Push, params?: RequestParams) =>
      this.request<Push, any>(`/push/${id}`, "PUT", params, data),

    /**
     * @tags Push
     * @name Push.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/push/{id}
     */
    pushDeleteById: (id: string, params?: RequestParams) => this.request<object, any>(`/push/${id}`, "DELETE", params),

    /**
     * @tags Push
     * @name Push.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/push/{id}
     */
    pushPrototypePatchAttributes: (id: string, data: Push, params?: RequestParams) =>
      this.request<Push, any>(`/push/${id}`, "PATCH", params, data),

    /**
     * @tags Push
     * @name Push.replaceById__post_push_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/push/{id}/replace
     */
    pushReplaceByIdPostPushIdReplace: (id: string, data: Push, params?: RequestParams) =>
      this.request<Push, any>(`/push/${id}/replace`, "POST", params, data),

    /**
     * @tags Push
     * @name Push.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/push/findOne
     */
    pushFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Push, any>(`/push/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Push
     * @name Push.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/push/update
     */
    pushUpdateAll: (data: Push, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/push/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Push
     * @name Push.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/push/count
     */
    pushCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/push/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Push
     * @name Push.createChangeStream__post_push_change-stream
     * @summary Create a change stream.
     * @request POST:/push/change-stream
     */
    pushCreateChangeStreamPostPushChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/push/change-stream`, "POST", params, data),

    /**
     * @tags Push
     * @name Push.createChangeStream__get_push_change-stream
     * @summary Create a change stream.
     * @request GET:/push/change-stream
     */
    pushCreateChangeStreamGetPushChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/push/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  notifications = {
    /**
     * @tags Notification
     * @name Notification.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Notifications
     */
    notificationCreate: (data: Notification, params?: RequestParams) =>
      this.request<Notification, any>(`/Notifications`, "POST", params, data),

    /**
     * @tags Notification
     * @name Notification.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Notifications
     */
    notificationPatchOrCreate: (data: Notification, params?: RequestParams) =>
      this.request<Notification, any>(`/Notifications`, "PATCH", params, data),

    /**
     * @tags Notification
     * @name Notification.replaceOrCreate__put_Notifications
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Notifications
     */
    notificationReplaceOrCreatePutNotifications: (data: Notification, params?: RequestParams) =>
      this.request<Notification, any>(`/Notifications`, "PUT", params, data),

    /**
     * @tags Notification
     * @name Notification.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Notifications
     */
    notificationFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Notification[], any>(`/Notifications${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Notification
     * @name Notification.replaceOrCreate__post_Notifications_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Notifications/replaceOrCreate
     */
    notificationReplaceOrCreatePostNotificationsReplaceOrCreate: (data: Notification, params?: RequestParams) =>
      this.request<Notification, any>(`/Notifications/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Notification
     * @name Notification.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Notifications/upsertWithWhere
     */
    notificationUpsertWithWhere: (data: Notification, query?: { where?: string }, params?: RequestParams) =>
      this.request<Notification, any>(
        `/Notifications/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags Notification
     * @name Notification.exists__get_Notifications_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Notifications/{id}/exists
     */
    notificationExistsGetNotificationsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Notifications/${id}/exists`, "GET", params),

    /**
     * @tags Notification
     * @name Notification.exists__head_Notifications_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Notifications/{id}
     */
    notificationExistsHeadNotificationsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Notifications/${id}`, "HEAD", params),

    /**
     * @tags Notification
     * @name Notification.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Notifications/{id}
     */
    notificationFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Notification, any>(`/Notifications/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Notification
     * @name Notification.replaceById__put_Notifications_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Notifications/{id}
     */
    notificationReplaceByIdPutNotificationsId: (id: string, data: Notification, params?: RequestParams) =>
      this.request<Notification, any>(`/Notifications/${id}`, "PUT", params, data),

    /**
     * @tags Notification
     * @name Notification.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Notifications/{id}
     */
    notificationDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Notifications/${id}`, "DELETE", params),

    /**
     * @tags Notification
     * @name Notification.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Notifications/{id}
     */
    notificationPrototypePatchAttributes: (id: string, data: Notification, params?: RequestParams) =>
      this.request<Notification, any>(`/Notifications/${id}`, "PATCH", params, data),

    /**
     * @tags Notification
     * @name Notification.replaceById__post_Notifications_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Notifications/{id}/replace
     */
    notificationReplaceByIdPostNotificationsIdReplace: (id: string, data: Notification, params?: RequestParams) =>
      this.request<Notification, any>(`/Notifications/${id}/replace`, "POST", params, data),

    /**
     * @tags Notification
     * @name Notification.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Notifications/findOne
     */
    notificationFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Notification, any>(`/Notifications/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Notification
     * @name Notification.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Notifications/update
     */
    notificationUpdateAll: (data: Notification, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Notifications/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Notification
     * @name Notification.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Notifications/count
     */
    notificationCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Notifications/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Notification
     * @name Notification.createChangeStream__post_Notifications_change-stream
     * @summary Create a change stream.
     * @request POST:/Notifications/change-stream
     */
    notificationCreateChangeStreamPostNotificationsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Notifications/change-stream`, "POST", params, data),

    /**
     * @tags Notification
     * @name Notification.createChangeStream__get_Notifications_change-stream
     * @summary Create a change stream.
     * @request GET:/Notifications/change-stream
     */
    notificationCreateChangeStreamGetNotificationsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/Notifications/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  cmRs = {
    /**
     * @tags CMR
     * @name CMR.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/CMRs/{id}/account
     */
    cmrPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/CMRs/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags CMR
     * @name CMR.prototype.__get__mission
     * @summary Fetches belongsTo relation mission.
     * @request GET:/CMRs/{id}/mission
     */
    cmrPrototypeGetMission: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Mission, any>(`/CMRs/${id}/mission${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags CMR
     * @name CMR.prototype.__get__etape
     * @summary Fetches belongsTo relation etape.
     * @request GET:/CMRs/{id}/etape
     */
    cmrPrototypeGetEtape: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Etape, any>(`/CMRs/${id}/etape${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags CMR
     * @name CMR.prototype.__get__assurance
     * @summary Fetches belongsTo relation assurance.
     * @request GET:/CMRs/{id}/assurance
     */
    cmrPrototypeGetAssurance: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Assurance, any>(`/CMRs/${id}/assurance${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags CMR
     * @name CMR.prototype.__get__pleinCarburants
     * @summary Fetches hasOne relation pleinCarburants.
     * @request GET:/CMRs/{id}/pleinCarburants
     */
    cmrPrototypeGetPleinCarburants: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<PleinCarburant, any>(`/CMRs/${id}/pleinCarburants${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags CMR
     * @name CMR.prototype.__create__pleinCarburants
     * @summary Creates a new instance in pleinCarburants of this model.
     * @request POST:/CMRs/{id}/pleinCarburants
     */
    cmrPrototypeCreatePleinCarburants: (id: string, data: PleinCarburant, params?: RequestParams) =>
      this.request<PleinCarburant, any>(`/CMRs/${id}/pleinCarburants`, "POST", params, data),

    /**
     * @tags CMR
     * @name CMR.prototype.__update__pleinCarburants
     * @summary Update pleinCarburants of this model.
     * @request PUT:/CMRs/{id}/pleinCarburants
     */
    cmrPrototypeUpdatePleinCarburants: (id: string, data: PleinCarburant, params?: RequestParams) =>
      this.request<PleinCarburant, any>(`/CMRs/${id}/pleinCarburants`, "PUT", params, data),

    /**
     * @tags CMR
     * @name CMR.prototype.__destroy__pleinCarburants
     * @summary Deletes pleinCarburants of this model.
     * @request DELETE:/CMRs/{id}/pleinCarburants
     */
    cmrPrototypeDestroyPleinCarburants: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/CMRs/${id}/pleinCarburants`, "DELETE", params),

    /**
     * @tags CMR
     * @name CMR.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/CMRs
     */
    cmrCreate: (data: CMR, params?: RequestParams) => this.request<CMR, any>(`/CMRs`, "POST", params, data),

    /**
     * @tags CMR
     * @name CMR.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/CMRs
     */
    cmrPatchOrCreate: (data: CMR, params?: RequestParams) => this.request<CMR, any>(`/CMRs`, "PATCH", params, data),

    /**
     * @tags CMR
     * @name CMR.replaceOrCreate__put_CMRs
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/CMRs
     */
    cmrReplaceOrCreatePutCmRs: (data: CMR, params?: RequestParams) =>
      this.request<CMR, any>(`/CMRs`, "PUT", params, data),

    /**
     * @tags CMR
     * @name CMR.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/CMRs
     */
    cmrFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<CMR[], any>(`/CMRs${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags CMR
     * @name CMR.replaceOrCreate__post_CMRs_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/CMRs/replaceOrCreate
     */
    cmrReplaceOrCreatePostCmRsReplaceOrCreate: (data: CMR, params?: RequestParams) =>
      this.request<CMR, any>(`/CMRs/replaceOrCreate`, "POST", params, data),

    /**
     * @tags CMR
     * @name CMR.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/CMRs/upsertWithWhere
     */
    cmrUpsertWithWhere: (data: CMR, query?: { where?: string }, params?: RequestParams) =>
      this.request<CMR, any>(`/CMRs/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags CMR
     * @name CMR.exists__get_CMRs_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/CMRs/{id}/exists
     */
    cmrExistsGetCmRsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/CMRs/${id}/exists`, "GET", params),

    /**
     * @tags CMR
     * @name CMR.exists__head_CMRs_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/CMRs/{id}
     */
    cmrExistsHeadCmRsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/CMRs/${id}`, "HEAD", params),

    /**
     * @tags CMR
     * @name CMR.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/CMRs/{id}
     */
    cmrFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<CMR, any>(`/CMRs/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags CMR
     * @name CMR.replaceById__put_CMRs_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/CMRs/{id}
     */
    cmrReplaceByIdPutCmRsId: (id: string, data: CMR, params?: RequestParams) =>
      this.request<CMR, any>(`/CMRs/${id}`, "PUT", params, data),

    /**
     * @tags CMR
     * @name CMR.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/CMRs/{id}
     */
    cmrDeleteById: (id: string, params?: RequestParams) => this.request<object, any>(`/CMRs/${id}`, "DELETE", params),

    /**
     * @tags CMR
     * @name CMR.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/CMRs/{id}
     */
    cmrPrototypePatchAttributes: (id: string, data: CMR, params?: RequestParams) =>
      this.request<CMR, any>(`/CMRs/${id}`, "PATCH", params, data),

    /**
     * @tags CMR
     * @name CMR.replaceById__post_CMRs_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/CMRs/{id}/replace
     */
    cmrReplaceByIdPostCmRsIdReplace: (id: string, data: CMR, params?: RequestParams) =>
      this.request<CMR, any>(`/CMRs/${id}/replace`, "POST", params, data),

    /**
     * @tags CMR
     * @name CMR.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/CMRs/findOne
     */
    cmrFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<CMR, any>(`/CMRs/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags CMR
     * @name CMR.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/CMRs/update
     */
    cmrUpdateAll: (data: CMR, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/CMRs/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags CMR
     * @name CMR.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/CMRs/count
     */
    cmrCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/CMRs/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags CMR
     * @name CMR.createChangeStream__post_CMRs_change-stream
     * @summary Create a change stream.
     * @request POST:/CMRs/change-stream
     */
    cmrCreateChangeStreamPostCmRsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/CMRs/change-stream`, "POST", params, data),

    /**
     * @tags CMR
     * @name CMR.createChangeStream__get_CMRs_change-stream
     * @summary Create a change stream.
     * @request GET:/CMRs/change-stream
     */
    cmrCreateChangeStreamGetCmRsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/CMRs/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  observationNotifications = {
    /**
     * @tags ObservationNotification
     * @name ObservationNotification.prototype.__get__inAppNotification
     * @summary Fetches belongsTo relation inAppNotification.
     * @request GET:/ObservationNotifications/{id}/inAppNotification
     */
    observationNotificationPrototypeGetInAppNotification: (
      id: string,
      query?: { refresh?: boolean },
      params?: RequestParams,
    ) =>
      this.request<InAppNotification, any>(
        `/ObservationNotifications/${id}/inAppNotification${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags ObservationNotification
     * @name ObservationNotification.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/ObservationNotifications/{id}/account
     */
    observationNotificationPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/ObservationNotifications/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags ObservationNotification
     * @name ObservationNotification.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/ObservationNotifications
     */
    observationNotificationCreate: (data: ObservationNotification, params?: RequestParams) =>
      this.request<ObservationNotification, any>(`/ObservationNotifications`, "POST", params, data),

    /**
     * @tags ObservationNotification
     * @name ObservationNotification.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/ObservationNotifications
     */
    observationNotificationPatchOrCreate: (data: ObservationNotification, params?: RequestParams) =>
      this.request<ObservationNotification, any>(`/ObservationNotifications`, "PATCH", params, data),

    /**
     * @tags ObservationNotification
     * @name ObservationNotification.replaceOrCreate__put_ObservationNotifications
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/ObservationNotifications
     */
    observationNotificationReplaceOrCreatePutObservationNotifications: (
      data: ObservationNotification,
      params?: RequestParams,
    ) => this.request<ObservationNotification, any>(`/ObservationNotifications`, "PUT", params, data),

    /**
     * @tags ObservationNotification
     * @name ObservationNotification.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/ObservationNotifications
     */
    observationNotificationFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<ObservationNotification[], any>(
        `/ObservationNotifications${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags ObservationNotification
     * @name ObservationNotification.replaceOrCreate__post_ObservationNotifications_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/ObservationNotifications/replaceOrCreate
     */
    observationNotificationReplaceOrCreatePostObservationNotificationsReplaceOrCreate: (
      data: ObservationNotification,
      params?: RequestParams,
    ) => this.request<ObservationNotification, any>(`/ObservationNotifications/replaceOrCreate`, "POST", params, data),

    /**
     * @tags ObservationNotification
     * @name ObservationNotification.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/ObservationNotifications/upsertWithWhere
     */
    observationNotificationUpsertWithWhere: (
      data: ObservationNotification,
      query?: { where?: string },
      params?: RequestParams,
    ) =>
      this.request<ObservationNotification, any>(
        `/ObservationNotifications/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags ObservationNotification
     * @name ObservationNotification.exists__get_ObservationNotifications_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/ObservationNotifications/{id}/exists
     */
    observationNotificationExistsGetObservationNotificationsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/ObservationNotifications/${id}/exists`, "GET", params),

    /**
     * @tags ObservationNotification
     * @name ObservationNotification.exists__head_ObservationNotifications_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/ObservationNotifications/{id}
     */
    observationNotificationExistsHeadObservationNotificationsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/ObservationNotifications/${id}`, "HEAD", params),

    /**
     * @tags ObservationNotification
     * @name ObservationNotification.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/ObservationNotifications/{id}
     */
    observationNotificationFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<ObservationNotification, any>(
        `/ObservationNotifications/${id}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags ObservationNotification
     * @name ObservationNotification.replaceById__put_ObservationNotifications_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/ObservationNotifications/{id}
     */
    observationNotificationReplaceByIdPutObservationNotificationsId: (
      id: string,
      data: ObservationNotification,
      params?: RequestParams,
    ) => this.request<ObservationNotification, any>(`/ObservationNotifications/${id}`, "PUT", params, data),

    /**
     * @tags ObservationNotification
     * @name ObservationNotification.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/ObservationNotifications/{id}
     */
    observationNotificationDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/ObservationNotifications/${id}`, "DELETE", params),

    /**
     * @tags ObservationNotification
     * @name ObservationNotification.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/ObservationNotifications/{id}
     */
    observationNotificationPrototypePatchAttributes: (
      id: string,
      data: ObservationNotification,
      params?: RequestParams,
    ) => this.request<ObservationNotification, any>(`/ObservationNotifications/${id}`, "PATCH", params, data),

    /**
     * @tags ObservationNotification
     * @name ObservationNotification.replaceById__post_ObservationNotifications_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/ObservationNotifications/{id}/replace
     */
    observationNotificationReplaceByIdPostObservationNotificationsIdReplace: (
      id: string,
      data: ObservationNotification,
      params?: RequestParams,
    ) => this.request<ObservationNotification, any>(`/ObservationNotifications/${id}/replace`, "POST", params, data),

    /**
     * @tags ObservationNotification
     * @name ObservationNotification.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/ObservationNotifications/findOne
     */
    observationNotificationFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<ObservationNotification, any>(
        `/ObservationNotifications/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags ObservationNotification
     * @name ObservationNotification.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/ObservationNotifications/update
     */
    observationNotificationUpdateAll: (
      data: ObservationNotification,
      query?: { where?: string },
      params?: RequestParams,
    ) =>
      this.request<{ count?: number }, any>(
        `/ObservationNotifications/update${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags ObservationNotification
     * @name ObservationNotification.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/ObservationNotifications/count
     */
    observationNotificationCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/ObservationNotifications/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags ObservationNotification
     * @name ObservationNotification.createChangeStream__post_ObservationNotifications_change-stream
     * @summary Create a change stream.
     * @request POST:/ObservationNotifications/change-stream
     */
    observationNotificationCreateChangeStreamPostObservationNotificationsChangeStream: (
      data: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/ObservationNotifications/change-stream`, "POST", params, data),

    /**
     * @tags ObservationNotification
     * @name ObservationNotification.createChangeStream__get_ObservationNotifications_change-stream
     * @summary Create a change stream.
     * @request GET:/ObservationNotifications/change-stream
     */
    observationNotificationCreateChangeStreamGetObservationNotificationsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/ObservationNotifications/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  tempServices = {
    /**
     * @tags TempService
     * @name TempService.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/TempServices/{id}/account
     */
    tempServicePrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/TempServices/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags TempService
     * @name TempService.prototype.__get__mission
     * @summary Fetches belongsTo relation mission.
     * @request GET:/TempServices/{id}/mission
     */
    tempServicePrototypeGetMission: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Mission, any>(`/TempServices/${id}/mission${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags TempService
     * @name TempService.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/TempServices
     */
    tempServiceCreate: (data: TempService, params?: RequestParams) =>
      this.request<TempService, any>(`/TempServices`, "POST", params, data),

    /**
     * @tags TempService
     * @name TempService.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/TempServices
     */
    tempServicePatchOrCreate: (data: TempService, params?: RequestParams) =>
      this.request<TempService, any>(`/TempServices`, "PATCH", params, data),

    /**
     * @tags TempService
     * @name TempService.replaceOrCreate__put_TempServices
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/TempServices
     */
    tempServiceReplaceOrCreatePutTempServices: (data: TempService, params?: RequestParams) =>
      this.request<TempService, any>(`/TempServices`, "PUT", params, data),

    /**
     * @tags TempService
     * @name TempService.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/TempServices
     */
    tempServiceFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<TempService[], any>(`/TempServices${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags TempService
     * @name TempService.replaceOrCreate__post_TempServices_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/TempServices/replaceOrCreate
     */
    tempServiceReplaceOrCreatePostTempServicesReplaceOrCreate: (data: TempService, params?: RequestParams) =>
      this.request<TempService, any>(`/TempServices/replaceOrCreate`, "POST", params, data),

    /**
     * @tags TempService
     * @name TempService.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/TempServices/upsertWithWhere
     */
    tempServiceUpsertWithWhere: (data: TempService, query?: { where?: string }, params?: RequestParams) =>
      this.request<TempService, any>(
        `/TempServices/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags TempService
     * @name TempService.exists__get_TempServices_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/TempServices/{id}/exists
     */
    tempServiceExistsGetTempServicesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/TempServices/${id}/exists`, "GET", params),

    /**
     * @tags TempService
     * @name TempService.exists__head_TempServices_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/TempServices/{id}
     */
    tempServiceExistsHeadTempServicesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/TempServices/${id}`, "HEAD", params),

    /**
     * @tags TempService
     * @name TempService.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/TempServices/{id}
     */
    tempServiceFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<TempService, any>(`/TempServices/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags TempService
     * @name TempService.replaceById__put_TempServices_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/TempServices/{id}
     */
    tempServiceReplaceByIdPutTempServicesId: (id: string, data: TempService, params?: RequestParams) =>
      this.request<TempService, any>(`/TempServices/${id}`, "PUT", params, data),

    /**
     * @tags TempService
     * @name TempService.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/TempServices/{id}
     */
    tempServiceDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/TempServices/${id}`, "DELETE", params),

    /**
     * @tags TempService
     * @name TempService.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/TempServices/{id}
     */
    tempServicePrototypePatchAttributes: (id: string, data: TempService, params?: RequestParams) =>
      this.request<TempService, any>(`/TempServices/${id}`, "PATCH", params, data),

    /**
     * @tags TempService
     * @name TempService.replaceById__post_TempServices_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/TempServices/{id}/replace
     */
    tempServiceReplaceByIdPostTempServicesIdReplace: (id: string, data: TempService, params?: RequestParams) =>
      this.request<TempService, any>(`/TempServices/${id}/replace`, "POST", params, data),

    /**
     * @tags TempService
     * @name TempService.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/TempServices/findOne
     */
    tempServiceFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<TempService, any>(`/TempServices/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags TempService
     * @name TempService.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/TempServices/update
     */
    tempServiceUpdateAll: (data: TempService, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/TempServices/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags TempService
     * @name TempService.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/TempServices/count
     */
    tempServiceCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/TempServices/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags TempService
     * @name TempService.createChangeStream__post_TempServices_change-stream
     * @summary Create a change stream.
     * @request POST:/TempServices/change-stream
     */
    tempServiceCreateChangeStreamPostTempServicesChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/TempServices/change-stream`, "POST", params, data),

    /**
     * @tags TempService
     * @name TempService.createChangeStream__get_TempServices_change-stream
     * @summary Create a change stream.
     * @request GET:/TempServices/change-stream
     */
    tempServiceCreateChangeStreamGetTempServicesChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/TempServices/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  poIs = {
    /**
     * @tags POI
     * @name POI.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/POIs
     */
    poiCreate: (data: POI, params?: RequestParams) => this.request<POI, any>(`/POIs`, "POST", params, data),

    /**
     * @tags POI
     * @name POI.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/POIs
     */
    poiPatchOrCreate: (data: POI, params?: RequestParams) => this.request<POI, any>(`/POIs`, "PATCH", params, data),

    /**
     * @tags POI
     * @name POI.replaceOrCreate__put_POIs
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/POIs
     */
    poiReplaceOrCreatePutPoIs: (data: POI, params?: RequestParams) =>
      this.request<POI, any>(`/POIs`, "PUT", params, data),

    /**
     * @tags POI
     * @name POI.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/POIs
     */
    poiFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<POI[], any>(`/POIs${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags POI
     * @name POI.replaceOrCreate__post_POIs_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/POIs/replaceOrCreate
     */
    poiReplaceOrCreatePostPoIsReplaceOrCreate: (data: POI, params?: RequestParams) =>
      this.request<POI, any>(`/POIs/replaceOrCreate`, "POST", params, data),

    /**
     * @tags POI
     * @name POI.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/POIs/upsertWithWhere
     */
    poiUpsertWithWhere: (data: POI, query?: { where?: string }, params?: RequestParams) =>
      this.request<POI, any>(`/POIs/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags POI
     * @name POI.exists__get_POIs_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/POIs/{id}/exists
     */
    poiExistsGetPoIsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/POIs/${id}/exists`, "GET", params),

    /**
     * @tags POI
     * @name POI.exists__head_POIs_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/POIs/{id}
     */
    poiExistsHeadPoIsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/POIs/${id}`, "HEAD", params),

    /**
     * @tags POI
     * @name POI.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/POIs/{id}
     */
    poiFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<POI, any>(`/POIs/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags POI
     * @name POI.replaceById__put_POIs_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/POIs/{id}
     */
    poiReplaceByIdPutPoIsId: (id: string, data: POI, params?: RequestParams) =>
      this.request<POI, any>(`/POIs/${id}`, "PUT", params, data),

    /**
     * @tags POI
     * @name POI.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/POIs/{id}
     */
    poiDeleteById: (id: string, params?: RequestParams) => this.request<object, any>(`/POIs/${id}`, "DELETE", params),

    /**
     * @tags POI
     * @name POI.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/POIs/{id}
     */
    poiPrototypePatchAttributes: (id: string, data: POI, params?: RequestParams) =>
      this.request<POI, any>(`/POIs/${id}`, "PATCH", params, data),

    /**
     * @tags POI
     * @name POI.replaceById__post_POIs_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/POIs/{id}/replace
     */
    poiReplaceByIdPostPoIsIdReplace: (id: string, data: POI, params?: RequestParams) =>
      this.request<POI, any>(`/POIs/${id}/replace`, "POST", params, data),

    /**
     * @tags POI
     * @name POI.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/POIs/findOne
     */
    poiFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<POI, any>(`/POIs/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags POI
     * @name POI.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/POIs/update
     */
    poiUpdateAll: (data: POI, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/POIs/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags POI
     * @name POI.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/POIs/count
     */
    poiCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/POIs/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags POI
     * @name POI.createChangeStream__post_POIs_change-stream
     * @summary Create a change stream.
     * @request POST:/POIs/change-stream
     */
    poiCreateChangeStreamPostPoIsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/POIs/change-stream`, "POST", params, data),

    /**
     * @tags POI
     * @name POI.createChangeStream__get_POIs_change-stream
     * @summary Create a change stream.
     * @request GET:/POIs/change-stream
     */
    poiCreateChangeStreamGetPoIsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/POIs/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  verifications = {
    /**
     * @tags Verifications
     * @name Verifications.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/Verifications/{id}/account
     */
    verificationsPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Verifications/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Verifications
     * @name Verifications.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Verifications
     */
    verificationsCreate: (data: Verifications, params?: RequestParams) =>
      this.request<Verifications, any>(`/Verifications`, "POST", params, data),

    /**
     * @tags Verifications
     * @name Verifications.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Verifications
     */
    verificationsPatchOrCreate: (data: Verifications, params?: RequestParams) =>
      this.request<Verifications, any>(`/Verifications`, "PATCH", params, data),

    /**
     * @tags Verifications
     * @name Verifications.replaceOrCreate__put_Verifications
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Verifications
     */
    verificationsReplaceOrCreatePutVerifications: (data: Verifications, params?: RequestParams) =>
      this.request<Verifications, any>(`/Verifications`, "PUT", params, data),

    /**
     * @tags Verifications
     * @name Verifications.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Verifications
     */
    verificationsFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Verifications[], any>(`/Verifications${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Verifications
     * @name Verifications.replaceOrCreate__post_Verifications_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Verifications/replaceOrCreate
     */
    verificationsReplaceOrCreatePostVerificationsReplaceOrCreate: (data: Verifications, params?: RequestParams) =>
      this.request<Verifications, any>(`/Verifications/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Verifications
     * @name Verifications.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Verifications/upsertWithWhere
     */
    verificationsUpsertWithWhere: (data: Verifications, query?: { where?: string }, params?: RequestParams) =>
      this.request<Verifications, any>(
        `/Verifications/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags Verifications
     * @name Verifications.exists__get_Verifications_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Verifications/{id}/exists
     */
    verificationsExistsGetVerificationsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Verifications/${id}/exists`, "GET", params),

    /**
     * @tags Verifications
     * @name Verifications.exists__head_Verifications_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Verifications/{id}
     */
    verificationsExistsHeadVerificationsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Verifications/${id}`, "HEAD", params),

    /**
     * @tags Verifications
     * @name Verifications.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Verifications/{id}
     */
    verificationsFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Verifications, any>(`/Verifications/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Verifications
     * @name Verifications.replaceById__put_Verifications_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Verifications/{id}
     */
    verificationsReplaceByIdPutVerificationsId: (id: string, data: Verifications, params?: RequestParams) =>
      this.request<Verifications, any>(`/Verifications/${id}`, "PUT", params, data),

    /**
     * @tags Verifications
     * @name Verifications.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Verifications/{id}
     */
    verificationsDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Verifications/${id}`, "DELETE", params),

    /**
     * @tags Verifications
     * @name Verifications.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Verifications/{id}
     */
    verificationsPrototypePatchAttributes: (id: string, data: Verifications, params?: RequestParams) =>
      this.request<Verifications, any>(`/Verifications/${id}`, "PATCH", params, data),

    /**
     * @tags Verifications
     * @name Verifications.replaceById__post_Verifications_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Verifications/{id}/replace
     */
    verificationsReplaceByIdPostVerificationsIdReplace: (id: string, data: Verifications, params?: RequestParams) =>
      this.request<Verifications, any>(`/Verifications/${id}/replace`, "POST", params, data),

    /**
     * @tags Verifications
     * @name Verifications.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Verifications/findOne
     */
    verificationsFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Verifications, any>(`/Verifications/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Verifications
     * @name Verifications.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Verifications/update
     */
    verificationsUpdateAll: (data: Verifications, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Verifications/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Verifications
     * @name Verifications.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Verifications/count
     */
    verificationsCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Verifications/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Verifications
     * @name Verifications.createChangeStream__post_Verifications_change-stream
     * @summary Create a change stream.
     * @request POST:/Verifications/change-stream
     */
    verificationsCreateChangeStreamPostVerificationsChangeStream: (
      data: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/Verifications/change-stream`, "POST", params, data),

    /**
     * @tags Verifications
     * @name Verifications.createChangeStream__get_Verifications_change-stream
     * @summary Create a change stream.
     * @request GET:/Verifications/change-stream
     */
    verificationsCreateChangeStreamGetVerificationsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/Verifications/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  ecoconduites = {
    /**
     * @tags Ecoconduite
     * @name Ecoconduite.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/Ecoconduites/{id}/account
     */
    ecoconduitePrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Ecoconduites/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Ecoconduites
     */
    ecoconduiteCreate: (data: Ecoconduite, params?: RequestParams) =>
      this.request<Ecoconduite, any>(`/Ecoconduites`, "POST", params, data),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Ecoconduites
     */
    ecoconduitePatchOrCreate: (data: Ecoconduite, params?: RequestParams) =>
      this.request<Ecoconduite, any>(`/Ecoconduites`, "PATCH", params, data),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.replaceOrCreate__put_Ecoconduites
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Ecoconduites
     */
    ecoconduiteReplaceOrCreatePutEcoconduites: (data: Ecoconduite, params?: RequestParams) =>
      this.request<Ecoconduite, any>(`/Ecoconduites`, "PUT", params, data),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Ecoconduites
     */
    ecoconduiteFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Ecoconduite[], any>(`/Ecoconduites${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.replaceOrCreate__post_Ecoconduites_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Ecoconduites/replaceOrCreate
     */
    ecoconduiteReplaceOrCreatePostEcoconduitesReplaceOrCreate: (data: Ecoconduite, params?: RequestParams) =>
      this.request<Ecoconduite, any>(`/Ecoconduites/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Ecoconduites/upsertWithWhere
     */
    ecoconduiteUpsertWithWhere: (data: Ecoconduite, query?: { where?: string }, params?: RequestParams) =>
      this.request<Ecoconduite, any>(
        `/Ecoconduites/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.exists__get_Ecoconduites_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Ecoconduites/{id}/exists
     */
    ecoconduiteExistsGetEcoconduitesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Ecoconduites/${id}/exists`, "GET", params),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.exists__head_Ecoconduites_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Ecoconduites/{id}
     */
    ecoconduiteExistsHeadEcoconduitesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Ecoconduites/${id}`, "HEAD", params),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Ecoconduites/{id}
     */
    ecoconduiteFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Ecoconduite, any>(`/Ecoconduites/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.replaceById__put_Ecoconduites_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Ecoconduites/{id}
     */
    ecoconduiteReplaceByIdPutEcoconduitesId: (id: string, data: Ecoconduite, params?: RequestParams) =>
      this.request<Ecoconduite, any>(`/Ecoconduites/${id}`, "PUT", params, data),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Ecoconduites/{id}
     */
    ecoconduiteDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Ecoconduites/${id}`, "DELETE", params),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Ecoconduites/{id}
     */
    ecoconduitePrototypePatchAttributes: (id: string, data: Ecoconduite, params?: RequestParams) =>
      this.request<Ecoconduite, any>(`/Ecoconduites/${id}`, "PATCH", params, data),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.replaceById__post_Ecoconduites_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Ecoconduites/{id}/replace
     */
    ecoconduiteReplaceByIdPostEcoconduitesIdReplace: (id: string, data: Ecoconduite, params?: RequestParams) =>
      this.request<Ecoconduite, any>(`/Ecoconduites/${id}/replace`, "POST", params, data),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Ecoconduites/findOne
     */
    ecoconduiteFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Ecoconduite, any>(`/Ecoconduites/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Ecoconduites/update
     */
    ecoconduiteUpdateAll: (data: Ecoconduite, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Ecoconduites/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Ecoconduites/count
     */
    ecoconduiteCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Ecoconduites/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.createChangeStream__post_Ecoconduites_change-stream
     * @summary Create a change stream.
     * @request POST:/Ecoconduites/change-stream
     */
    ecoconduiteCreateChangeStreamPostEcoconduitesChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Ecoconduites/change-stream`, "POST", params, data),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.createChangeStream__get_Ecoconduites_change-stream
     * @summary Create a change stream.
     * @request GET:/Ecoconduites/change-stream
     */
    ecoconduiteCreateChangeStreamGetEcoconduitesChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Ecoconduites/change-stream${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.getAverageTrip
     * @request GET:/Ecoconduites/getAverageTrip
     */
    ecoconduiteGetAverageTrip: (
      query: { teltoDriverId: string; startDate?: string; endDate?: string; activite?: string },
      params?: RequestParams,
    ) => this.request<Ecoconduite, any>(`/Ecoconduites/getAverageTrip${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.getPerformances
     * @request GET:/Ecoconduites/getPerformances
     */
    ecoconduiteGetPerformances: (
      query: { teltoDriverId: string; startDate?: string; endDate?: string; activite?: string },
      params?: RequestParams,
    ) => this.request<object, any>(`/Ecoconduites/getPerformances${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Ecoconduite
     * @name Ecoconduite.getPdf
     * @request GET:/Ecoconduites/getPdf
     */
    ecoconduiteGetPdf: (query: { teltoDriverId: string; coachingId?: number }, params?: RequestParams) =>
      this.request<object, any>(`/Ecoconduites/getPdf${this.addQueryParams(query)}`, "GET", params),
  };
  moteurs = {
    /**
     * @tags Moteur
     * @name Moteur.prototype.__findById__etapes
     * @summary Find a related item by id for etapes.
     * @request GET:/Moteurs/{id}/etapes/{fk}
     */
    moteurPrototypeFindByIdEtapes: (id: string, fk: string, params?: RequestParams) =>
      this.request<Etape, any>(`/Moteurs/${id}/etapes/${fk}`, "GET", params),

    /**
     * @tags Moteur
     * @name Moteur.prototype.__destroyById__etapes
     * @summary Delete a related item by id for etapes.
     * @request DELETE:/Moteurs/{id}/etapes/{fk}
     */
    moteurPrototypeDestroyByIdEtapes: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Moteurs/${id}/etapes/${fk}`, "DELETE", params),

    /**
     * @tags Moteur
     * @name Moteur.prototype.__updateById__etapes
     * @summary Update a related item by id for etapes.
     * @request PUT:/Moteurs/{id}/etapes/{fk}
     */
    moteurPrototypeUpdateByIdEtapes: (id: string, fk: string, data: Etape, params?: RequestParams) =>
      this.request<Etape, any>(`/Moteurs/${id}/etapes/${fk}`, "PUT", params, data),

    /**
     * @tags Moteur
     * @name Moteur.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/Moteurs/{id}/account
     */
    moteurPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Moteurs/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Moteur
     * @name Moteur.prototype.__get__techniques
     * @summary Fetches hasOne relation techniques.
     * @request GET:/Moteurs/{id}/techniques
     */
    moteurPrototypeGetTechniques: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Technique, any>(`/Moteurs/${id}/techniques${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Moteur
     * @name Moteur.prototype.__create__techniques
     * @summary Creates a new instance in techniques of this model.
     * @request POST:/Moteurs/{id}/techniques
     */
    moteurPrototypeCreateTechniques: (id: string, data: Technique, params?: RequestParams) =>
      this.request<Technique, any>(`/Moteurs/${id}/techniques`, "POST", params, data),

    /**
     * @tags Moteur
     * @name Moteur.prototype.__update__techniques
     * @summary Update techniques of this model.
     * @request PUT:/Moteurs/{id}/techniques
     */
    moteurPrototypeUpdateTechniques: (id: string, data: Technique, params?: RequestParams) =>
      this.request<Technique, any>(`/Moteurs/${id}/techniques`, "PUT", params, data),

    /**
     * @tags Moteur
     * @name Moteur.prototype.__destroy__techniques
     * @summary Deletes techniques of this model.
     * @request DELETE:/Moteurs/{id}/techniques
     */
    moteurPrototypeDestroyTechniques: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Moteurs/${id}/techniques`, "DELETE", params),

    /**
     * @tags Moteur
     * @name Moteur.prototype.__get__vehicleimage
     * @summary Fetches belongsTo relation vehicleimage.
     * @request GET:/Moteurs/{id}/vehicleimage
     */
    moteurPrototypeGetVehicleimage: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<VehicleImage, any>(`/Moteurs/${id}/vehicleimage${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Moteur
     * @name Moteur.prototype.__get__etapes
     * @summary Queries etapes of Moteur.
     * @request GET:/Moteurs/{id}/etapes
     */
    moteurPrototypeGetEtapes: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Etape[], any>(`/Moteurs/${id}/etapes${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Moteur
     * @name Moteur.prototype.__create__etapes
     * @summary Creates a new instance in etapes of this model.
     * @request POST:/Moteurs/{id}/etapes
     */
    moteurPrototypeCreateEtapes: (id: string, data: Etape, params?: RequestParams) =>
      this.request<Etape, any>(`/Moteurs/${id}/etapes`, "POST", params, data),

    /**
     * @tags Moteur
     * @name Moteur.prototype.__delete__etapes
     * @summary Deletes all etapes of this model.
     * @request DELETE:/Moteurs/{id}/etapes
     */
    moteurPrototypeDeleteEtapes: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Moteurs/${id}/etapes`, "DELETE", params),

    /**
     * @tags Moteur
     * @name Moteur.prototype.__count__etapes
     * @summary Counts etapes of Moteur.
     * @request GET:/Moteurs/{id}/etapes/count
     */
    moteurPrototypeCountEtapes: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Moteurs/${id}/etapes/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Moteur
     * @name Moteur.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Moteurs
     */
    moteurCreate: (data: Moteur, params?: RequestParams) => this.request<Moteur, any>(`/Moteurs`, "POST", params, data),

    /**
     * @tags Moteur
     * @name Moteur.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Moteurs
     */
    moteurPatchOrCreate: (data: Moteur, params?: RequestParams) =>
      this.request<Moteur, any>(`/Moteurs`, "PATCH", params, data),

    /**
     * @tags Moteur
     * @name Moteur.replaceOrCreate__put_Moteurs
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Moteurs
     */
    moteurReplaceOrCreatePutMoteurs: (data: Moteur, params?: RequestParams) =>
      this.request<Moteur, any>(`/Moteurs`, "PUT", params, data),

    /**
     * @tags Moteur
     * @name Moteur.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Moteurs
     */
    moteurFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Moteur[], any>(`/Moteurs${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Moteur
     * @name Moteur.replaceOrCreate__post_Moteurs_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Moteurs/replaceOrCreate
     */
    moteurReplaceOrCreatePostMoteursReplaceOrCreate: (data: Moteur, params?: RequestParams) =>
      this.request<Moteur, any>(`/Moteurs/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Moteur
     * @name Moteur.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Moteurs/upsertWithWhere
     */
    moteurUpsertWithWhere: (data: Moteur, query?: { where?: string }, params?: RequestParams) =>
      this.request<Moteur, any>(`/Moteurs/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Moteur
     * @name Moteur.exists__get_Moteurs_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Moteurs/{id}/exists
     */
    moteurExistsGetMoteursIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Moteurs/${id}/exists`, "GET", params),

    /**
     * @tags Moteur
     * @name Moteur.exists__head_Moteurs_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Moteurs/{id}
     */
    moteurExistsHeadMoteursId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Moteurs/${id}`, "HEAD", params),

    /**
     * @tags Moteur
     * @name Moteur.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Moteurs/{id}
     */
    moteurFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Moteur, any>(`/Moteurs/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Moteur
     * @name Moteur.replaceById__put_Moteurs_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Moteurs/{id}
     */
    moteurReplaceByIdPutMoteursId: (id: string, data: Moteur, params?: RequestParams) =>
      this.request<Moteur, any>(`/Moteurs/${id}`, "PUT", params, data),

    /**
     * @tags Moteur
     * @name Moteur.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Moteurs/{id}
     */
    moteurDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Moteurs/${id}`, "DELETE", params),

    /**
     * @tags Moteur
     * @name Moteur.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Moteurs/{id}
     */
    moteurPrototypePatchAttributes: (id: string, data: Moteur, params?: RequestParams) =>
      this.request<Moteur, any>(`/Moteurs/${id}`, "PATCH", params, data),

    /**
     * @tags Moteur
     * @name Moteur.replaceById__post_Moteurs_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Moteurs/{id}/replace
     */
    moteurReplaceByIdPostMoteursIdReplace: (id: string, data: Moteur, params?: RequestParams) =>
      this.request<Moteur, any>(`/Moteurs/${id}/replace`, "POST", params, data),

    /**
     * @tags Moteur
     * @name Moteur.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Moteurs/findOne
     */
    moteurFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Moteur, any>(`/Moteurs/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Moteur
     * @name Moteur.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Moteurs/update
     */
    moteurUpdateAll: (data: Moteur, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Moteurs/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Moteur
     * @name Moteur.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Moteurs/count
     */
    moteurCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Moteurs/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Moteur
     * @name Moteur.createChangeStream__post_Moteurs_change-stream
     * @summary Create a change stream.
     * @request POST:/Moteurs/change-stream
     */
    moteurCreateChangeStreamPostMoteursChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Moteurs/change-stream`, "POST", params, data),

    /**
     * @tags Moteur
     * @name Moteur.createChangeStream__get_Moteurs_change-stream
     * @summary Create a change stream.
     * @request GET:/Moteurs/change-stream
     */
    moteurCreateChangeStreamGetMoteursChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Moteurs/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  techniques = {
    /**
     * @tags Technique
     * @name Technique.prototype.__get__moteur
     * @summary Fetches belongsTo relation moteur.
     * @request GET:/Techniques/{id}/moteur
     */
    techniquePrototypeGetMoteur: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Moteur, any>(`/Techniques/${id}/moteur${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Technique
     * @name Technique.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Techniques
     */
    techniqueCreate: (data: Technique, params?: RequestParams) =>
      this.request<Technique, any>(`/Techniques`, "POST", params, data),

    /**
     * @tags Technique
     * @name Technique.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Techniques
     */
    techniquePatchOrCreate: (data: Technique, params?: RequestParams) =>
      this.request<Technique, any>(`/Techniques`, "PATCH", params, data),

    /**
     * @tags Technique
     * @name Technique.replaceOrCreate__put_Techniques
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Techniques
     */
    techniqueReplaceOrCreatePutTechniques: (data: Technique, params?: RequestParams) =>
      this.request<Technique, any>(`/Techniques`, "PUT", params, data),

    /**
     * @tags Technique
     * @name Technique.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Techniques
     */
    techniqueFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Technique[], any>(`/Techniques${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Technique
     * @name Technique.replaceOrCreate__post_Techniques_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Techniques/replaceOrCreate
     */
    techniqueReplaceOrCreatePostTechniquesReplaceOrCreate: (data: Technique, params?: RequestParams) =>
      this.request<Technique, any>(`/Techniques/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Technique
     * @name Technique.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Techniques/upsertWithWhere
     */
    techniqueUpsertWithWhere: (data: Technique, query?: { where?: string }, params?: RequestParams) =>
      this.request<Technique, any>(`/Techniques/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Technique
     * @name Technique.exists__get_Techniques_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Techniques/{id}/exists
     */
    techniqueExistsGetTechniquesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Techniques/${id}/exists`, "GET", params),

    /**
     * @tags Technique
     * @name Technique.exists__head_Techniques_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Techniques/{id}
     */
    techniqueExistsHeadTechniquesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Techniques/${id}`, "HEAD", params),

    /**
     * @tags Technique
     * @name Technique.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Techniques/{id}
     */
    techniqueFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Technique, any>(`/Techniques/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Technique
     * @name Technique.replaceById__put_Techniques_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Techniques/{id}
     */
    techniqueReplaceByIdPutTechniquesId: (id: string, data: Technique, params?: RequestParams) =>
      this.request<Technique, any>(`/Techniques/${id}`, "PUT", params, data),

    /**
     * @tags Technique
     * @name Technique.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Techniques/{id}
     */
    techniqueDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Techniques/${id}`, "DELETE", params),

    /**
     * @tags Technique
     * @name Technique.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Techniques/{id}
     */
    techniquePrototypePatchAttributes: (id: string, data: Technique, params?: RequestParams) =>
      this.request<Technique, any>(`/Techniques/${id}`, "PATCH", params, data),

    /**
     * @tags Technique
     * @name Technique.replaceById__post_Techniques_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Techniques/{id}/replace
     */
    techniqueReplaceByIdPostTechniquesIdReplace: (id: string, data: Technique, params?: RequestParams) =>
      this.request<Technique, any>(`/Techniques/${id}/replace`, "POST", params, data),

    /**
     * @tags Technique
     * @name Technique.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Techniques/findOne
     */
    techniqueFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Technique, any>(`/Techniques/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Technique
     * @name Technique.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Techniques/update
     */
    techniqueUpdateAll: (data: Technique, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Techniques/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Technique
     * @name Technique.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Techniques/count
     */
    techniqueCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Techniques/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Technique
     * @name Technique.createChangeStream__post_Techniques_change-stream
     * @summary Create a change stream.
     * @request POST:/Techniques/change-stream
     */
    techniqueCreateChangeStreamPostTechniquesChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Techniques/change-stream`, "POST", params, data),

    /**
     * @tags Technique
     * @name Technique.createChangeStream__get_Techniques_change-stream
     * @summary Create a change stream.
     * @request GET:/Techniques/change-stream
     */
    techniqueCreateChangeStreamGetTechniquesChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Techniques/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  pleinCarburants = {
    /**
     * @tags PleinCarburant
     * @name PleinCarburant.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/PleinCarburants/{id}/account
     */
    pleinCarburantPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/PleinCarburants/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.prototype.__get__moteur
     * @summary Fetches belongsTo relation moteur.
     * @request GET:/PleinCarburants/{id}/moteur
     */
    pleinCarburantPrototypeGetMoteur: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Moteur, any>(`/PleinCarburants/${id}/moteur${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.prototype.__get__cMR
     * @summary Fetches belongsTo relation cMR.
     * @request GET:/PleinCarburants/{id}/cMR
     */
    pleinCarburantPrototypeGetCMr: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<CMR, any>(`/PleinCarburants/${id}/cMR${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/PleinCarburants
     */
    pleinCarburantCreate: (data: PleinCarburant, params?: RequestParams) =>
      this.request<PleinCarburant, any>(`/PleinCarburants`, "POST", params, data),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/PleinCarburants
     */
    pleinCarburantPatchOrCreate: (data: PleinCarburant, params?: RequestParams) =>
      this.request<PleinCarburant, any>(`/PleinCarburants`, "PATCH", params, data),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.replaceOrCreate__put_PleinCarburants
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/PleinCarburants
     */
    pleinCarburantReplaceOrCreatePutPleinCarburants: (data: PleinCarburant, params?: RequestParams) =>
      this.request<PleinCarburant, any>(`/PleinCarburants`, "PUT", params, data),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/PleinCarburants
     */
    pleinCarburantFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<PleinCarburant[], any>(`/PleinCarburants${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.replaceOrCreate__post_PleinCarburants_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/PleinCarburants/replaceOrCreate
     */
    pleinCarburantReplaceOrCreatePostPleinCarburantsReplaceOrCreate: (data: PleinCarburant, params?: RequestParams) =>
      this.request<PleinCarburant, any>(`/PleinCarburants/replaceOrCreate`, "POST", params, data),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/PleinCarburants/upsertWithWhere
     */
    pleinCarburantUpsertWithWhere: (data: PleinCarburant, query?: { where?: string }, params?: RequestParams) =>
      this.request<PleinCarburant, any>(
        `/PleinCarburants/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.exists__get_PleinCarburants_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/PleinCarburants/{id}/exists
     */
    pleinCarburantExistsGetPleinCarburantsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/PleinCarburants/${id}/exists`, "GET", params),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.exists__head_PleinCarburants_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/PleinCarburants/{id}
     */
    pleinCarburantExistsHeadPleinCarburantsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/PleinCarburants/${id}`, "HEAD", params),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/PleinCarburants/{id}
     */
    pleinCarburantFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<PleinCarburant, any>(`/PleinCarburants/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.replaceById__put_PleinCarburants_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/PleinCarburants/{id}
     */
    pleinCarburantReplaceByIdPutPleinCarburantsId: (id: string, data: PleinCarburant, params?: RequestParams) =>
      this.request<PleinCarburant, any>(`/PleinCarburants/${id}`, "PUT", params, data),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/PleinCarburants/{id}
     */
    pleinCarburantDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/PleinCarburants/${id}`, "DELETE", params),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/PleinCarburants/{id}
     */
    pleinCarburantPrototypePatchAttributes: (id: string, data: PleinCarburant, params?: RequestParams) =>
      this.request<PleinCarburant, any>(`/PleinCarburants/${id}`, "PATCH", params, data),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.replaceById__post_PleinCarburants_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/PleinCarburants/{id}/replace
     */
    pleinCarburantReplaceByIdPostPleinCarburantsIdReplace: (id: string, data: PleinCarburant, params?: RequestParams) =>
      this.request<PleinCarburant, any>(`/PleinCarburants/${id}/replace`, "POST", params, data),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/PleinCarburants/findOne
     */
    pleinCarburantFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<PleinCarburant, any>(`/PleinCarburants/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/PleinCarburants/update
     */
    pleinCarburantUpdateAll: (data: PleinCarburant, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/PleinCarburants/update${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/PleinCarburants/count
     */
    pleinCarburantCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/PleinCarburants/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.createChangeStream__post_PleinCarburants_change-stream
     * @summary Create a change stream.
     * @request POST:/PleinCarburants/change-stream
     */
    pleinCarburantCreateChangeStreamPostPleinCarburantsChangeStream: (
      data: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/PleinCarburants/change-stream`, "POST", params, data),

    /**
     * @tags PleinCarburant
     * @name PleinCarburant.createChangeStream__get_PleinCarburants_change-stream
     * @summary Create a change stream.
     * @request GET:/PleinCarburants/change-stream
     */
    pleinCarburantCreateChangeStreamGetPleinCarburantsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/PleinCarburants/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  rHs = {
    /**
     * @tags RH
     * @name RH.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/RHs/{id}/account
     */
    rhPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/RHs/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags RH
     * @name RH.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/RHs
     */
    rhCreate: (data: RH, params?: RequestParams) => this.request<RH, any>(`/RHs`, "POST", params, data),

    /**
     * @tags RH
     * @name RH.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/RHs
     */
    rhPatchOrCreate: (data: RH, params?: RequestParams) => this.request<RH, any>(`/RHs`, "PATCH", params, data),

    /**
     * @tags RH
     * @name RH.replaceOrCreate__put_RHs
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/RHs
     */
    rhReplaceOrCreatePutRHs: (data: RH, params?: RequestParams) => this.request<RH, any>(`/RHs`, "PUT", params, data),

    /**
     * @tags RH
     * @name RH.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/RHs
     */
    rhFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<RH[], any>(`/RHs${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags RH
     * @name RH.replaceOrCreate__post_RHs_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/RHs/replaceOrCreate
     */
    rhReplaceOrCreatePostRHsReplaceOrCreate: (data: RH, params?: RequestParams) =>
      this.request<RH, any>(`/RHs/replaceOrCreate`, "POST", params, data),

    /**
     * @tags RH
     * @name RH.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/RHs/upsertWithWhere
     */
    rhUpsertWithWhere: (data: RH, query?: { where?: string }, params?: RequestParams) =>
      this.request<RH, any>(`/RHs/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags RH
     * @name RH.exists__get_RHs_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/RHs/{id}/exists
     */
    rhExistsGetRHsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/RHs/${id}/exists`, "GET", params),

    /**
     * @tags RH
     * @name RH.exists__head_RHs_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/RHs/{id}
     */
    rhExistsHeadRHsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/RHs/${id}`, "HEAD", params),

    /**
     * @tags RH
     * @name RH.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/RHs/{id}
     */
    rhFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<RH, any>(`/RHs/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags RH
     * @name RH.replaceById__put_RHs_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/RHs/{id}
     */
    rhReplaceByIdPutRHsId: (id: string, data: RH, params?: RequestParams) =>
      this.request<RH, any>(`/RHs/${id}`, "PUT", params, data),

    /**
     * @tags RH
     * @name RH.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/RHs/{id}
     */
    rhDeleteById: (id: string, params?: RequestParams) => this.request<object, any>(`/RHs/${id}`, "DELETE", params),

    /**
     * @tags RH
     * @name RH.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/RHs/{id}
     */
    rhPrototypePatchAttributes: (id: string, data: RH, params?: RequestParams) =>
      this.request<RH, any>(`/RHs/${id}`, "PATCH", params, data),

    /**
     * @tags RH
     * @name RH.replaceById__post_RHs_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/RHs/{id}/replace
     */
    rhReplaceByIdPostRHsIdReplace: (id: string, data: RH, params?: RequestParams) =>
      this.request<RH, any>(`/RHs/${id}/replace`, "POST", params, data),

    /**
     * @tags RH
     * @name RH.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/RHs/findOne
     */
    rhFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<RH, any>(`/RHs/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags RH
     * @name RH.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/RHs/update
     */
    rhUpdateAll: (data: RH, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/RHs/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags RH
     * @name RH.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/RHs/count
     */
    rhCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/RHs/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags RH
     * @name RH.createChangeStream__post_RHs_change-stream
     * @summary Create a change stream.
     * @request POST:/RHs/change-stream
     */
    rhCreateChangeStreamPostRHsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/RHs/change-stream`, "POST", params, data),

    /**
     * @tags RH
     * @name RH.createChangeStream__get_RHs_change-stream
     * @summary Create a change stream.
     * @request GET:/RHs/change-stream
     */
    rhCreateChangeStreamGetRHsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/RHs/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  accueils = {
    /**
     * @tags Accueil
     * @name Accueil.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/Accueils/{id}/account
     */
    accueilPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Accueils/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Accueil
     * @name Accueil.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Accueils
     */
    accueilCreate: (data: Accueil, params?: RequestParams) =>
      this.request<Accueil, any>(`/Accueils`, "POST", params, data),

    /**
     * @tags Accueil
     * @name Accueil.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Accueils
     */
    accueilPatchOrCreate: (data: Accueil, params?: RequestParams) =>
      this.request<Accueil, any>(`/Accueils`, "PATCH", params, data),

    /**
     * @tags Accueil
     * @name Accueil.replaceOrCreate__put_Accueils
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Accueils
     */
    accueilReplaceOrCreatePutAccueils: (data: Accueil, params?: RequestParams) =>
      this.request<Accueil, any>(`/Accueils`, "PUT", params, data),

    /**
     * @tags Accueil
     * @name Accueil.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Accueils
     */
    accueilFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Accueil[], any>(`/Accueils${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Accueil
     * @name Accueil.replaceOrCreate__post_Accueils_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Accueils/replaceOrCreate
     */
    accueilReplaceOrCreatePostAccueilsReplaceOrCreate: (data: Accueil, params?: RequestParams) =>
      this.request<Accueil, any>(`/Accueils/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Accueil
     * @name Accueil.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Accueils/upsertWithWhere
     */
    accueilUpsertWithWhere: (data: Accueil, query?: { where?: string }, params?: RequestParams) =>
      this.request<Accueil, any>(`/Accueils/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Accueil
     * @name Accueil.exists__get_Accueils_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Accueils/{id}/exists
     */
    accueilExistsGetAccueilsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Accueils/${id}/exists`, "GET", params),

    /**
     * @tags Accueil
     * @name Accueil.exists__head_Accueils_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Accueils/{id}
     */
    accueilExistsHeadAccueilsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Accueils/${id}`, "HEAD", params),

    /**
     * @tags Accueil
     * @name Accueil.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Accueils/{id}
     */
    accueilFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Accueil, any>(`/Accueils/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Accueil
     * @name Accueil.replaceById__put_Accueils_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Accueils/{id}
     */
    accueilReplaceByIdPutAccueilsId: (id: string, data: Accueil, params?: RequestParams) =>
      this.request<Accueil, any>(`/Accueils/${id}`, "PUT", params, data),

    /**
     * @tags Accueil
     * @name Accueil.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Accueils/{id}
     */
    accueilDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Accueils/${id}`, "DELETE", params),

    /**
     * @tags Accueil
     * @name Accueil.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Accueils/{id}
     */
    accueilPrototypePatchAttributes: (id: string, data: Accueil, params?: RequestParams) =>
      this.request<Accueil, any>(`/Accueils/${id}`, "PATCH", params, data),

    /**
     * @tags Accueil
     * @name Accueil.replaceById__post_Accueils_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Accueils/{id}/replace
     */
    accueilReplaceByIdPostAccueilsIdReplace: (id: string, data: Accueil, params?: RequestParams) =>
      this.request<Accueil, any>(`/Accueils/${id}/replace`, "POST", params, data),

    /**
     * @tags Accueil
     * @name Accueil.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Accueils/findOne
     */
    accueilFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Accueil, any>(`/Accueils/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Accueil
     * @name Accueil.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Accueils/update
     */
    accueilUpdateAll: (data: Accueil, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Accueils/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Accueil
     * @name Accueil.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Accueils/count
     */
    accueilCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Accueils/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Accueil
     * @name Accueil.createChangeStream__post_Accueils_change-stream
     * @summary Create a change stream.
     * @request POST:/Accueils/change-stream
     */
    accueilCreateChangeStreamPostAccueilsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Accueils/change-stream`, "POST", params, data),

    /**
     * @tags Accueil
     * @name Accueil.createChangeStream__get_Accueils_change-stream
     * @summary Create a change stream.
     * @request GET:/Accueils/change-stream
     */
    accueilCreateChangeStreamGetAccueilsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Accueils/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  tractes = {
    /**
     * @tags Tracte
     * @name Tracte.prototype.__findById__etapes
     * @summary Find a related item by id for etapes.
     * @request GET:/Tractes/{id}/etapes/{fk}
     */
    tractePrototypeFindByIdEtapes: (id: string, fk: string, params?: RequestParams) =>
      this.request<Etape, any>(`/Tractes/${id}/etapes/${fk}`, "GET", params),

    /**
     * @tags Tracte
     * @name Tracte.prototype.__destroyById__etapes
     * @summary Delete a related item by id for etapes.
     * @request DELETE:/Tractes/{id}/etapes/{fk}
     */
    tractePrototypeDestroyByIdEtapes: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Tractes/${id}/etapes/${fk}`, "DELETE", params),

    /**
     * @tags Tracte
     * @name Tracte.prototype.__updateById__etapes
     * @summary Update a related item by id for etapes.
     * @request PUT:/Tractes/{id}/etapes/{fk}
     */
    tractePrototypeUpdateByIdEtapes: (id: string, fk: string, data: Etape, params?: RequestParams) =>
      this.request<Etape, any>(`/Tractes/${id}/etapes/${fk}`, "PUT", params, data),

    /**
     * @tags Tracte
     * @name Tracte.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/Tractes/{id}/account
     */
    tractePrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Tractes/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Tracte
     * @name Tracte.prototype.__get__vehicleimage
     * @summary Fetches belongsTo relation vehicleimage.
     * @request GET:/Tractes/{id}/vehicleimage
     */
    tractePrototypeGetVehicleimage: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<VehicleImage, any>(`/Tractes/${id}/vehicleimage${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Tracte
     * @name Tracte.prototype.__get__etapes
     * @summary Queries etapes of Tracte.
     * @request GET:/Tractes/{id}/etapes
     */
    tractePrototypeGetEtapes: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Etape[], any>(`/Tractes/${id}/etapes${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Tracte
     * @name Tracte.prototype.__create__etapes
     * @summary Creates a new instance in etapes of this model.
     * @request POST:/Tractes/{id}/etapes
     */
    tractePrototypeCreateEtapes: (id: string, data: Etape, params?: RequestParams) =>
      this.request<Etape, any>(`/Tractes/${id}/etapes`, "POST", params, data),

    /**
     * @tags Tracte
     * @name Tracte.prototype.__delete__etapes
     * @summary Deletes all etapes of this model.
     * @request DELETE:/Tractes/{id}/etapes
     */
    tractePrototypeDeleteEtapes: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Tractes/${id}/etapes`, "DELETE", params),

    /**
     * @tags Tracte
     * @name Tracte.prototype.__count__etapes
     * @summary Counts etapes of Tracte.
     * @request GET:/Tractes/{id}/etapes/count
     */
    tractePrototypeCountEtapes: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Tractes/${id}/etapes/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Tracte
     * @name Tracte.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Tractes
     */
    tracteCreate: (data: Tracte, params?: RequestParams) => this.request<Tracte, any>(`/Tractes`, "POST", params, data),

    /**
     * @tags Tracte
     * @name Tracte.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Tractes
     */
    tractePatchOrCreate: (data: Tracte, params?: RequestParams) =>
      this.request<Tracte, any>(`/Tractes`, "PATCH", params, data),

    /**
     * @tags Tracte
     * @name Tracte.replaceOrCreate__put_Tractes
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Tractes
     */
    tracteReplaceOrCreatePutTractes: (data: Tracte, params?: RequestParams) =>
      this.request<Tracte, any>(`/Tractes`, "PUT", params, data),

    /**
     * @tags Tracte
     * @name Tracte.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Tractes
     */
    tracteFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Tracte[], any>(`/Tractes${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Tracte
     * @name Tracte.replaceOrCreate__post_Tractes_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Tractes/replaceOrCreate
     */
    tracteReplaceOrCreatePostTractesReplaceOrCreate: (data: Tracte, params?: RequestParams) =>
      this.request<Tracte, any>(`/Tractes/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Tracte
     * @name Tracte.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Tractes/upsertWithWhere
     */
    tracteUpsertWithWhere: (data: Tracte, query?: { where?: string }, params?: RequestParams) =>
      this.request<Tracte, any>(`/Tractes/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Tracte
     * @name Tracte.exists__get_Tractes_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Tractes/{id}/exists
     */
    tracteExistsGetTractesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Tractes/${id}/exists`, "GET", params),

    /**
     * @tags Tracte
     * @name Tracte.exists__head_Tractes_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Tractes/{id}
     */
    tracteExistsHeadTractesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Tractes/${id}`, "HEAD", params),

    /**
     * @tags Tracte
     * @name Tracte.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Tractes/{id}
     */
    tracteFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Tracte, any>(`/Tractes/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Tracte
     * @name Tracte.replaceById__put_Tractes_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Tractes/{id}
     */
    tracteReplaceByIdPutTractesId: (id: string, data: Tracte, params?: RequestParams) =>
      this.request<Tracte, any>(`/Tractes/${id}`, "PUT", params, data),

    /**
     * @tags Tracte
     * @name Tracte.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Tractes/{id}
     */
    tracteDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Tractes/${id}`, "DELETE", params),

    /**
     * @tags Tracte
     * @name Tracte.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Tractes/{id}
     */
    tractePrototypePatchAttributes: (id: string, data: Tracte, params?: RequestParams) =>
      this.request<Tracte, any>(`/Tractes/${id}`, "PATCH", params, data),

    /**
     * @tags Tracte
     * @name Tracte.replaceById__post_Tractes_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Tractes/{id}/replace
     */
    tracteReplaceByIdPostTractesIdReplace: (id: string, data: Tracte, params?: RequestParams) =>
      this.request<Tracte, any>(`/Tractes/${id}/replace`, "POST", params, data),

    /**
     * @tags Tracte
     * @name Tracte.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Tractes/findOne
     */
    tracteFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Tracte, any>(`/Tractes/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Tracte
     * @name Tracte.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Tractes/update
     */
    tracteUpdateAll: (data: Tracte, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Tractes/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Tracte
     * @name Tracte.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Tractes/count
     */
    tracteCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Tractes/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Tracte
     * @name Tracte.createChangeStream__post_Tractes_change-stream
     * @summary Create a change stream.
     * @request POST:/Tractes/change-stream
     */
    tracteCreateChangeStreamPostTractesChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Tractes/change-stream`, "POST", params, data),

    /**
     * @tags Tracte
     * @name Tracte.createChangeStream__get_Tractes_change-stream
     * @summary Create a change stream.
     * @request GET:/Tractes/change-stream
     */
    tracteCreateChangeStreamGetTractesChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Tractes/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  parametres = {
    /**
     * @tags Parametres
     * @name Parametres.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/Parametres/{id}/account
     */
    parametresPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Parametres/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Parametres
     * @name Parametres.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Parametres
     */
    parametresCreate: (data: Parametres, params?: RequestParams) =>
      this.request<Parametres, any>(`/Parametres`, "POST", params, data),

    /**
     * @tags Parametres
     * @name Parametres.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Parametres
     */
    parametresPatchOrCreate: (data: Parametres, params?: RequestParams) =>
      this.request<Parametres, any>(`/Parametres`, "PATCH", params, data),

    /**
     * @tags Parametres
     * @name Parametres.replaceOrCreate__put_Parametres
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Parametres
     */
    parametresReplaceOrCreatePutParametres: (data: Parametres, params?: RequestParams) =>
      this.request<Parametres, any>(`/Parametres`, "PUT", params, data),

    /**
     * @tags Parametres
     * @name Parametres.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Parametres
     */
    parametresFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Parametres[], any>(`/Parametres${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Parametres
     * @name Parametres.replaceOrCreate__post_Parametres_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Parametres/replaceOrCreate
     */
    parametresReplaceOrCreatePostParametresReplaceOrCreate: (data: Parametres, params?: RequestParams) =>
      this.request<Parametres, any>(`/Parametres/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Parametres
     * @name Parametres.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Parametres/upsertWithWhere
     */
    parametresUpsertWithWhere: (data: Parametres, query?: { where?: string }, params?: RequestParams) =>
      this.request<Parametres, any>(`/Parametres/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Parametres
     * @name Parametres.exists__get_Parametres_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Parametres/{id}/exists
     */
    parametresExistsGetParametresIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Parametres/${id}/exists`, "GET", params),

    /**
     * @tags Parametres
     * @name Parametres.exists__head_Parametres_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Parametres/{id}
     */
    parametresExistsHeadParametresId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Parametres/${id}`, "HEAD", params),

    /**
     * @tags Parametres
     * @name Parametres.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Parametres/{id}
     */
    parametresFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Parametres, any>(`/Parametres/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Parametres
     * @name Parametres.replaceById__put_Parametres_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Parametres/{id}
     */
    parametresReplaceByIdPutParametresId: (id: string, data: Parametres, params?: RequestParams) =>
      this.request<Parametres, any>(`/Parametres/${id}`, "PUT", params, data),

    /**
     * @tags Parametres
     * @name Parametres.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Parametres/{id}
     */
    parametresDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Parametres/${id}`, "DELETE", params),

    /**
     * @tags Parametres
     * @name Parametres.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Parametres/{id}
     */
    parametresPrototypePatchAttributes: (id: string, data: Parametres, params?: RequestParams) =>
      this.request<Parametres, any>(`/Parametres/${id}`, "PATCH", params, data),

    /**
     * @tags Parametres
     * @name Parametres.replaceById__post_Parametres_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Parametres/{id}/replace
     */
    parametresReplaceByIdPostParametresIdReplace: (id: string, data: Parametres, params?: RequestParams) =>
      this.request<Parametres, any>(`/Parametres/${id}/replace`, "POST", params, data),

    /**
     * @tags Parametres
     * @name Parametres.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Parametres/findOne
     */
    parametresFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Parametres, any>(`/Parametres/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Parametres
     * @name Parametres.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Parametres/update
     */
    parametresUpdateAll: (data: Parametres, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Parametres/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Parametres
     * @name Parametres.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Parametres/count
     */
    parametresCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Parametres/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Parametres
     * @name Parametres.createChangeStream__post_Parametres_change-stream
     * @summary Create a change stream.
     * @request POST:/Parametres/change-stream
     */
    parametresCreateChangeStreamPostParametresChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Parametres/change-stream`, "POST", params, data),

    /**
     * @tags Parametres
     * @name Parametres.createChangeStream__get_Parametres_change-stream
     * @summary Create a change stream.
     * @request GET:/Parametres/change-stream
     */
    parametresCreateChangeStreamGetParametresChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Parametres/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  assuranceMatLocs = {
    /**
     * @tags AssuranceMatLoc
     * @name AssuranceMatLoc.prototype.__get__assurance
     * @summary Fetches belongsTo relation assurance.
     * @request GET:/AssuranceMatLocs/{id}/assurance
     */
    assuranceMatLocPrototypeGetAssurance: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Assurance, any>(`/AssuranceMatLocs/${id}/assurance${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AssuranceMatLoc
     * @name AssuranceMatLoc.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/AssuranceMatLocs
     */
    assuranceMatLocCreate: (data: AssuranceMatLoc, params?: RequestParams) =>
      this.request<AssuranceMatLoc, any>(`/AssuranceMatLocs`, "POST", params, data),

    /**
     * @tags AssuranceMatLoc
     * @name AssuranceMatLoc.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/AssuranceMatLocs
     */
    assuranceMatLocPatchOrCreate: (data: AssuranceMatLoc, params?: RequestParams) =>
      this.request<AssuranceMatLoc, any>(`/AssuranceMatLocs`, "PATCH", params, data),

    /**
     * @tags AssuranceMatLoc
     * @name AssuranceMatLoc.replaceOrCreate__put_AssuranceMatLocs
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/AssuranceMatLocs
     */
    assuranceMatLocReplaceOrCreatePutAssuranceMatLocs: (data: AssuranceMatLoc, params?: RequestParams) =>
      this.request<AssuranceMatLoc, any>(`/AssuranceMatLocs`, "PUT", params, data),

    /**
     * @tags AssuranceMatLoc
     * @name AssuranceMatLoc.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/AssuranceMatLocs
     */
    assuranceMatLocFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<AssuranceMatLoc[], any>(`/AssuranceMatLocs${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AssuranceMatLoc
     * @name AssuranceMatLoc.replaceOrCreate__post_AssuranceMatLocs_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/AssuranceMatLocs/replaceOrCreate
     */
    assuranceMatLocReplaceOrCreatePostAssuranceMatLocsReplaceOrCreate: (
      data: AssuranceMatLoc,
      params?: RequestParams,
    ) => this.request<AssuranceMatLoc, any>(`/AssuranceMatLocs/replaceOrCreate`, "POST", params, data),

    /**
     * @tags AssuranceMatLoc
     * @name AssuranceMatLoc.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/AssuranceMatLocs/upsertWithWhere
     */
    assuranceMatLocUpsertWithWhere: (data: AssuranceMatLoc, query?: { where?: string }, params?: RequestParams) =>
      this.request<AssuranceMatLoc, any>(
        `/AssuranceMatLocs/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags AssuranceMatLoc
     * @name AssuranceMatLoc.exists__get_AssuranceMatLocs_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/AssuranceMatLocs/{id}/exists
     */
    assuranceMatLocExistsGetAssuranceMatLocsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/AssuranceMatLocs/${id}/exists`, "GET", params),

    /**
     * @tags AssuranceMatLoc
     * @name AssuranceMatLoc.exists__head_AssuranceMatLocs_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/AssuranceMatLocs/{id}
     */
    assuranceMatLocExistsHeadAssuranceMatLocsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/AssuranceMatLocs/${id}`, "HEAD", params),

    /**
     * @tags AssuranceMatLoc
     * @name AssuranceMatLoc.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/AssuranceMatLocs/{id}
     */
    assuranceMatLocFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<AssuranceMatLoc, any>(`/AssuranceMatLocs/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AssuranceMatLoc
     * @name AssuranceMatLoc.replaceById__put_AssuranceMatLocs_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/AssuranceMatLocs/{id}
     */
    assuranceMatLocReplaceByIdPutAssuranceMatLocsId: (id: string, data: AssuranceMatLoc, params?: RequestParams) =>
      this.request<AssuranceMatLoc, any>(`/AssuranceMatLocs/${id}`, "PUT", params, data),

    /**
     * @tags AssuranceMatLoc
     * @name AssuranceMatLoc.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/AssuranceMatLocs/{id}
     */
    assuranceMatLocDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/AssuranceMatLocs/${id}`, "DELETE", params),

    /**
     * @tags AssuranceMatLoc
     * @name AssuranceMatLoc.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/AssuranceMatLocs/{id}
     */
    assuranceMatLocPrototypePatchAttributes: (id: string, data: AssuranceMatLoc, params?: RequestParams) =>
      this.request<AssuranceMatLoc, any>(`/AssuranceMatLocs/${id}`, "PATCH", params, data),

    /**
     * @tags AssuranceMatLoc
     * @name AssuranceMatLoc.replaceById__post_AssuranceMatLocs_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/AssuranceMatLocs/{id}/replace
     */
    assuranceMatLocReplaceByIdPostAssuranceMatLocsIdReplace: (
      id: string,
      data: AssuranceMatLoc,
      params?: RequestParams,
    ) => this.request<AssuranceMatLoc, any>(`/AssuranceMatLocs/${id}/replace`, "POST", params, data),

    /**
     * @tags AssuranceMatLoc
     * @name AssuranceMatLoc.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/AssuranceMatLocs/findOne
     */
    assuranceMatLocFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<AssuranceMatLoc, any>(`/AssuranceMatLocs/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AssuranceMatLoc
     * @name AssuranceMatLoc.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/AssuranceMatLocs/update
     */
    assuranceMatLocUpdateAll: (data: AssuranceMatLoc, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/AssuranceMatLocs/update${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags AssuranceMatLoc
     * @name AssuranceMatLoc.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/AssuranceMatLocs/count
     */
    assuranceMatLocCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/AssuranceMatLocs/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AssuranceMatLoc
     * @name AssuranceMatLoc.createChangeStream__post_AssuranceMatLocs_change-stream
     * @summary Create a change stream.
     * @request POST:/AssuranceMatLocs/change-stream
     */
    assuranceMatLocCreateChangeStreamPostAssuranceMatLocsChangeStream: (
      data: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/AssuranceMatLocs/change-stream`, "POST", params, data),

    /**
     * @tags AssuranceMatLoc
     * @name AssuranceMatLoc.createChangeStream__get_AssuranceMatLocs_change-stream
     * @summary Create a change stream.
     * @request GET:/AssuranceMatLocs/change-stream
     */
    assuranceMatLocCreateChangeStreamGetAssuranceMatLocsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/AssuranceMatLocs/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  vehicleImages = {
    /**
     * @tags VehicleImage
     * @name VehicleImage.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/VehicleImages
     */
    vehicleImageCreate: (data: VehicleImage, params?: RequestParams) =>
      this.request<VehicleImage, any>(`/VehicleImages`, "POST", params, data),

    /**
     * @tags VehicleImage
     * @name VehicleImage.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/VehicleImages
     */
    vehicleImagePatchOrCreate: (data: VehicleImage, params?: RequestParams) =>
      this.request<VehicleImage, any>(`/VehicleImages`, "PATCH", params, data),

    /**
     * @tags VehicleImage
     * @name VehicleImage.replaceOrCreate__put_VehicleImages
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/VehicleImages
     */
    vehicleImageReplaceOrCreatePutVehicleImages: (data: VehicleImage, params?: RequestParams) =>
      this.request<VehicleImage, any>(`/VehicleImages`, "PUT", params, data),

    /**
     * @tags VehicleImage
     * @name VehicleImage.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/VehicleImages
     */
    vehicleImageFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<VehicleImage[], any>(`/VehicleImages${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags VehicleImage
     * @name VehicleImage.replaceOrCreate__post_VehicleImages_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/VehicleImages/replaceOrCreate
     */
    vehicleImageReplaceOrCreatePostVehicleImagesReplaceOrCreate: (data: VehicleImage, params?: RequestParams) =>
      this.request<VehicleImage, any>(`/VehicleImages/replaceOrCreate`, "POST", params, data),

    /**
     * @tags VehicleImage
     * @name VehicleImage.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/VehicleImages/upsertWithWhere
     */
    vehicleImageUpsertWithWhere: (data: VehicleImage, query?: { where?: string }, params?: RequestParams) =>
      this.request<VehicleImage, any>(
        `/VehicleImages/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags VehicleImage
     * @name VehicleImage.exists__get_VehicleImages_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/VehicleImages/{id}/exists
     */
    vehicleImageExistsGetVehicleImagesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/VehicleImages/${id}/exists`, "GET", params),

    /**
     * @tags VehicleImage
     * @name VehicleImage.exists__head_VehicleImages_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/VehicleImages/{id}
     */
    vehicleImageExistsHeadVehicleImagesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/VehicleImages/${id}`, "HEAD", params),

    /**
     * @tags VehicleImage
     * @name VehicleImage.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/VehicleImages/{id}
     */
    vehicleImageFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<VehicleImage, any>(`/VehicleImages/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags VehicleImage
     * @name VehicleImage.replaceById__put_VehicleImages_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/VehicleImages/{id}
     */
    vehicleImageReplaceByIdPutVehicleImagesId: (id: string, data: VehicleImage, params?: RequestParams) =>
      this.request<VehicleImage, any>(`/VehicleImages/${id}`, "PUT", params, data),

    /**
     * @tags VehicleImage
     * @name VehicleImage.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/VehicleImages/{id}
     */
    vehicleImageDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/VehicleImages/${id}`, "DELETE", params),

    /**
     * @tags VehicleImage
     * @name VehicleImage.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/VehicleImages/{id}
     */
    vehicleImagePrototypePatchAttributes: (id: string, data: VehicleImage, params?: RequestParams) =>
      this.request<VehicleImage, any>(`/VehicleImages/${id}`, "PATCH", params, data),

    /**
     * @tags VehicleImage
     * @name VehicleImage.replaceById__post_VehicleImages_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/VehicleImages/{id}/replace
     */
    vehicleImageReplaceByIdPostVehicleImagesIdReplace: (id: string, data: VehicleImage, params?: RequestParams) =>
      this.request<VehicleImage, any>(`/VehicleImages/${id}/replace`, "POST", params, data),

    /**
     * @tags VehicleImage
     * @name VehicleImage.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/VehicleImages/findOne
     */
    vehicleImageFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<VehicleImage, any>(`/VehicleImages/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags VehicleImage
     * @name VehicleImage.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/VehicleImages/update
     */
    vehicleImageUpdateAll: (data: VehicleImage, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/VehicleImages/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags VehicleImage
     * @name VehicleImage.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/VehicleImages/count
     */
    vehicleImageCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/VehicleImages/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags VehicleImage
     * @name VehicleImage.createChangeStream__post_VehicleImages_change-stream
     * @summary Create a change stream.
     * @request POST:/VehicleImages/change-stream
     */
    vehicleImageCreateChangeStreamPostVehicleImagesChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/VehicleImages/change-stream`, "POST", params, data),

    /**
     * @tags VehicleImage
     * @name VehicleImage.createChangeStream__get_VehicleImages_change-stream
     * @summary Create a change stream.
     * @request GET:/VehicleImages/change-stream
     */
    vehicleImageCreateChangeStreamGetVehicleImagesChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/VehicleImages/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  appVersions = {
    /**
     * @tags AppVersion
     * @name AppVersion.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/AppVersions
     */
    appVersionCreate: (data: AppVersion, params?: RequestParams) =>
      this.request<AppVersion, any>(`/AppVersions`, "POST", params, data),

    /**
     * @tags AppVersion
     * @name AppVersion.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/AppVersions
     */
    appVersionPatchOrCreate: (data: AppVersion, params?: RequestParams) =>
      this.request<AppVersion, any>(`/AppVersions`, "PATCH", params, data),

    /**
     * @tags AppVersion
     * @name AppVersion.replaceOrCreate__put_AppVersions
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/AppVersions
     */
    appVersionReplaceOrCreatePutAppVersions: (data: AppVersion, params?: RequestParams) =>
      this.request<AppVersion, any>(`/AppVersions`, "PUT", params, data),

    /**
     * @tags AppVersion
     * @name AppVersion.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/AppVersions
     */
    appVersionFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<AppVersion[], any>(`/AppVersions${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AppVersion
     * @name AppVersion.replaceOrCreate__post_AppVersions_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/AppVersions/replaceOrCreate
     */
    appVersionReplaceOrCreatePostAppVersionsReplaceOrCreate: (data: AppVersion, params?: RequestParams) =>
      this.request<AppVersion, any>(`/AppVersions/replaceOrCreate`, "POST", params, data),

    /**
     * @tags AppVersion
     * @name AppVersion.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/AppVersions/upsertWithWhere
     */
    appVersionUpsertWithWhere: (data: AppVersion, query?: { where?: string }, params?: RequestParams) =>
      this.request<AppVersion, any>(`/AppVersions/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags AppVersion
     * @name AppVersion.exists__get_AppVersions_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/AppVersions/{id}/exists
     */
    appVersionExistsGetAppVersionsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/AppVersions/${id}/exists`, "GET", params),

    /**
     * @tags AppVersion
     * @name AppVersion.exists__head_AppVersions_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/AppVersions/{id}
     */
    appVersionExistsHeadAppVersionsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/AppVersions/${id}`, "HEAD", params),

    /**
     * @tags AppVersion
     * @name AppVersion.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/AppVersions/{id}
     */
    appVersionFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<AppVersion, any>(`/AppVersions/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AppVersion
     * @name AppVersion.replaceById__put_AppVersions_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/AppVersions/{id}
     */
    appVersionReplaceByIdPutAppVersionsId: (id: string, data: AppVersion, params?: RequestParams) =>
      this.request<AppVersion, any>(`/AppVersions/${id}`, "PUT", params, data),

    /**
     * @tags AppVersion
     * @name AppVersion.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/AppVersions/{id}
     */
    appVersionDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/AppVersions/${id}`, "DELETE", params),

    /**
     * @tags AppVersion
     * @name AppVersion.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/AppVersions/{id}
     */
    appVersionPrototypePatchAttributes: (id: string, data: AppVersion, params?: RequestParams) =>
      this.request<AppVersion, any>(`/AppVersions/${id}`, "PATCH", params, data),

    /**
     * @tags AppVersion
     * @name AppVersion.replaceById__post_AppVersions_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/AppVersions/{id}/replace
     */
    appVersionReplaceByIdPostAppVersionsIdReplace: (id: string, data: AppVersion, params?: RequestParams) =>
      this.request<AppVersion, any>(`/AppVersions/${id}/replace`, "POST", params, data),

    /**
     * @tags AppVersion
     * @name AppVersion.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/AppVersions/findOne
     */
    appVersionFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<AppVersion, any>(`/AppVersions/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AppVersion
     * @name AppVersion.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/AppVersions/update
     */
    appVersionUpdateAll: (data: AppVersion, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/AppVersions/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags AppVersion
     * @name AppVersion.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/AppVersions/count
     */
    appVersionCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/AppVersions/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AppVersion
     * @name AppVersion.createChangeStream__post_AppVersions_change-stream
     * @summary Create a change stream.
     * @request POST:/AppVersions/change-stream
     */
    appVersionCreateChangeStreamPostAppVersionsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/AppVersions/change-stream`, "POST", params, data),

    /**
     * @tags AppVersion
     * @name AppVersion.createChangeStream__get_AppVersions_change-stream
     * @summary Create a change stream.
     * @request GET:/AppVersions/change-stream
     */
    appVersionCreateChangeStreamGetAppVersionsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/AppVersions/change-stream${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AppVersion
     * @name AppVersion.notifyNewVersion
     * @summary Notify all users that a new version is released
     * @request POST:/AppVersions/notifyNewVersion
     */
    appVersionNotifyNewVersion: (params?: RequestParams) =>
      this.request<object, any>(`/AppVersions/notifyNewVersion`, "POST", params),

    /**
     * @tags AppVersion
     * @name AppVersion.remindNewVersion
     * @summary Notify all users that a new version is released
     * @request POST:/AppVersions/remindNewVersion
     */
    appVersionRemindNewVersion: (params?: RequestParams) =>
      this.request<object, any>(`/AppVersions/remindNewVersion`, "POST", params),
  };
  bus = {
    /**
     * @tags Bu
     * @name Bu.prototype.__get__accounts
     * @summary Fetches hasOne relation accounts.
     * @request GET:/Bus/{id}/accounts
     */
    buPrototypeGetAccounts: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Bus/${id}/accounts${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Bu
     * @name Bu.prototype.__create__accounts
     * @summary Creates a new instance in accounts of this model.
     * @request POST:/Bus/{id}/accounts
     */
    buPrototypeCreateAccounts: (id: string, data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Bus/${id}/accounts`, "POST", params, data),

    /**
     * @tags Bu
     * @name Bu.prototype.__update__accounts
     * @summary Update accounts of this model.
     * @request PUT:/Bus/{id}/accounts
     */
    buPrototypeUpdateAccounts: (id: string, data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Bus/${id}/accounts`, "PUT", params, data),

    /**
     * @tags Bu
     * @name Bu.prototype.__destroy__accounts
     * @summary Deletes accounts of this model.
     * @request DELETE:/Bus/{id}/accounts
     */
    buPrototypeDestroyAccounts: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Bus/${id}/accounts`, "DELETE", params),

    /**
     * @tags Bu
     * @name Bu.prototype.__get__statGlobal
     * @summary Fetches hasOne relation statGlobal.
     * @request GET:/Bus/{id}/statGlobal
     */
    buPrototypeGetStatGlobal: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/Bus/${id}/statGlobal${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Bu
     * @name Bu.prototype.__create__statGlobal
     * @summary Creates a new instance in statGlobal of this model.
     * @request POST:/Bus/{id}/statGlobal
     */
    buPrototypeCreateStatGlobal: (id: string, data: StatGlobal, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/Bus/${id}/statGlobal`, "POST", params, data),

    /**
     * @tags Bu
     * @name Bu.prototype.__update__statGlobal
     * @summary Update statGlobal of this model.
     * @request PUT:/Bus/{id}/statGlobal
     */
    buPrototypeUpdateStatGlobal: (id: string, data: StatGlobal, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/Bus/${id}/statGlobal`, "PUT", params, data),

    /**
     * @tags Bu
     * @name Bu.prototype.__destroy__statGlobal
     * @summary Deletes statGlobal of this model.
     * @request DELETE:/Bus/{id}/statGlobal
     */
    buPrototypeDestroyStatGlobal: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Bus/${id}/statGlobal`, "DELETE", params),

    /**
     * @tags Bu
     * @name Bu.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Bus
     */
    buCreate: (data: Bu, params?: RequestParams) => this.request<Bu, any>(`/Bus`, "POST", params, data),

    /**
     * @tags Bu
     * @name Bu.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Bus
     */
    buPatchOrCreate: (data: Bu, params?: RequestParams) => this.request<Bu, any>(`/Bus`, "PATCH", params, data),

    /**
     * @tags Bu
     * @name Bu.replaceOrCreate__put_Bus
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Bus
     */
    buReplaceOrCreatePutBus: (data: Bu, params?: RequestParams) => this.request<Bu, any>(`/Bus`, "PUT", params, data),

    /**
     * @tags Bu
     * @name Bu.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Bus
     */
    buFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Bu[], any>(`/Bus${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Bu
     * @name Bu.replaceOrCreate__post_Bus_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Bus/replaceOrCreate
     */
    buReplaceOrCreatePostBusReplaceOrCreate: (data: Bu, params?: RequestParams) =>
      this.request<Bu, any>(`/Bus/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Bu
     * @name Bu.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Bus/upsertWithWhere
     */
    buUpsertWithWhere: (data: Bu, query?: { where?: string }, params?: RequestParams) =>
      this.request<Bu, any>(`/Bus/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Bu
     * @name Bu.exists__get_Bus_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Bus/{id}/exists
     */
    buExistsGetBusIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Bus/${id}/exists`, "GET", params),

    /**
     * @tags Bu
     * @name Bu.exists__head_Bus_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Bus/{id}
     */
    buExistsHeadBusId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Bus/${id}`, "HEAD", params),

    /**
     * @tags Bu
     * @name Bu.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Bus/{id}
     */
    buFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Bu, any>(`/Bus/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Bu
     * @name Bu.replaceById__put_Bus_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Bus/{id}
     */
    buReplaceByIdPutBusId: (id: string, data: Bu, params?: RequestParams) =>
      this.request<Bu, any>(`/Bus/${id}`, "PUT", params, data),

    /**
     * @tags Bu
     * @name Bu.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Bus/{id}
     */
    buDeleteById: (id: string, params?: RequestParams) => this.request<object, any>(`/Bus/${id}`, "DELETE", params),

    /**
     * @tags Bu
     * @name Bu.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Bus/{id}
     */
    buPrototypePatchAttributes: (id: string, data: Bu, params?: RequestParams) =>
      this.request<Bu, any>(`/Bus/${id}`, "PATCH", params, data),

    /**
     * @tags Bu
     * @name Bu.replaceById__post_Bus_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Bus/{id}/replace
     */
    buReplaceByIdPostBusIdReplace: (id: string, data: Bu, params?: RequestParams) =>
      this.request<Bu, any>(`/Bus/${id}/replace`, "POST", params, data),

    /**
     * @tags Bu
     * @name Bu.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Bus/findOne
     */
    buFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Bu, any>(`/Bus/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Bu
     * @name Bu.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Bus/update
     */
    buUpdateAll: (data: Bu, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Bus/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Bu
     * @name Bu.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Bus/count
     */
    buCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Bus/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Bu
     * @name Bu.createChangeStream__post_Bus_change-stream
     * @summary Create a change stream.
     * @request POST:/Bus/change-stream
     */
    buCreateChangeStreamPostBusChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Bus/change-stream`, "POST", params, data),

    /**
     * @tags Bu
     * @name Bu.createChangeStream__get_Bus_change-stream
     * @summary Create a change stream.
     * @request GET:/Bus/change-stream
     */
    buCreateChangeStreamGetBusChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Bus/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  codeAnalytiques = {
    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.prototype.__get__accounts
     * @summary Fetches hasOne relation accounts.
     * @request GET:/CodeAnalytiques/{id}/accounts
     */
    codeAnalytiquePrototypeGetAccounts: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/CodeAnalytiques/${id}/accounts${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.prototype.__create__accounts
     * @summary Creates a new instance in accounts of this model.
     * @request POST:/CodeAnalytiques/{id}/accounts
     */
    codeAnalytiquePrototypeCreateAccounts: (id: string, data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/CodeAnalytiques/${id}/accounts`, "POST", params, data),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.prototype.__update__accounts
     * @summary Update accounts of this model.
     * @request PUT:/CodeAnalytiques/{id}/accounts
     */
    codeAnalytiquePrototypeUpdateAccounts: (id: string, data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/CodeAnalytiques/${id}/accounts`, "PUT", params, data),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.prototype.__destroy__accounts
     * @summary Deletes accounts of this model.
     * @request DELETE:/CodeAnalytiques/{id}/accounts
     */
    codeAnalytiquePrototypeDestroyAccounts: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/CodeAnalytiques/${id}/accounts`, "DELETE", params),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.prototype.__get__statGlobal
     * @summary Fetches hasOne relation statGlobal.
     * @request GET:/CodeAnalytiques/{id}/statGlobal
     */
    codeAnalytiquePrototypeGetStatGlobal: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/CodeAnalytiques/${id}/statGlobal${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.prototype.__create__statGlobal
     * @summary Creates a new instance in statGlobal of this model.
     * @request POST:/CodeAnalytiques/{id}/statGlobal
     */
    codeAnalytiquePrototypeCreateStatGlobal: (id: string, data: StatGlobal, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/CodeAnalytiques/${id}/statGlobal`, "POST", params, data),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.prototype.__update__statGlobal
     * @summary Update statGlobal of this model.
     * @request PUT:/CodeAnalytiques/{id}/statGlobal
     */
    codeAnalytiquePrototypeUpdateStatGlobal: (id: string, data: StatGlobal, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/CodeAnalytiques/${id}/statGlobal`, "PUT", params, data),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.prototype.__destroy__statGlobal
     * @summary Deletes statGlobal of this model.
     * @request DELETE:/CodeAnalytiques/{id}/statGlobal
     */
    codeAnalytiquePrototypeDestroyStatGlobal: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/CodeAnalytiques/${id}/statGlobal`, "DELETE", params),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/CodeAnalytiques
     */
    codeAnalytiqueCreate: (data: CodeAnalytique, params?: RequestParams) =>
      this.request<CodeAnalytique, any>(`/CodeAnalytiques`, "POST", params, data),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/CodeAnalytiques
     */
    codeAnalytiquePatchOrCreate: (data: CodeAnalytique, params?: RequestParams) =>
      this.request<CodeAnalytique, any>(`/CodeAnalytiques`, "PATCH", params, data),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.replaceOrCreate__put_CodeAnalytiques
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/CodeAnalytiques
     */
    codeAnalytiqueReplaceOrCreatePutCodeAnalytiques: (data: CodeAnalytique, params?: RequestParams) =>
      this.request<CodeAnalytique, any>(`/CodeAnalytiques`, "PUT", params, data),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/CodeAnalytiques
     */
    codeAnalytiqueFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<CodeAnalytique[], any>(`/CodeAnalytiques${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.replaceOrCreate__post_CodeAnalytiques_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/CodeAnalytiques/replaceOrCreate
     */
    codeAnalytiqueReplaceOrCreatePostCodeAnalytiquesReplaceOrCreate: (data: CodeAnalytique, params?: RequestParams) =>
      this.request<CodeAnalytique, any>(`/CodeAnalytiques/replaceOrCreate`, "POST", params, data),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/CodeAnalytiques/upsertWithWhere
     */
    codeAnalytiqueUpsertWithWhere: (data: CodeAnalytique, query?: { where?: string }, params?: RequestParams) =>
      this.request<CodeAnalytique, any>(
        `/CodeAnalytiques/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.exists__get_CodeAnalytiques_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/CodeAnalytiques/{id}/exists
     */
    codeAnalytiqueExistsGetCodeAnalytiquesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/CodeAnalytiques/${id}/exists`, "GET", params),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.exists__head_CodeAnalytiques_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/CodeAnalytiques/{id}
     */
    codeAnalytiqueExistsHeadCodeAnalytiquesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/CodeAnalytiques/${id}`, "HEAD", params),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/CodeAnalytiques/{id}
     */
    codeAnalytiqueFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<CodeAnalytique, any>(`/CodeAnalytiques/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.replaceById__put_CodeAnalytiques_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/CodeAnalytiques/{id}
     */
    codeAnalytiqueReplaceByIdPutCodeAnalytiquesId: (id: string, data: CodeAnalytique, params?: RequestParams) =>
      this.request<CodeAnalytique, any>(`/CodeAnalytiques/${id}`, "PUT", params, data),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/CodeAnalytiques/{id}
     */
    codeAnalytiqueDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/CodeAnalytiques/${id}`, "DELETE", params),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/CodeAnalytiques/{id}
     */
    codeAnalytiquePrototypePatchAttributes: (id: string, data: CodeAnalytique, params?: RequestParams) =>
      this.request<CodeAnalytique, any>(`/CodeAnalytiques/${id}`, "PATCH", params, data),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.replaceById__post_CodeAnalytiques_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/CodeAnalytiques/{id}/replace
     */
    codeAnalytiqueReplaceByIdPostCodeAnalytiquesIdReplace: (id: string, data: CodeAnalytique, params?: RequestParams) =>
      this.request<CodeAnalytique, any>(`/CodeAnalytiques/${id}/replace`, "POST", params, data),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/CodeAnalytiques/findOne
     */
    codeAnalytiqueFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<CodeAnalytique, any>(`/CodeAnalytiques/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/CodeAnalytiques/update
     */
    codeAnalytiqueUpdateAll: (data: CodeAnalytique, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/CodeAnalytiques/update${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/CodeAnalytiques/count
     */
    codeAnalytiqueCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/CodeAnalytiques/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.createChangeStream__post_CodeAnalytiques_change-stream
     * @summary Create a change stream.
     * @request POST:/CodeAnalytiques/change-stream
     */
    codeAnalytiqueCreateChangeStreamPostCodeAnalytiquesChangeStream: (
      data: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/CodeAnalytiques/change-stream`, "POST", params, data),

    /**
     * @tags CodeAnalytique
     * @name CodeAnalytique.createChangeStream__get_CodeAnalytiques_change-stream
     * @summary Create a change stream.
     * @request GET:/CodeAnalytiques/change-stream
     */
    codeAnalytiqueCreateChangeStreamGetCodeAnalytiquesChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/CodeAnalytiques/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  etablissements = {
    /**
     * @tags Etablissement
     * @name Etablissement.prototype.__get__accounts
     * @summary Fetches hasOne relation accounts.
     * @request GET:/Etablissements/{id}/accounts
     */
    etablissementPrototypeGetAccounts: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Etablissements/${id}/accounts${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Etablissement
     * @name Etablissement.prototype.__create__accounts
     * @summary Creates a new instance in accounts of this model.
     * @request POST:/Etablissements/{id}/accounts
     */
    etablissementPrototypeCreateAccounts: (id: string, data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Etablissements/${id}/accounts`, "POST", params, data),

    /**
     * @tags Etablissement
     * @name Etablissement.prototype.__update__accounts
     * @summary Update accounts of this model.
     * @request PUT:/Etablissements/{id}/accounts
     */
    etablissementPrototypeUpdateAccounts: (id: string, data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Etablissements/${id}/accounts`, "PUT", params, data),

    /**
     * @tags Etablissement
     * @name Etablissement.prototype.__destroy__accounts
     * @summary Deletes accounts of this model.
     * @request DELETE:/Etablissements/{id}/accounts
     */
    etablissementPrototypeDestroyAccounts: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Etablissements/${id}/accounts`, "DELETE", params),

    /**
     * @tags Etablissement
     * @name Etablissement.prototype.__get__statGlobal
     * @summary Fetches hasOne relation statGlobal.
     * @request GET:/Etablissements/{id}/statGlobal
     */
    etablissementPrototypeGetStatGlobal: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/Etablissements/${id}/statGlobal${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Etablissement
     * @name Etablissement.prototype.__create__statGlobal
     * @summary Creates a new instance in statGlobal of this model.
     * @request POST:/Etablissements/{id}/statGlobal
     */
    etablissementPrototypeCreateStatGlobal: (id: string, data: StatGlobal, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/Etablissements/${id}/statGlobal`, "POST", params, data),

    /**
     * @tags Etablissement
     * @name Etablissement.prototype.__update__statGlobal
     * @summary Update statGlobal of this model.
     * @request PUT:/Etablissements/{id}/statGlobal
     */
    etablissementPrototypeUpdateStatGlobal: (id: string, data: StatGlobal, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/Etablissements/${id}/statGlobal`, "PUT", params, data),

    /**
     * @tags Etablissement
     * @name Etablissement.prototype.__destroy__statGlobal
     * @summary Deletes statGlobal of this model.
     * @request DELETE:/Etablissements/{id}/statGlobal
     */
    etablissementPrototypeDestroyStatGlobal: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Etablissements/${id}/statGlobal`, "DELETE", params),

    /**
     * @tags Etablissement
     * @name Etablissement.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Etablissements
     */
    etablissementCreate: (data: Etablissement, params?: RequestParams) =>
      this.request<Etablissement, any>(`/Etablissements`, "POST", params, data),

    /**
     * @tags Etablissement
     * @name Etablissement.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Etablissements
     */
    etablissementPatchOrCreate: (data: Etablissement, params?: RequestParams) =>
      this.request<Etablissement, any>(`/Etablissements`, "PATCH", params, data),

    /**
     * @tags Etablissement
     * @name Etablissement.replaceOrCreate__put_Etablissements
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Etablissements
     */
    etablissementReplaceOrCreatePutEtablissements: (data: Etablissement, params?: RequestParams) =>
      this.request<Etablissement, any>(`/Etablissements`, "PUT", params, data),

    /**
     * @tags Etablissement
     * @name Etablissement.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Etablissements
     */
    etablissementFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Etablissement[], any>(`/Etablissements${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Etablissement
     * @name Etablissement.replaceOrCreate__post_Etablissements_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Etablissements/replaceOrCreate
     */
    etablissementReplaceOrCreatePostEtablissementsReplaceOrCreate: (data: Etablissement, params?: RequestParams) =>
      this.request<Etablissement, any>(`/Etablissements/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Etablissement
     * @name Etablissement.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Etablissements/upsertWithWhere
     */
    etablissementUpsertWithWhere: (data: Etablissement, query?: { where?: string }, params?: RequestParams) =>
      this.request<Etablissement, any>(
        `/Etablissements/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags Etablissement
     * @name Etablissement.exists__get_Etablissements_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Etablissements/{id}/exists
     */
    etablissementExistsGetEtablissementsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Etablissements/${id}/exists`, "GET", params),

    /**
     * @tags Etablissement
     * @name Etablissement.exists__head_Etablissements_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Etablissements/{id}
     */
    etablissementExistsHeadEtablissementsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Etablissements/${id}`, "HEAD", params),

    /**
     * @tags Etablissement
     * @name Etablissement.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Etablissements/{id}
     */
    etablissementFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Etablissement, any>(`/Etablissements/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Etablissement
     * @name Etablissement.replaceById__put_Etablissements_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Etablissements/{id}
     */
    etablissementReplaceByIdPutEtablissementsId: (id: string, data: Etablissement, params?: RequestParams) =>
      this.request<Etablissement, any>(`/Etablissements/${id}`, "PUT", params, data),

    /**
     * @tags Etablissement
     * @name Etablissement.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Etablissements/{id}
     */
    etablissementDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Etablissements/${id}`, "DELETE", params),

    /**
     * @tags Etablissement
     * @name Etablissement.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Etablissements/{id}
     */
    etablissementPrototypePatchAttributes: (id: string, data: Etablissement, params?: RequestParams) =>
      this.request<Etablissement, any>(`/Etablissements/${id}`, "PATCH", params, data),

    /**
     * @tags Etablissement
     * @name Etablissement.replaceById__post_Etablissements_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Etablissements/{id}/replace
     */
    etablissementReplaceByIdPostEtablissementsIdReplace: (id: string, data: Etablissement, params?: RequestParams) =>
      this.request<Etablissement, any>(`/Etablissements/${id}/replace`, "POST", params, data),

    /**
     * @tags Etablissement
     * @name Etablissement.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Etablissements/findOne
     */
    etablissementFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Etablissement, any>(`/Etablissements/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Etablissement
     * @name Etablissement.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Etablissements/update
     */
    etablissementUpdateAll: (data: Etablissement, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Etablissements/update${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags Etablissement
     * @name Etablissement.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Etablissements/count
     */
    etablissementCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Etablissements/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Etablissement
     * @name Etablissement.createChangeStream__post_Etablissements_change-stream
     * @summary Create a change stream.
     * @request POST:/Etablissements/change-stream
     */
    etablissementCreateChangeStreamPostEtablissementsChangeStream: (
      data: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/Etablissements/change-stream`, "POST", params, data),

    /**
     * @tags Etablissement
     * @name Etablissement.createChangeStream__get_Etablissements_change-stream
     * @summary Create a change stream.
     * @request GET:/Etablissements/change-stream
     */
    etablissementCreateChangeStreamGetEtablissementsChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/Etablissements/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  statistiques = {
    /**
     * @tags Statistiques
     * @name Statistiques.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/Statistiques/{id}/account
     */
    statistiquesPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Statistiques/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Statistiques
     * @name Statistiques.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Statistiques
     */
    statistiquesCreate: (data: Statistiques, params?: RequestParams) =>
      this.request<Statistiques, any>(`/Statistiques`, "POST", params, data),

    /**
     * @tags Statistiques
     * @name Statistiques.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Statistiques
     */
    statistiquesPatchOrCreate: (data: Statistiques, params?: RequestParams) =>
      this.request<Statistiques, any>(`/Statistiques`, "PATCH", params, data),

    /**
     * @tags Statistiques
     * @name Statistiques.replaceOrCreate__put_Statistiques
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Statistiques
     */
    statistiquesReplaceOrCreatePutStatistiques: (data: Statistiques, params?: RequestParams) =>
      this.request<Statistiques, any>(`/Statistiques`, "PUT", params, data),

    /**
     * @tags Statistiques
     * @name Statistiques.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Statistiques
     */
    statistiquesFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Statistiques[], any>(`/Statistiques${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Statistiques
     * @name Statistiques.replaceOrCreate__post_Statistiques_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Statistiques/replaceOrCreate
     */
    statistiquesReplaceOrCreatePostStatistiquesReplaceOrCreate: (data: Statistiques, params?: RequestParams) =>
      this.request<Statistiques, any>(`/Statistiques/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Statistiques
     * @name Statistiques.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Statistiques/upsertWithWhere
     */
    statistiquesUpsertWithWhere: (data: Statistiques, query?: { where?: string }, params?: RequestParams) =>
      this.request<Statistiques, any>(
        `/Statistiques/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags Statistiques
     * @name Statistiques.exists__get_Statistiques_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Statistiques/{id}/exists
     */
    statistiquesExistsGetStatistiquesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Statistiques/${id}/exists`, "GET", params),

    /**
     * @tags Statistiques
     * @name Statistiques.exists__head_Statistiques_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Statistiques/{id}
     */
    statistiquesExistsHeadStatistiquesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Statistiques/${id}`, "HEAD", params),

    /**
     * @tags Statistiques
     * @name Statistiques.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Statistiques/{id}
     */
    statistiquesFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Statistiques, any>(`/Statistiques/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Statistiques
     * @name Statistiques.replaceById__put_Statistiques_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Statistiques/{id}
     */
    statistiquesReplaceByIdPutStatistiquesId: (id: string, data: Statistiques, params?: RequestParams) =>
      this.request<Statistiques, any>(`/Statistiques/${id}`, "PUT", params, data),

    /**
     * @tags Statistiques
     * @name Statistiques.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Statistiques/{id}
     */
    statistiquesDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Statistiques/${id}`, "DELETE", params),

    /**
     * @tags Statistiques
     * @name Statistiques.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Statistiques/{id}
     */
    statistiquesPrototypePatchAttributes: (id: string, data: Statistiques, params?: RequestParams) =>
      this.request<Statistiques, any>(`/Statistiques/${id}`, "PATCH", params, data),

    /**
     * @tags Statistiques
     * @name Statistiques.replaceById__post_Statistiques_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Statistiques/{id}/replace
     */
    statistiquesReplaceByIdPostStatistiquesIdReplace: (id: string, data: Statistiques, params?: RequestParams) =>
      this.request<Statistiques, any>(`/Statistiques/${id}/replace`, "POST", params, data),

    /**
     * @tags Statistiques
     * @name Statistiques.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Statistiques/findOne
     */
    statistiquesFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Statistiques, any>(`/Statistiques/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Statistiques
     * @name Statistiques.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Statistiques/update
     */
    statistiquesUpdateAll: (data: Statistiques, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Statistiques/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Statistiques
     * @name Statistiques.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Statistiques/count
     */
    statistiquesCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Statistiques/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Statistiques
     * @name Statistiques.createChangeStream__post_Statistiques_change-stream
     * @summary Create a change stream.
     * @request POST:/Statistiques/change-stream
     */
    statistiquesCreateChangeStreamPostStatistiquesChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Statistiques/change-stream`, "POST", params, data),

    /**
     * @tags Statistiques
     * @name Statistiques.createChangeStream__get_Statistiques_change-stream
     * @summary Create a change stream.
     * @request GET:/Statistiques/change-stream
     */
    statistiquesCreateChangeStreamGetStatistiquesChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Statistiques/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  statGlobals = {
    /**
     * @tags StatGlobal
     * @name StatGlobal.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/StatGlobals/{id}/account
     */
    statGlobalPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/StatGlobals/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags StatGlobal
     * @name StatGlobal.prototype.__get__bu
     * @summary Fetches belongsTo relation bu.
     * @request GET:/StatGlobals/{id}/bu
     */
    statGlobalPrototypeGetBu: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Bu, any>(`/StatGlobals/${id}/bu${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags StatGlobal
     * @name StatGlobal.prototype.__get__societe
     * @summary Fetches belongsTo relation societe.
     * @request GET:/StatGlobals/{id}/societe
     */
    statGlobalPrototypeGetSociete: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Societe, any>(`/StatGlobals/${id}/societe${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags StatGlobal
     * @name StatGlobal.prototype.__get__etablissement
     * @summary Fetches belongsTo relation etablissement.
     * @request GET:/StatGlobals/{id}/etablissement
     */
    statGlobalPrototypeGetEtablissement: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Etablissement, any>(`/StatGlobals/${id}/etablissement${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags StatGlobal
     * @name StatGlobal.prototype.__get__codeAnalytique
     * @summary Fetches belongsTo relation codeAnalytique.
     * @request GET:/StatGlobals/{id}/codeAnalytique
     */
    statGlobalPrototypeGetCodeAnalytique: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<CodeAnalytique, any>(
        `/StatGlobals/${id}/codeAnalytique${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags StatGlobal
     * @name StatGlobal.prototype.__get__statIndicateur
     * @summary Fetches belongsTo relation statIndicateur.
     * @request GET:/StatGlobals/{id}/statIndicateur
     */
    statGlobalPrototypeGetStatIndicateur: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<StatIndicateur, any>(
        `/StatGlobals/${id}/statIndicateur${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags StatGlobal
     * @name StatGlobal.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/StatGlobals
     */
    statGlobalCreate: (data: StatGlobal, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/StatGlobals`, "POST", params, data),

    /**
     * @tags StatGlobal
     * @name StatGlobal.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/StatGlobals
     */
    statGlobalPatchOrCreate: (data: StatGlobal, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/StatGlobals`, "PATCH", params, data),

    /**
     * @tags StatGlobal
     * @name StatGlobal.replaceOrCreate__put_StatGlobals
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/StatGlobals
     */
    statGlobalReplaceOrCreatePutStatGlobals: (data: StatGlobal, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/StatGlobals`, "PUT", params, data),

    /**
     * @tags StatGlobal
     * @name StatGlobal.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/StatGlobals
     */
    statGlobalFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<StatGlobal[], any>(`/StatGlobals${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags StatGlobal
     * @name StatGlobal.replaceOrCreate__post_StatGlobals_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/StatGlobals/replaceOrCreate
     */
    statGlobalReplaceOrCreatePostStatGlobalsReplaceOrCreate: (data: StatGlobal, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/StatGlobals/replaceOrCreate`, "POST", params, data),

    /**
     * @tags StatGlobal
     * @name StatGlobal.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/StatGlobals/upsertWithWhere
     */
    statGlobalUpsertWithWhere: (data: StatGlobal, query?: { where?: string }, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/StatGlobals/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags StatGlobal
     * @name StatGlobal.exists__get_StatGlobals_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/StatGlobals/{id}/exists
     */
    statGlobalExistsGetStatGlobalsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/StatGlobals/${id}/exists`, "GET", params),

    /**
     * @tags StatGlobal
     * @name StatGlobal.exists__head_StatGlobals_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/StatGlobals/{id}
     */
    statGlobalExistsHeadStatGlobalsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/StatGlobals/${id}`, "HEAD", params),

    /**
     * @tags StatGlobal
     * @name StatGlobal.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/StatGlobals/{id}
     */
    statGlobalFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/StatGlobals/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags StatGlobal
     * @name StatGlobal.replaceById__put_StatGlobals_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/StatGlobals/{id}
     */
    statGlobalReplaceByIdPutStatGlobalsId: (id: string, data: StatGlobal, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/StatGlobals/${id}`, "PUT", params, data),

    /**
     * @tags StatGlobal
     * @name StatGlobal.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/StatGlobals/{id}
     */
    statGlobalDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/StatGlobals/${id}`, "DELETE", params),

    /**
     * @tags StatGlobal
     * @name StatGlobal.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/StatGlobals/{id}
     */
    statGlobalPrototypePatchAttributes: (id: string, data: StatGlobal, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/StatGlobals/${id}`, "PATCH", params, data),

    /**
     * @tags StatGlobal
     * @name StatGlobal.replaceById__post_StatGlobals_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/StatGlobals/{id}/replace
     */
    statGlobalReplaceByIdPostStatGlobalsIdReplace: (id: string, data: StatGlobal, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/StatGlobals/${id}/replace`, "POST", params, data),

    /**
     * @tags StatGlobal
     * @name StatGlobal.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/StatGlobals/findOne
     */
    statGlobalFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/StatGlobals/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags StatGlobal
     * @name StatGlobal.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/StatGlobals/update
     */
    statGlobalUpdateAll: (data: StatGlobal, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/StatGlobals/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags StatGlobal
     * @name StatGlobal.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/StatGlobals/count
     */
    statGlobalCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/StatGlobals/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags StatGlobal
     * @name StatGlobal.createChangeStream__post_StatGlobals_change-stream
     * @summary Create a change stream.
     * @request POST:/StatGlobals/change-stream
     */
    statGlobalCreateChangeStreamPostStatGlobalsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/StatGlobals/change-stream`, "POST", params, data),

    /**
     * @tags StatGlobal
     * @name StatGlobal.createChangeStream__get_StatGlobals_change-stream
     * @summary Create a change stream.
     * @request GET:/StatGlobals/change-stream
     */
    statGlobalCreateChangeStreamGetStatGlobalsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/StatGlobals/change-stream${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags StatGlobal
     * @name StatGlobal.getStatistiques
     * @summary get Statistiques
     * @request POST:/StatGlobals/getStatistiques
     */
    statGlobalGetStatistiques: (data: { filter: string }, params?: RequestParams) =>
      this.request<object, any>(`/StatGlobals/getStatistiques`, "POST", params, data),
  };
  statIndicateurs = {
    /**
     * @tags StatIndicateur
     * @name StatIndicateur.prototype.__findById__statGlobal
     * @summary Find a related item by id for statGlobal.
     * @request GET:/StatIndicateurs/{id}/statGlobal/{fk}
     */
    statIndicateurPrototypeFindByIdStatGlobal: (id: string, fk: string, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/StatIndicateurs/${id}/statGlobal/${fk}`, "GET", params),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.prototype.__destroyById__statGlobal
     * @summary Delete a related item by id for statGlobal.
     * @request DELETE:/StatIndicateurs/{id}/statGlobal/{fk}
     */
    statIndicateurPrototypeDestroyByIdStatGlobal: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/StatIndicateurs/${id}/statGlobal/${fk}`, "DELETE", params),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.prototype.__updateById__statGlobal
     * @summary Update a related item by id for statGlobal.
     * @request PUT:/StatIndicateurs/{id}/statGlobal/{fk}
     */
    statIndicateurPrototypeUpdateByIdStatGlobal: (id: string, fk: string, data: StatGlobal, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/StatIndicateurs/${id}/statGlobal/${fk}`, "PUT", params, data),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.prototype.__get__statGlobal
     * @summary Queries statGlobal of StatIndicateur.
     * @request GET:/StatIndicateurs/{id}/statGlobal
     */
    statIndicateurPrototypeGetStatGlobal: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<StatGlobal[], any>(`/StatIndicateurs/${id}/statGlobal${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.prototype.__create__statGlobal
     * @summary Creates a new instance in statGlobal of this model.
     * @request POST:/StatIndicateurs/{id}/statGlobal
     */
    statIndicateurPrototypeCreateStatGlobal: (id: string, data: StatGlobal, params?: RequestParams) =>
      this.request<StatGlobal, any>(`/StatIndicateurs/${id}/statGlobal`, "POST", params, data),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.prototype.__delete__statGlobal
     * @summary Deletes all statGlobal of this model.
     * @request DELETE:/StatIndicateurs/{id}/statGlobal
     */
    statIndicateurPrototypeDeleteStatGlobal: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/StatIndicateurs/${id}/statGlobal`, "DELETE", params),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.prototype.__count__statGlobal
     * @summary Counts statGlobal of StatIndicateur.
     * @request GET:/StatIndicateurs/{id}/statGlobal/count
     */
    statIndicateurPrototypeCountStatGlobal: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/StatIndicateurs/${id}/statGlobal/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/StatIndicateurs
     */
    statIndicateurCreate: (data: StatIndicateur, params?: RequestParams) =>
      this.request<StatIndicateur, any>(`/StatIndicateurs`, "POST", params, data),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/StatIndicateurs
     */
    statIndicateurPatchOrCreate: (data: StatIndicateur, params?: RequestParams) =>
      this.request<StatIndicateur, any>(`/StatIndicateurs`, "PATCH", params, data),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.replaceOrCreate__put_StatIndicateurs
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/StatIndicateurs
     */
    statIndicateurReplaceOrCreatePutStatIndicateurs: (data: StatIndicateur, params?: RequestParams) =>
      this.request<StatIndicateur, any>(`/StatIndicateurs`, "PUT", params, data),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/StatIndicateurs
     */
    statIndicateurFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<StatIndicateur[], any>(`/StatIndicateurs${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.replaceOrCreate__post_StatIndicateurs_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/StatIndicateurs/replaceOrCreate
     */
    statIndicateurReplaceOrCreatePostStatIndicateursReplaceOrCreate: (data: StatIndicateur, params?: RequestParams) =>
      this.request<StatIndicateur, any>(`/StatIndicateurs/replaceOrCreate`, "POST", params, data),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/StatIndicateurs/upsertWithWhere
     */
    statIndicateurUpsertWithWhere: (data: StatIndicateur, query?: { where?: string }, params?: RequestParams) =>
      this.request<StatIndicateur, any>(
        `/StatIndicateurs/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.exists__get_StatIndicateurs_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/StatIndicateurs/{id}/exists
     */
    statIndicateurExistsGetStatIndicateursIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/StatIndicateurs/${id}/exists`, "GET", params),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.exists__head_StatIndicateurs_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/StatIndicateurs/{id}
     */
    statIndicateurExistsHeadStatIndicateursId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/StatIndicateurs/${id}`, "HEAD", params),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/StatIndicateurs/{id}
     */
    statIndicateurFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<StatIndicateur, any>(`/StatIndicateurs/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.replaceById__put_StatIndicateurs_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/StatIndicateurs/{id}
     */
    statIndicateurReplaceByIdPutStatIndicateursId: (id: string, data: StatIndicateur, params?: RequestParams) =>
      this.request<StatIndicateur, any>(`/StatIndicateurs/${id}`, "PUT", params, data),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/StatIndicateurs/{id}
     */
    statIndicateurDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/StatIndicateurs/${id}`, "DELETE", params),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/StatIndicateurs/{id}
     */
    statIndicateurPrototypePatchAttributes: (id: string, data: StatIndicateur, params?: RequestParams) =>
      this.request<StatIndicateur, any>(`/StatIndicateurs/${id}`, "PATCH", params, data),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.replaceById__post_StatIndicateurs_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/StatIndicateurs/{id}/replace
     */
    statIndicateurReplaceByIdPostStatIndicateursIdReplace: (id: string, data: StatIndicateur, params?: RequestParams) =>
      this.request<StatIndicateur, any>(`/StatIndicateurs/${id}/replace`, "POST", params, data),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/StatIndicateurs/findOne
     */
    statIndicateurFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<StatIndicateur, any>(`/StatIndicateurs/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/StatIndicateurs/update
     */
    statIndicateurUpdateAll: (data: StatIndicateur, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/StatIndicateurs/update${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/StatIndicateurs/count
     */
    statIndicateurCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/StatIndicateurs/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.createChangeStream__post_StatIndicateurs_change-stream
     * @summary Create a change stream.
     * @request POST:/StatIndicateurs/change-stream
     */
    statIndicateurCreateChangeStreamPostStatIndicateursChangeStream: (
      data: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/StatIndicateurs/change-stream`, "POST", params, data),

    /**
     * @tags StatIndicateur
     * @name StatIndicateur.createChangeStream__get_StatIndicateurs_change-stream
     * @summary Create a change stream.
     * @request GET:/StatIndicateurs/change-stream
     */
    statIndicateurCreateChangeStreamGetStatIndicateursChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/StatIndicateurs/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
}
