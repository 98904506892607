import React from "react";
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    DateTimeInput,
    DeleteButton,
    Edit,
    EditButton,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    useTranslate,
} from "react-admin";
import {AccountType} from "../react-admin-loopback/api/shared-constants";
import BackButton from "../components/BackButton";
import {makeStyles} from "@material-ui/core/styles";
import {visibility} from "html2canvas/dist/types/css/property-descriptors/visibility";

const useStyles = makeStyles({
    metaDataHeader: {width: '250px'},
});

const InAppNotificationFilter = (props: any) => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <ReferenceInput
                source="accountId"
                perPage={null}
                reference="Accounts"
                sort={{field: "nom", order: "ASC"}}
                alwaysOn
                filter={{type: AccountType.CONDUCTEUR}}
            >
                <SelectInput optionText="nom"/>
                {/*<AutocompleteInput optionText="nom"/>*/}
            </ReferenceInput>
            {/*<SelectInput source="date"  alwaysOn/>*/}
        </Filter>
    );
};

export const CustomForm = (props) => {
    const translate = useTranslate();
    // const [messageTypes, setMessageTypes] = useState();
    // useEffect(() => {
    //   const lbtoken = localStorage.getItem("lbtoken");
    //   const token = lbtoken ? JSON.parse(lbtoken).value?.id : undefined;
    //   fetch(`${API_URL}/InAppNotifications/messageTypes`, {
    //      method: 'GET',
    //      headers: {
    //       authorization: token,
    //     },

    //   })
    //   .then(response => response.json())
    //   .then(data => {
    //       let messageTypes = [] as any;
    //       data.forEach(myFunction);
    //       function myFunction(value) {
    //         messageTypes.push({id: value, name: value})
    //       }
    //       setMessageTypes(messageTypes);
    //   })
    //   .catch((error) => {
    //     console.error('Error:', error);
    //   });

    // }, []);
    return (
        <div className="w-100">
            <div className="d-flex flex-wrap">
                <div className="col-12 col-sm-6">
                    <ReferenceInput
                        source="accountId"
                        reference="Accounts"
                        perPage={null}
                        sort={{field: "nom", order: "ASC"}}
                        label={translate("commons.driver")}
                        filter={{type: AccountType.CONDUCTEUR}}
                        fullWidth>
                        <SelectInput optionText="nom"/>
                    </ReferenceInput>
                </div>
                <div className="col-12 col-sm-6">
                    <ReferenceInput
                        source="messageType"
                        reference="InAppNotifications/messageTypes"
                        label={translate("commons.messageType")}
                        perPage={null}
                        fullWidth>
                        <SelectInput optionText="messageType" optionValue="messageType"/>
                    </ReferenceInput>
                </div>
                {/* <div className="col-12 col-sm-6">
        <SelectInput translateChoice={false} source="messageType" choices={messageTypes} fullWidth/>
        </div> */}
                <div className="col-12 col-sm-6">
                    <TextInput
                        source="message"
                        label={translate("commons.message")}
                        fullWidth
                    />
                </div>
                {/*<div className="col-12 col-sm-6">*/}
                {/*    /!*<NullableBooleanInput source="isRead" label={translate('commons.isRead')}*!/*/}
                {/*    /!*keynullLabel="None"*!/*/}
                {/*    /!*falseLabel="True"*!/*/}
                {/*    /!*trueLabel="False" fullWidth/>*!/*/}
                {/*    <BooleanInput label={translate("commons.isRead")} source="isRead"/>*/}
                {/*</div>*/}
                <div className="col-12 col-sm-6">
                    <TextInput
                        source="nomNotification"
                        label={translate("commons.nomNotification")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput
                        source="resumeNotification"
                        label={translate("commons.resumeNotification")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6" style={{visibility: 'hidden'}}>
                    <DateTimeInput
                        source="dateNotification"
                        label={translate("commons.dateNotification")}
                        initialValue={new Date()}
                        disable={true}
                        fullWidth
                    />
                </div>
            </div>
        </div>
    );
};
const CustomMetaDataField = (props) => {
    const classes = useStyles();
    return (
        <div
            className={classes.metaDataHeader}>{props?.record?.metaData && JSON.stringify(props?.record?.metaData, null, 4)}</div>
    )
}
export const InAppNotificationList = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <List {...props} filters={<InAppNotificationFilter/>} exporter={false}>
            <Datagrid>
                <TextField source="id"/>
                <ReferenceField source="accountId" reference="Accounts">
                    <TextField source="nom" label={translate("commons.driver")}/>
                </ReferenceField>
                <TextField
                    source="messageType"
                    label={translate("commons.messageType")}
                />
                <TextField source="message" label={translate("commons.message")}/>
                <BooleanField source="isRead" label={translate("commons.isRead")}/>
                <CustomMetaDataField
                    source="metaData"
                    label={translate("commons.metaData")}/>
                <TextField
                    source="nomNotification"
                    label={translate("commons.nomNotification")}
                />
                <TextField
                    source="resumeNotification"
                    label={translate("commons.resumeNotification")}
                />
                <DateField
                    source="dateNotification"
                    label={translate("commons.dateNotification")}
                    showTime
                />
                <DateField
                    source="dateLecture"
                    label={translate("commons.dateLecture")}
                    showTime
                />
                <DateField
                    source="heureLecture"
                    label={translate("commons.driver")}
                    showTime
                />
                <EditButton/>
                <DeleteButton/>
            </Datagrid>
        </List>
    );
};

export const InAppNotificationEdit = (props: any) => {
    const translate = useTranslate();
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                {/* <Link to="/RHs" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
                <BackButton/>
                <h3>{translate("commons.editNotification")}</h3>
                <b></b>
            </div>
            <Edit {...props}>
                <SimpleForm>
                    <CustomForm/>
                </SimpleForm>
            </Edit>
        </div>
    );
};
export const InAppNotificationCreate = (props: any) => {
    const translate = useTranslate();
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                {/* <Link to="/RHs" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
                <BackButton/>
                <h3>{translate("commons.newNotification")}</h3>
                <b></b>
            </div>
            <Create {...props}>
                <SimpleForm redirect="/InAppNotifications">
                    <CustomForm/>
                </SimpleForm>
            </Create>
        </div>
    );
};
