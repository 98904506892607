import { position } from "html2canvas/dist/types/css/property-descriptors/position";
import { TranslationMessages } from "ra-core";
import frenchMessages from "ra-language-french";

const customFrenchMessages: TranslationMessages = {
  ...frenchMessages,
  col: {
    list: {
      firstName: "Nom",
      name: "Name",
      address: "Address",
      city: "City",
      lastName: "Prénom",
      role: "Rôle",
      filiale: "Filiale",
      email: "Email",
      password: "Password",
      confirmPassword: "Confirm Password",
    },
  },
  button: {
    back: "Retour",
    add: "Add",
    edit: "Edit",
    save: "Sauvegarder",
  },
  adminitrastor: {
    editadmin: "Edit Adminstration",
    createadmin: "Create an Adminstration",
  },
  menu: {
    account: "COMPTES",
    company: "SOCIÉTÉS",
    service: "SERVICES",
    assurances: "ASSURANCES",
    contacts: "CONTACTS",
    photos: "PHOTOS",
    medias: "MÉDIAS",
    parameters: "PARAMÈTRES",
    model: "MODÈLE",
    reseve: "RESERVES",
    etat: "ETAT DE LIEUX",
    mission: "MISSIONS",
    etape: "ETAPES",
    horaises: "HORAISES",
    CMR: "CMR",
    DN: "DN",
    appNotifications: "APP NOTIFICATIONS",
    moteur: "MOTEURS",
    tractes: "TRACTES",
    verify: "VERIFICATIONS",
    temp: "TEMPS",
    pleinCarburants: "PLEIN DE CARBURANT",
    position: "POSITION",
    historyvieprivee: "HISTO VIE PRIVEE",
    guide: "Guide de l'application",
    guideConducteur1: "Guide qualité 1",
    guideConducteur2: "Guide qualité 2",
    guideConducteur3: "Guide qualité 3",
    installation: "Installation",
    appversion: "App Version",
  },
  password: {
    oldPassword: "Mot de passe actuel",
    newPassword: "Nouveau mot de passe",
    confirmPassword: "Confirmation du nouveau mot de passe",
    changePassword: "Modifier le mot de passe",
  },
  alert: {
    min: "longueur minimale: 8",
    max: "longueur maximale: 100",
    uppercase: "doit contenir des lettres majuscules",
    lowercase: "doit contenir des lettres minuscules",
    digits: "doit contenir au moins 2 chiffres",
    manyRequests:
      "Vous avez essayé de vous connecter trop de fois. Veuillez réessayer dans %{time} minutes",
    newPasswordDifferentOld: `Le nouveau mot de passe doit être différent de l'ancien mot de passe`,
    internalServerError: "Erreur Interne du Serveur",
    invalidPassword: "Le mot de passe actuel est incorrect",
    confirmPasswordNotSame:
      "La confirmation du nouveau de passe est incorrecte",
  },
  title: "Connect BackOffice",
  commons: {
    name: "Nom",
    firstName: "Prénom",
    username: "Nom d'utilisateur",
    email: "Email",
    type: "Type",
    text: "Text",
    roles: "Rôle",
    cancel: "ANNULER",
    confirm: "Confirmer",
    deleteTitle: "Supprimer Etape",
    items: "élément(s)",
    confirmDelete: "Êtes-vous sûr(e) de vouloir supprimer cet élément ?",
    service: "Service",
    services: "Services",
    societe: "Société",
    societes: "Sociétés",
    parameter: "Paramètre",
    modele: "Modèle",
    photos: "Photos",
    createdAt: "Créé à",
    updatedAt: "Mis à jour à",
    edit: "Éditer",
    delete: "Supprimer",
    google_meet: "Google Meet",
    link_url: "URL du lien",
    send: "Envoyer",
    logo: "Logo",
    data: "Données",
    post: "Poste",
    author: "Auteur",
    media: "Média",
    description: "Description",
    passwordNotMatch: "Les mots de passe ne sont pas identiques!",
    password: "Mot de passe",
    confirmPassword: "Confirmer le mot de passe",
    accessTokenGmail: "Jeton d'accès gmail",
    refreshTokenGmail: "Actualiser le jeton gmail",
    cookie: "Cookie",
    add: "Adjoute",
    uploadLogo: "Téléchargez un logo",
    uploadImage: "Téléchargez un image",
    driver: "Conducteur",
    object: "Objet",
    message: "Message",
    principalType: "Principal Type",
    principal: "Principal",
    value: "Valeur",
    serialNumber: "Numéro de série",
    viewForm: "Voir formulaire",
    tripId: "Trip ID",
    conducteurId: "Conducteur ID",
    informationTrip: "Information of trip",
    image: "Image",
    note: "Note",
    problem: "Problème",
    contact: "Contact",
    lieu: "Lieu",
    general: "Générale",
    dataNotValid: "Les données ne sont pas disponibles",
    ot: "Ot",
    moteur: "Moteur",
    tracte: "Tracte",
    pays: "Pays",
    departement: "Departement",
    codePostal: "Code Postal",
    city: "Ville",
    addresse: "Adresse",
    contrainte: "Contrainte",
    particularite: "Particularite",
    client: "Client",
    refClient: "Ref client",
    ref2: "Ref 2",
    ref3: "Ref 3",
    date: "Date",
    heureDebut: "Heure debut",
    heurePrevu: "Heure prevu",
    heureCharge: "Heure chargement",
    heureArrive: "Heure arrive",
    heureFin: "Heure fin",
    metreLineaire: "Metre lineaire",
    poidsKg: "Poids kg",
    nbPal: "Nb pal",
    mission: "Mission",
    duration: "Duree",
    acitivity: "Activity",
    etape: "Etape",
    etapes: "Etapes",
    activity: "Activity",
    contactLink: "Hangouts",
    texte: "Texte",
    notification: "Notification",
    CMRno: "CMR no",
    DNno: "DN no",
    latitude: "Latitude",
    longitude: "Longitude",
    timeStamp: "Timestamp",
    hdop: "Hdop",
    altitude: "Altitude",
    speed: "Speed",
    unit: "Unit",
    quantity: "Quantity",
    quantityReelle: "quantityReelle",
    action: "Action",
    appelPersonnel: "Appel personnel",
    SOS: "SOS",
    language: "Langue",
    link: "Lien",
    versionName: "Nom de la version",
    versionCode: "Code de version",
    repertoire: "Repertoire",
    empty: "Vide",
    updateTMS: "Mise à jour timestamp",
    changelogs: "Journaux de modification",
    rdvDebut: "rdvDebutn",
    nomTypeDetape: "nomTypeDetape",
    newNomTypeDetape: "newNomTypeDetape",
    nature: "nature",
    statut: "statut",
    rdvFin: "rdvFin",
    heureArriveReelle: "heure ArriveReelle",
    heureDebutReelle: "heure DebutReelle",
    heureFinReelle: "heure FinReelle",
    saisiearrivesite: "saisie Arrivesite",
    saisiedebutetape: "saisie Debutetape",
    saisiefinetape: "saisie Finetape",
    raisonSociale: "raison Sociale",
    dateHeureCmr: "date Heure Cmr",
    dateFinReelle: "date FinReelle",
    ville: "ville",
    eta: "eta",
    tracteParcTracteReel: "tracteParc TracteReel",
    parcMoteur: "parc Moteur",
    matImMoteur: "mat ImMoteur",
    kilometrage: "kilometrage",
    consommation: "consommation",
    vitesseMoy: "vitesseMoy",
    tempsMoteur: "tempss Moteur",
    tempsRoute: "temps Route",
    tempsRalenti: "temps Ralenti",
    tempsSpecif: "tempsSpecif",
    nivCarburant: "nivCarburant (%)",
    donneeSpecif: "donneeSpecif",
    typeMoteur: "type Moteur",
    autonomie: "autonomie",
    prisePoste: "prise Poste",
    finPoste: "fin Poste",
    montant: "montant",
    updatefrais: "update Frais",
    majoration: "majoration",
    activation: "activation",
    codePostalReelle: "codePostal Reelle",
    majorationReelle: "majoration Reelle",
    villeReelle: "ville Reelle",
    paysReelle: "pays Reelle",
    montantReelle: "montant Reelle",
    activationReelle: "activation Reelle",
    updateuser: "update User",
    typeReelle: "type Reelle",
    messageType: "messageType",
    isRead: "isRead",
    metaData: "metaData",
    nomNotification: "nom Notification",
    resumeNotification: "resume Notification",
    dateNotification: "date Notification",
    heureNotification: "heure Notification",
    newNotification: "Nouvelle notification",
    editNotification: "Éditer notification",
    dateLecture: "date Lecture",
    heureLecture: "heure Lecture",
    autreStation: "Autre Station",
    document: "Document",
    echangepalreel: "Echangepalreel",
    echangepal: "Echangepal",
    gestionpalettes: "Gestion  Palettes",
    missionId: "Mission Id",
    etapesDone: "Etapes terminées",
    etapesUndone: "Etapes non terminées",
  },
  tabs: {
    driver: "Conducteur",
    admin: "Administration",
    personnel: "Personnel",
    externe: "Externe",
  },
  account: {
    information: "Informations sur le compte",
    driver: "Conducteur",
    admin: "Administrateur",
    editAccount: "Modifier un compte",
    createAccount: "Créer un compte",
  },
  societe: {
    editSociete: "Modifier une société",
    createSociete: "Créer une société",
  },
  service: {
    editService: "Modifier un service",
    createService: "Créer un service",
  },
  assurance: {
    editAssurance: "Modifier une assurance",
    createAssurance: "Créer une assurance",
  },
  photos: {
    editPhotos: "Modifier un album",
    createPhotos: "Créer un album",
  },
  media: {
    editMedia: "Modifier un média",
    createMedia: "Créer un média",
    dropMusic:
      "Déposez une musique à télécharger, ou cliquez pour la sélectionner.",
    dropFilm:
      "Déposez une film à télécharger, ou cliquez pour la sélectionner.",
    noMedia: "Aucun type de média sélectionné",
    uploadFilm: "Télécharger un film",
    uploadMusic: "Télécharger un musique",
    previewMedia: "Avant première médias",
    uploadMedia: "Charger de nouveaux médias",
  },
  parameter: {
    editParameter: "Modifier un paramètre",
    createParameter: "Créer un paramètre ",
  },
  contact: {
    editContact: "Modifier un contact",
    createContact: "Créer un contact",
  },
  modele: {
    editModele: "Modifier un modèle",
    createModele: "Créer un modèle",
  },
  reseve: {
    editReseve: "Modifier un reseve",
    createReseve: "Créer un reseve",
  },
  formulaire: {
    title: "FICHE DECLARATION ACCIDENT DE LA CIRCULATION",
    subTitle:
      "Document à compléter dans les 48 heures de l’accident à faire suivre à votre service assurances",
    nameSociete: "Nom de la société :",
    driver: "LE CONDUCTEUR",
    name: "Nom: ",
    firstName: "Prénom: ",
    engineRegistration: "Immatriculation (ou parc) du moteur :",
    registrationVehicle: "Immatriculation (ou parc) du tracté :",
    vehicle: "LES VEHICULES",
    accident: "L’ACCIDENT",
    date: "Date :",
    locality: "Localité: ",
    typeOfLocation: "Type de lieu (ville, autoroute, nationale, etc...) :",
    isSigned: "Un constat a-t-il été signé ?",
    yes: "oui",
    no: "non",
    describeTheCircumstances:
      "Décrivez les circonstances et causes de l’événement :",
    describe: "Décrivez les dommages matériels :",
    damages: "LES DOMMAGES",
    personalInjury: "Dommages corporels",
    locateProperty:
      "Localisez les dommages matériels à l’aide d’une ou plusieurs croix",
    signatureOfOperator: "Signature de l’exploitant",
    signatureOfDriver: "Signature du conducteur",
  },
  messages: {
    success: "A créé un succès d'assurance",
    error: "Quelque chose n'allait pas !",
    emailSent: "Le courrier électronique est envoyé !",
    elementCreated: "Élément créé !",
  },
  etat: {
    editEtat: "Modifier un Etat de Lieux",
    createEtat: "Créer un Etat de Lieux",
  },
  missions: {
    edit: "Modifier une Mission",
    create: "Créer une Mission",
  },
  moteur: {
    edit: "Éditer une Moteur",
    create: "Créer une Moteur",
  },
  tractes: {
    edit: "Éditer une Tracte",
    create: "Créer une Tracte",
  },
  etape: {
    edit: "Modifier une Etape",
    create: "Créer une Etape",
  },
  observation: {
    edit: "Modifier une observation",
    create: "Créer une observation",
  },
  cmr: {
    edit: "Modifier une CMR",
    create: "Créer une CMR",
  },
  dn: {
    edit: "Modifier une DN",
    create: "Créer une DN",
  },
  SOS: "SOS",
  EXPLOITANT: "Exploitant",
  HOTLINE: "Hotline",
  ASSURANCE: "Assurances",
  REPERTORIE: "Repertoire",
  APPELPERSONNEL: "Appel personnel",
  guide: {
    create: "Créer un Guide",
    edit: "Modifier un Guide",
  },
  appversion: {
    create: "Nouvelle version de l'application",
  },
};

export default customFrenchMessages;
